import React, { Component } from 'react'
import classNames from 'classnames'
import { TickTockField } from '../../../models/auctions/show/Fields/TickTockField'
import { makePrice } from '../../../utils'
import { OwnPagination } from '../../OwnPagination'
import { dataProvider } from '../../../App'
import update from 'immutability-helper'
import { GET_LIST, Datagrid, TextField } from 'react-admin'
import MaterialTextField from '@material-ui/core/TextField'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Collapse } from 'react-collapse'
import debounce from 'lodash/debounce'
import { add10Seconds as add10secToLot, addMinute as addMinuteToLot } from '../../../models/lots/configurations/model'

export class LiveLotsTable extends Component {
  constructor (props) {
    super(props)
    this.state = {
      liveLots: {
        data: {},
        isOpened: false,
        page: 1,
        perPage: 10,
        total: 0,
        field: 'number',
        order: 'ASC'
      },
      searchLive: ''
    }
    this.loadDataLiveLots()
  }

  /**
   * It gets live-auction-lots and sets to state
   */
  loadDataLiveLots = async () => {
    dataProvider(GET_LIST, `auctions/${this.props.auctionId}/live-auction-lots`, {
      filter: this.filter,
      pagination: {
        page: this.state.liveLots.page,
        perPage: this.state.liveLots.perPage
      },
      sort: {
        field: this.state.liveLots.field,
        order: this.state.liveLots.order
      },
      search: this.state.searchLive,
      auction: true
    }).then(res => {
      const { data, total } = res
      let newids = []
      let newdata = {}
      for (let i = 0; i < data.length; i++) {
        let id = data[i].id
        newids.push(id)
        newdata[id] = {
          ...data[i],
          leftTime: data[i].leftTime
        }
      }
      this.setState({
        liveLots: update(this.state.liveLots, {
          data: {
            $set: newdata
          },
          total: {
            $set: total
          }
        })
      })
    })
  }

  /**
   * We should update live lots after every bid
   * We can't get all need data from the socket because we try to keep the bid socket the most simple to improve speed on the bid
   */
  updateLiveLotsBySocket = () => {
    this.loadDataLiveLots()
  }

  handle10s = async (event, record) => {
    event.preventDefault()
    event.stopPropagation()

    await add10secToLot({ data: { id: record.id }, showNotification: this.props.data.showNotification }, { withoutRefresh: true })
    this.loadDataLiveLots()
  }

  handle60s = async (event, record) => {
    event.preventDefault()
    event.stopPropagation()

    await addMinuteToLot({ data: { id: record.id }, showNotification: this.props.data.showNotification }, { withoutRefresh: true })
    this.loadDataLiveLots()
  }

  setSearchLiveString = ({ target: { value } }) => {
    if (this.debounced && this.debounced.cancel) {
      this.debounced.cancel()
    }

    this.debounced = debounce(this.loadDataLiveLots, 300)
    this.setState({
      searchLive: value
    }, this.debounced)
  }

  changeCollapse = (state) => {
    this.setState({
      liveLots: update(this.state.liveLots, {
        isOpened: { $set: state }
      })
    })
  }

  collapsible = ({ title, exportLink, isOpened, changeCollapse, body }) => {
    return (
      <div className='collapse-wrapper'>
        <div
          className='header'
          onClick={isOpened ? () => (changeCollapse(false)) : () => (changeCollapse(true))}
        >
          {title}
          <div
            className='button'
          >
            <FontAwesomeIcon icon={isOpened ? 'angle-up' : 'angle-down'} />
            <span>{isOpened ? 'Hide' : 'Show'}</span>
          </div>
        </div>
        <Collapse isOpened={isOpened}>
          {exportLink ? <a className='export-button' href={exportLink} target='_blank' rel='noopener noreferrer'>Export to CSV</a> : null}
          <div className='body'>
            {body}
          </div>
        </Collapse>
      </div>
    )
  }

  setLiveLotsPerPage = (perPage) => {
    this.setState({
      liveLots: update(this.state.liveLots, {
        perPage: { $set: perPage }
      })
    }, this.loadDataLiveLots)
  }

  setLiveLotsPage = (page) => {
    this.setState({
      liveLots: update(this.state.liveLots, {
        page: { $set: page }
      })
    }, this.loadDataLiveLots)
  }

  renderLiveLotsInfo = (auction) => {
    let { data } = this.state.liveLots
    let ids = Object.keys(data)
    const ReserveStatusField = ({ source, record = {} }) => {
      let highlight
      let reserveStatus = record.reserveStatus
      highlight = classNames('status-field', {
        green: reserveStatus === 'onMarket',
        white: reserveStatus === 'notNear' || reserveStatus === 'awaitingBids',
        lime: reserveStatus === 'nearReserve'
      })
      if (record.withdrawn) {
        reserveStatus = 'withdrawn'
      }
      switch (reserveStatus) {
        case 'onMarket':
          return (
            <span className={highlight}> On Market </span>
          )
        case 'notNear':
          return (
            <span className={highlight}> Opening Bid </span>
          )
        case 'awaitingBids':
          return (
            <span className={highlight}> Awaiting Bids </span>
          )
        case 'nearReserve':
          return (
            <span className={highlight}> Nearing Reserve </span>
          )
        case 'upcoming':
          return (
            <span> Upcoming </span>
          )
        case 'live':
          return (
            <span className={highlight}> Live </span>
          )
        default:
          return null
      }
    }
    const LotId = ({ record = {} }) => <a href={`/#/lots/${record._id}/show`} className='self-link'>{record.searchNumericId}</a>
    const AuctionLinkField = ({ record = {} }) => <a href={`/#/auctions/${record.auction}/show`} className='vendor-link'>{record.auctionData.title}</a>
    const DescriptionLinkField = ({ record = {} }) => <a href={`/#/lots/${record._id}/show`} className='self-link'>{record.publicDetails.title}</a>
    const NumberLink = ({ record = {} }) => <a href={`/#/lots/${record._id}/show`} className='self-link'>{record.number}</a>

    const TimeLeft = ({ record = {} }) => {
      const finishAt = auction.auctionType === 'synchronous' ? auction.finishAt : record.finishAt
      // let diff = auction.auctionType === 'synchronous' ? auction.staleTime : record.staleTime
      let diff
      if (auction.auctionType === 'synchronous') {
        let compareWith = new Date().getTime()
        let finishAtValue = new Date(finishAt || 0).valueOf()
        diff = finishAtValue - compareWith
      } else {
        diff = record.staleTime
        if (!diff) {
          diff = new Date(finishAt).getTime() - new Date().getTime()
        }
      }
      if (auction.pausedTime || auction.frozenTime || !finishAt || new Date(finishAt).getTime() < new Date().getTime()) {
        return (<span> - </span>)
      } else {
        return (
          <TickTockField leftTime={diff} label='Time Left' className='value' />
        )
      }
    }

    const HighestBidder = ({ record = {} }) => {
      if (record.bids.length) {
        return (<span> {`${record.bids[0].createdBy.firstName} ${record.bids[0].createdBy.lastName}`} </span>)
      } else {
        return (<span> - </span>)
      }
    }

    const BidIncrementField = ({ record = {} }) => {
      return (
        <p>{makePrice(record.bidIncrementCents, { bidding: record.bidding })}</p>
      )
    }

    const RunnerUp = ({ record = {} }) => {
      if (record.bids.length > 1 && record.bids[1].createdBy) {
        return (<span>{`${record.bids[1].createdBy.firstName} ${record.bids[1].createdBy.lastName}`}</span>)
      } else {
        return (<span> - </span>)
      }
    }

    const TimeSinceLastBid = ({ record = {} }) => {
      if (record.bids.length) {
        let diff = new Date().getTime() - new Date(record.bids[0].createdAt).getTime()
        return (<span><TickTockField leftTime={diff} label='Time Since Last Bid' action='add' className='value' /></span>)
      } else {
        return (<span> - </span>)
      }
    }

    const Add10s = ({ record = {} }) => {
      return (
        <a href='./' className='self-link' onClick={(event) => { this.handle10s(event, record) }}>Add 10s</a>
      )
    }

    const Add60s = ({ record = {} }) => {
      return (
        <a href='./' className='self-link' onClick={(event) => { this.handle60s(event, record) }}>Add 60s</a>
      )
    }

    return (
      <div className='info-table-div-wrapper'>
        <MaterialTextField
          label='Search'
          placeholder='Search'
          className='search-text'
          onChange={this.setSearchLiveString}
        />
        <Datagrid
          ids={ids}
          data={data}
          currentSort={{}}
        >
          <AuctionLinkField source='auctionData.title' label='Auction' sortable={false} />
          <LotId source='_id' label='Lot ID' sortable={false} />
          <NumberLink source='number' label='Lot No' sortable={false} />
          <DescriptionLinkField source='description' label='Lot Description' sortable={false} />
          <TextField source='_startPrice' label='Start Price' sortable={false} />
          <TextField source='_reserveCents' label='Reserve Price' sortable={false} />
          <BidIncrementField source='bidIncrementCents' label='Bid Increment' sortable={false} />
          <TextField source='_currentPrice' label='Current Price' sortable={false} />
          <ReserveStatusField source='reserveStatus' label='Status' sortable={false} />
          <TimeLeft source='' label='Time Left' sortable={false} />
          <HighestBidder source='' label='Highest Bidder' sortable={false} />
          <RunnerUp source='' label='Runner Up' sortable={false} />
          <TimeSinceLastBid source='' label='Time Since Last Bid' sortable={false} />
          <Add10s source='' label='Add 10s' sortable={false} />
          <Add60s source='' label='Add 60s' sortable={false} />

        </Datagrid>
        <OwnPagination
          page={this.state.liveLots.page}
          rowsPerPage={this.state.liveLots.perPage}
          perPage={this.state.liveLots.perPage}
          total={this.state.liveLots.total}
          setPerPage={this.setLiveLotsPerPage}
          setPage={this.setLiveLotsPage}
        />
      </div>
    )
  }

  render () {
    let { auction } = this.props
    if (auction && auction.state === 'live') {
      return (
        <div id='collapse-content-liveLots'>
          {this.collapsible({
            title: `Live Auction Lots, ${this.state.liveLots.total} lots`,
            isOpened: this.state.liveLots.isOpened,
            changeCollapse: this.changeCollapse,
            body: this.renderLiveLotsInfo(auction)
          })}
        </div>
      )
    } else {
      return null
    }
  }
}

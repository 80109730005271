import React from 'react'
import TextField from '@material-ui/core/TextField'
import { Form } from 'redux-form'
import auctionCreateService from '../auctionService'

export class TimeFormatInput extends Form {
  constructor (props, context) {
    super(props, context)
    if (props.defaultValue) {
      auctionCreateService.getDefaultTimers().then((defaultTimers) => {
        const duration = props.record[props.source] || defaultTimers[props.source]
        this.setState({
          hours: parseInt((duration / (60 * 60)), 10) || 0,
          minutes: parseInt((duration / 60) % 60, 10) || 0,
          seconds: parseInt((duration) % 60, 10) || 0
        })
      })
    }
    const duration = props.record[props.source] || props.defaultValue || 0
    this.state = {
      hours: parseInt((duration / (60 * 60)), 10),
      minutes: parseInt((duration / 60) % 60, 10),
      seconds: parseInt((duration) % 60, 10)
    }
    this.debounced = null
    this.reduxForm = context._reduxForm
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      // We should set in seconds
      const newData = +this.state.seconds + (+this.state.minutes * 60) + (+this.state.hours * 60 * 60)
      this.reduxForm.dispatch(this.reduxForm.change(this.props.source, newData))
    })
  }

  onBlur = (e) => {
    let newValue = null
    if (e.target.value < 0) {
      newValue = 0
    } else if (e.target.value > 59) {
      newValue = 59
    }
    if (newValue) {
      this.setState({ [e.target.name]: newValue }, () => {
        // We should set in seconds
        const newData = +this.state.seconds + (+this.state.minutes * 60) + (+this.state.hours * 60 * 60)
        this.reduxForm.dispatch(this.reduxForm.change(this.props.source, newData))
      })
    }
  }

  render () {
    return (
      <div style={{ marginTop: '10px' }}>
        <label style={{
          color: 'rgba(0, 0, 0, 0.54)',
          fontSize: '12px',
          marginBottom: '10px',
          display: 'block'
        }}>
          {this.props.label}
        </label>
        <div className={'timer-wrapper'}>
          <TextField
            name='hours'
            style={{ width: '85px' }}
            onChange={this.onChange}
            label={'Hours'}
            value={this.state.hours || 0}
            type='number'
            InputProps={{ inputProps: { min: 0 } }}
            disabled={this.props.disabled}
          />
          <TextField
            name='minutes'
            style={{ width: '85px' }}
            onChange={this.onChange}
            onBlur={this.onBlur}
            label={'Minutes'}
            value={this.state.minutes || 0}
            type='number'
            InputProps={{ inputProps: { min: 0, max: 59 } }}
            disabled={this.props.disabled}
          />
          <TextField
            name='seconds'
            style={{ width: '85px' }}
            onChange={this.onChange}
            onBlur={this.onBlur}
            label={'Seconds'}
            value={this.state.seconds || 0}
            type='number'
            InputProps={{ inputProps: { min: 0, max: 59 } }}
            disabled={this.props.disabled}
          />
        </div>
      </div>
    )
  }
}

import { bidTypes } from '../constants/index'
import config from '../config'
import qs from 'qs'

const pirceNumberFormatter = new Intl.NumberFormat('en-US',
  {
    minimumFractionDigits: 2
  }
)

export const numberFormatter = new Intl.NumberFormat('en-US',
  {
    minimumFractionDigits: 0
  }
)

export function makePrice (price = 0, { bidding, cents = false } = {}) {
  if (bidding === bidTypes.KG) {
    cents = true
  }
  if (cents) {
    if (price) {
      return `${numberFormatter.format(price)}¢`
    } else {
      return `0¢`
    }
  }
  if (price) {
    let roundedPrice = Math.floor(price)
    return `$${pirceNumberFormatter.format(roundedPrice / 100)}`
  } else {
    return `$0.00`
  }
}

export function parseQueryString (queryString) {
  // trim '?' symbol at the start of query string if present
  if (/^\?/.test(queryString)) {
    queryString = queryString.slice(1)
  }
  return qs.parse(queryString)
}

export function centsToDollars (cents) {
  return pirceNumberFormatter.format(cents / 100)
}

export function dollarsToCents (dollars) {
  return dollars * 100
}

export function makeFee (heads, type, discount) {
  let fee = 0
  if (type === 'cattle') {
    fee = heads * config.taxPerHead.cattle
    if (discount) {
      fee = fee - (fee * discount / 100)
    }
  }
  if (type === 'sheep') {
    fee = heads * config.taxPerHead.sheep
    if (discount) {
      fee = fee - (fee * discount / 100)
    }
  }
  return makePrice(fee)
}

export function makeDiscountTotalAmount (heads, type, discount) {
  let fee = 0
  let feeWithDiscount = 0
  if (type === 'cattle') {
    fee = heads * config.taxPerHead.cattle
    if (discount) {
      feeWithDiscount = fee - (fee * discount / 100)
    } else {
      feeWithDiscount = fee
    }
  }
  if (type === 'sheep') {
    fee = heads * config.taxPerHead.sheep
    if (discount) {
      feeWithDiscount = fee - (fee * discount / 100)
    } else {
      feeWithDiscount = fee
    }
  }
  return makePrice(fee - feeWithDiscount)
}

export function transformBidding (option) {
  let label
  switch (option) {
    case bidTypes.KG:
      label = 'c/kg only'
      break
    case bidTypes.HEAD:
    default:
      label = '$/head only'
  }
  return label
}

export function transformBidType (bid) {
  if (!bid) {
    return ''
  }
  let str
  switch (bid.type) {
    case 'single-bid':
      if (bid.isAdminBid) {
        str = 'Bid (Admin)'
      } else if (bid.isVendor) {
        str = 'Bid (Vendor)'
      } else {
        str = 'Bid'
      }
      break
    case 'auto-bid':
      if (bid.isAdminBid) {
        str = `Auto-Bid${bid.autoBidCents ? ` (${makePrice(bid.autoBidCents, { bidding: bid.bidding })})(Admin)` : ''}`
      } else {
        str = `Auto-Bid${bid.autoBidCents ? ` (${makePrice(bid.autoBidCents, { bidding: bid.bidding })})` : ''}`
      }
      break
    case 'pre-bid':
      if (bid.isAdminBid) {
        str = `Pre-Bid${bid.autoBidCents ? ` (${makePrice(bid.autoBidCents, { bidding: bid.bidding })})(Admin)` : ''}`
      } else {
        str = `Pre-Bid${bid.autoBidCents ? ` (${makePrice(bid.autoBidCents, { bidding: bid.bidding })})` : ''}`
      }
      break
    default:
      str = ''
  }
  return str
}

export function limitTextLength (text, maxLength) {
  if (!text) {
    return
  }
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '...'
  } else {
    return text
  }
}

export function msToTime (duration) {
  if (duration < 0) {
    return
  }
  let seconds = parseInt((duration / 1000) % 60, 10)
  let minutes = parseInt((duration / (1000 * 60)) % 60, 10)
  let hours = parseInt((duration / (1000 * 60 * 60)), 10)
  hours = (hours < 10) ? '0' + hours : hours
  minutes = (minutes < 10) ? '0' + minutes : minutes
  seconds = (seconds < 10) ? '0' + seconds : seconds
  let output = ''
  if (hours) {
    output += hours + ':'
  }
  if (minutes) {
    output += minutes + ':'
  }
  return output + seconds
}

export function generateUserId(type, digit) {
  if (type === 'seller' || type === 'buyer') {
    return `U${digit}`
  } else {
    return `${type.slice(0, 1).toUpperCase()}${digit}`
  }
}

export function getFeePerHead ({ lotFee, count }) {
  if (count) {
    return lotFee / count
  }
  return 0
}

export function getUserTypeByStatus(status, priviousType) {
  switch (status) {
    case 'registered':
    case 'registered-updated':
      return 'guest'
    case 'approved-buyer':
    case 'approved-buyer-updated':
      return 'buyer'
    case 'approved-seller':
    case 'approved-buyer-seller':
      return 'seller'
    default:
      return priviousType ? priviousType : 'guest'
  }
}

export const capitalize = (word) => {
  return word && word[0].toUpperCase() + word.slice(1)
}
/* global Blob */
import React, { Component } from 'react'
import { Show, SimpleShowLayout, BooleanField, showNotification, refreshView, ReferenceField } from 'react-admin'
import { connect } from 'react-redux'
import { pick } from 'lodash'
import moment from 'moment'
import {
  withdrawLot,
  add10Seconds,
  addMinute,
  freezeLot,
  pauseLot,
  resumeLot,
  moveToAcution,
  closeSequentialAuctionLot,
  handleOpenLot
} from '../configurations/model'
import LotAssessmentFormView from '../../../components/LotAssessmentForm/AssessmentFormView'
import ActionButton from '../../../components/ActionButton'
import { generateStatusString } from '../../../utils/fields'
import { lotDefaultProps } from '../../../utils/propsFields'
import { makePrice } from '../../../utils'
import BidsComponent from '../../../components/lotShow/lotShowBidTable/lotShowBidTableContainer'
import WatchList from '../../../components/lotShow/watchList/WatchList'
import axios from 'axios'
import CheckboxField from '../../../components/LotAssessmentForm/fields/CheckboxField'

const AuctionLinkField = ({ record = {} }) => <a className='link-value'
  href={`/#/auctions/${record.auction}/show`}>{record.auctionData.title}</a>
const CopyFromAuctionLinkField = ({ record = {} }) => {
  return (
    // <span className='value'>
    //   {record.copyFrom && record.copyFrom.publicDetails &&
    //   <a className='link-value' href={`/#/lots/${record.copyFrom._id}/show`}>{record.copyFrom.publicDetails.title}</a>
    //   }
    // </span>
    <span className='value'>
    {record.reoffered && record.copyFrom && record.copyFrom.publicDetails && 
    <a className='link-value' href={`/#/lots/${record.copyFrom._id}/show`}>{record.copyFrom.publicDetails.title}</a>
    }
  </span>
  )
}
const UserLink = ({ record = {} }) => {
  return (
    <span>
      {record && <span
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          window.location.href = `/#/users/${record._id}/show`
        }}
        className='vendor-link'>
        {`${record.firstName} ${record.lastName}`}
      </span>}
    </span>
  )
}
// TODO rewrite this component (it should return ready list)!!!!!!!
const LotInfoField = ({ basePath, record, fields }) => {
  return fields.map((field, index) => {
    let sources = field.source.split('.')
    let parsed = record
    let customClassName = 'table-wrapper'
    if (field.type === 'soldAfterAuction' && record.status !== 'soldAfterAuction') {
      return ''
    }
    if (field.source === 'mobEntryWeight' && !record.optiweighAllowed) {
      return null
    }
    sources.forEach(source => {
      if (parsed) {
        parsed = parsed[source]
      }
      if (parsed === 0 && field.type === 'bid') {
        parsed = 'Nil'
      }
    })
    if (field.type === 'date' && record.auctionData) {
      parsed = moment(record.auctionData.liveAt).format('D/M/YYYY')
    }
    if (field.type === 'time' && record.auctionData) {
      parsed = new Date(record.auctionData.liveAt).toLocaleTimeString()
    }
    if (field.source === 'status') {
      parsed = generateStatusString(parsed)
    }
    if (field.source === 'bidIncrementCents' || field.source === 'reserveCents' || field.source === 'startPrice' || field.source === 'highestBidCents' || field.source === 'closedPrice') {
      parsed = makePrice(parsed, { bidding: record.bidding })
    }
    // if (field.type === 'reoffered') {
    //  // record.reoffered = !!record.copyFrom
    //      record.reoffered 
      
    // }
    if (field.type === 'userLink' || field.type === 'winner') {
      customClassName = 'table-wrapper userLink'
    }
    return (
      <div key={index} className={customClassName}>
        <span className='label'>
          {field.type === 'reofferedLot' &&
          // <span className='label-info'>
          //   {!record.copyFrom && ''}
          //   {record.copyFrom && `${field.label} : `}&nbsp;
          // </span>
       <span className='label-info'>
          {!record.reoffered && ''}
          {record.reoffered && `${field.label} : `}&nbsp;
        </span>
          }
          {field.type !== 'reofferedLot' &&
          <span className='label-info'>
            {field.label} : &nbsp;
          </span>
          }
        </span>
        {field.type === 'userLink' &&
        <ReferenceField className='userLink' record={record} basePath={basePath} source='createdBy' reference='users'
          allowEmpty>
          <UserLink />
        </ReferenceField>
        }
        {field.type === 'auction' && <AuctionLinkField record={record} source='auctionData' label='Auction' />}
        {field.type === 'winner' &&
        <span style={{ cursor: 'pointer' }}>
          {record.winner && (record.winnerData?.firstName || record.winnerData?.lastName) && <span
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              window.location.href = `/#/users/${record.winner}/show`
            }}
            className='vendor-link'>
            {`${record.winnerData.firstName} ${record.winnerData.lastName}`}
          </span>}
        </span>
        }
        {field.type === 'approved' &&
        <BooleanField record={record} source='approved' className='value' sortable={false} />}
        {field.type === 'reoffered' &&
        <BooleanField record={record} source='reoffered' className='value boolean-value' sortable={false} />}
        {field.type === 'reofferedLot' &&
        <CopyFromAuctionLinkField record={record} source='copyFrom.auctionData' label='Auction' />}
        {field.type === 'soldAfterAuction' && <span className='value'> {parsed.toString()} </span>}
        {field.source === 'additionalTitle' && <span className='value'> {`${record.publicDetails.title} ${record.publicDetails.summary.age} ${record.additionalTitle}`} </span>}
        {field.source !== 'additionalTitle' && field.type !== 'auction' && field.type !== 'approved' && field.type !== 'reofferedLot' && field.type !== 'soldAfterAuction' &&
        <span className='value'> {parsed} </span>}
      </div>
    )
  })
}

const lotExportForm = async (props) => {
  try {
    const data = await axios(`lots/${props.data.id}?mode=full`, {
      method: 'get'
    })
    let lot = data.data.lot
    delete lot._id
    delete lot.auctionData
    delete lot.auctionData
    delete lot.createdAt
    delete lot.createdBy
    delete lot.creatorData
    delete lot.currentBidCents
    delete lot.finishAt
    delete lot.frozenTime
    delete lot.liveAt
    delete lot.reserveStatus
    delete lot.searchNumericId
    delete lot.soldAfterAuction
    delete lot.staleTime
    delete lot.state
    delete lot.status
    delete lot.submittedAt
    delete lot.updatedAt
    delete lot.winner
    delete lot.winnerData
    if (lot.bidding && lot.bidding.includes('head')) {
      if (lot.reserveCents) {
        lot.reserveCents = lot.reserveCents / 100
      }
      if (lot.startPrice) {
        lot.startPrice = lot.startPrice / 100
      }
    }
    if (lot.details) {
      const assessmentGroupIndex = lot.details.values.findIndex(item => item.publicId === 'assessmentGroup')
      if (assessmentGroupIndex !== -1) {
        const individualAssessmentIndex = lot.details.values[assessmentGroupIndex].values.findIndex(x => x.publicId === 'individualAssessment')
        if (individualAssessmentIndex !== -1) {
          lot.details.values[assessmentGroupIndex].values[individualAssessmentIndex].values.forEach(array => {
            array.forEach(item => {
              if (item.publicId && (item.publicId.includes('fat') || item.publicId.includes('weight'))) {
                item.value = Number(item.value) || ''
              }
            })
          })
        }
      }
    }
    lot.emailToVendor = false
    lot.draft = true
    const jsonLot = JSON.stringify(lot)
    let downloadUrl = URL.createObjectURL(new Blob([jsonLot], { type: 'application/json' }))
    let fileName = `${props.data.id}.json`
    const lotExportLink = document.createElement('a')
    lotExportLink.href = downloadUrl
    lotExportLink.setAttribute('download', fileName)
    document.body.appendChild(lotExportLink)
    lotExportLink.click()
    lotExportLink.remove()
    window.URL.revokeObjectURL(downloadUrl)
  } catch (e) {
    console.log(e, ' error exporting lot')
  }
}

const weightsExportForm = async (props) => {
  let response = await axios({
    url: `lot/weights/${props.data.id}`,
    method: 'GET',
    responseType: 'blob'
  })
  const blob = new window.Blob([response.data], { type: response.data.type })
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  const contentDisposition = response.headers['content-disposition']
  let fileName = `Weights ${props.data.id}`
  if (contentDisposition) {
    const fileNameMatch = contentDisposition.match(/filename="(.+)"/)
    if (fileNameMatch.length === 2) { fileName = fileNameMatch[1] }
  }
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
  link.remove()
  window.URL.revokeObjectURL(url)
}

const Media = ({ record }) => {
  return record.media
    .sort((a, b) => a.order - b.order)
    .map((media, index) => {
      let isVideo = media.mimeType.indexOf('video') !== -1
      return (
        <div key={index} id={`image-row-${index}`} className={isVideo ? 'video-link' : 'image-row'}>
          <label className='image-main-label' htmlFor={`image-row-${index}`}>Main</label>
          <CheckboxField
            disabled
            value={media.main}
            title={'some super text'}
            field='value'
          />
          {
            isVideo
              ? <video width={300} height={200} id='video' src={media.url} type='video/mp4' controls />
              : <img alt={media.name} src={media.thumbnail ? media.thumbnail : media.url} />
          }
        </div>
      )
    })
}

const Documents = ({ record }) => {
  return record.documents.length > 0 ? (
    <div>
      <span></span>
      <span className='label'>Documents:</span>
      <RenderDocuments docs={record.documents} />
    </div>
  ) : null
}

const AdminAttachments = ({ record }) => {
  return record.adminAttachments.length > 0 ? (
    <div>
      <span className='label'>Admin Attachments:</span>
      <RenderDocuments docs={record.adminAttachments} />
    </div>
  ) : null
}

const RenderDocuments = ({ docs }) => {
  return docs.map((document, index) => {
    return (
      <div key={index} className='document-row documents'>
        <a href={document.url}>{document.name}</a>
        <p className='description'><span>Description: </span>{document.description}</p>
      </div>
    )
  })
}

const CustomPauseButton = ({ record = {}, props }) => {
  return (
    <ActionButton
      onClick={pauseLot}
      data={props}
      variant={record.pausedTime ? 'contained' : 'outlined'}
      color='primary'
    >Pause Lot</ActionButton>
  )
}

const CustomWeightCSVButton = ({ record = {}, props }) => {
  if (record.optiweighAllowed) {
    return null
  } else {
    return (
      <ActionButton data={props} onClick={weightsExportForm} variant='outlined' color='primary'>Export
        Weights</ActionButton>
    )
  }
}

const CustomFreezeButton = ({ record = {}, props }) => {
  return (
    <ActionButton
      onClick={freezeLot}
      data={props}
      variant={record.frozenTime ? 'contained' : 'outlined'}
      color='primary'
    >Freeze Lot</ActionButton>
  )
}

const UsefulLinks = ({ record = {} }) => {
  if (record.usefulLinks && record.usefulLinks.filter(link => link.url).length) {
    return (
      <div className='useful-links-container'>
        <span className='label'>Useful links : &nbsp;</span>
        <span className='links'>{record.usefulLinks.map(e => <a target='_blank' rel='noopener noreferrer' style={{ marginLeft: '6px' }} href={e.url}>{e.description || e.url}</a>)}</span>
      </div>
    )
  } else {
    return null
  }
}

class LotShowComponent extends Component {
  render () {
    const LotTitle = ({ record = {} }) => {
      return (
        <h2 className='lot-title'>Lot {record.searchNumericId}</h2>
      )
    }

    const WithdrawnButton = ({ record = {} }) => {
      return <ActionButton data={this.props} onClick={withdrawLot} variant={record.withdrawn ? 'contained' : 'outlined'}
        color='primary'>Withdraw</ActionButton>
    }

    let defaultProps = pick(this.props, lotDefaultProps)
    return (
      <div>
        <Show {...defaultProps}>
          <SimpleShowLayout className='lot-info-wrapper'>
            <LotTitle />
            <SimpleShowLayout className='info-block left-block'>
              <LotInfoField fields={[
                {
                  source: 'additionalTitle',
                  label: 'Lot Title'
                },
                {
                  source: 'mobEntryWeight',
                  label: 'Mob Entry Weight'
                },
                {
                  source: 'count',
                  label: 'Number of Head'
                },
                {
                  source: 'publicDetails.majorBreed',
                  label: 'Breed'
                },
                {
                  source: 'publicDetails.sex',
                  label: 'Sex'
                },
                {
                  source: 'approved',
                  label: 'Approved',
                  type: 'approved'
                },
                {
                  source: 'number',
                  label: 'Lot Number'
                },
                {
                  source: 'state',
                  label: 'State'
                },
                {
                  source: 'description',
                  label: 'Description'
                },
                {
                  source: '',
                  label: 'Auction',
                  type: 'auction'
                },
                {
                  source: 'auctionData.liveAt',
                  label: 'Auction Date',
                  type: 'date'
                },
                {
                  source: 'auctionData.liveAt',
                  label: 'Auction Time',
                  type: 'time'
                },
                {
                  source: '',
                  label: 'User Who Has Added The Lot',
                  type: 'userLink'
                },
                {
                  source: 'headOnNVD',
                  label: 'Number of head on NVD'
                },
                {
                  source: 'headAccordingToBuyer',
                  label: 'Number of head according to Buyer'
                },
                {
                  source: 'adminNotes',
                  label: 'Admin Notes'
                },
              ]} />
            </SimpleShowLayout>
            <SimpleShowLayout className='info-block center-block'>
              <LotInfoField fields={[
                {
                  source: 'bidding',
                  label: 'Bidding'
                },
                {
                  source: 'startPrice',
                  label: 'Start Price'
                },
                {
                  source: 'reserveCents',
                  label: 'Reserve Price'
                },
                {
                  source: 'bidIncrementCents',
                  label: 'Bid Increment'
                },
                {
                  source: 'highestBidCents',
                  label: 'Current Price Highest Bid'
                },
                {
                  source: '',
                  label: 'Current Highest Bidder',
                  type: 'winner'
                },
                {
                  source: 'status',
                  label: 'Status'
                },
                {
                  source: 'closedPrice',
                  type: 'soldAfterAuction',
                  label: 'Sold AA Price'
                },
                {
                  source: 'totalBids',
                  label: 'Number of Bids'
                },
                {
                  source: '',
                  label: 'Time left'
                },
                {
                  source: 'maxVendorBids',
                  label: 'Max vendor bids',
                  type: 'bid'
                },
                {
                  source: '',
                  label: 'Reoffered',
                  type: 'reoffered'
                },
                {
                  source: 'soldAfterAuction',
                  label: 'Sold after auction',
                  type: 'soldAfterAuction'
                },
                {
                  source: '',
                  label: 'Previous Auction',
                  type: 'reofferedLot'
                }
              ]} />
            </SimpleShowLayout>
            <SimpleShowLayout className='info-block configurations'>
              <SimpleShowLayout className='inside-buttons'>
                <WithdrawnButton />
                <ActionButton data={this.props} onClick={moveToAcution} variant='outlined' color='primary'>
                  Edit or Move
                </ActionButton>
              </SimpleShowLayout>
              <SimpleShowLayout className='inside-buttons'>
                <ActionButton data={this.props} onClick={add10Seconds} variant='outlined' color='primary'>
                  Add 10 seconds
                </ActionButton>
                <ActionButton data={this.props} onClick={addMinute} variant='outlined' color='primary'>
                  Add 60 seconds
                </ActionButton>
              </SimpleShowLayout>
              <SimpleShowLayout className='inside-buttons'>
                <CustomPauseButton props={this.props} />
                <CustomFreezeButton props={this.props} />
              </SimpleShowLayout>
              <SimpleShowLayout className='inside-buttons alone-button'>
                <ActionButton data={this.props} onClick={resumeLot} variant='outlined' color='primary'>Resume
                  Lot</ActionButton>
                <ActionButton data={this.props} onClick={closeSequentialAuctionLot} variant='outlined' color='primary'>Close
                  Lot</ActionButton>
              </SimpleShowLayout>
              <SimpleShowLayout className='inside-buttons'>
                <ActionButton data={this.props} onClick={lotExportForm} variant='outlined' color='primary'>
                  Export Lot
                </ActionButton>
                <CustomWeightCSVButton props={this.props} />
              </SimpleShowLayout>
              <SimpleShowLayout className='inside-buttons alone-button'>
                <ActionButton 
                data={this.props} 
                onClick={handleOpenLot} 
                variant='outlined' 
                color='primary'>
                Re-Open Lot
                </ActionButton>
              </SimpleShowLayout>
            </SimpleShowLayout>
            <SimpleShowLayout className='images-wrapper'>
              <Media />
            </SimpleShowLayout>
            <SimpleShowLayout className='documents-wrapper'>
              <Documents />
              <AdminAttachments />
            </SimpleShowLayout>
            <SimpleShowLayout className='useful-links-wrapper'>
              <UsefulLinks />
            </SimpleShowLayout>
            <SimpleShowLayout>
              <h2>Bids</h2>
              <BidsComponent />
            </SimpleShowLayout>
            <SimpleShowLayout className='lot-assessment-wrapper'>
              <LotAssessmentFormView />
            </SimpleShowLayout>
            <SimpleShowLayout>
              <h2>Users who are watching the lot</h2>
              <WatchList />
            </SimpleShowLayout>
          </SimpleShowLayout>
        </Show>
      </div>
    )
  }
}

export const LotShow = connect(null, {
  showNotification,
  refreshView
})(LotShowComponent)

import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  SaveButton,
  showNotification
} from 'react-admin'
import { push } from 'react-router-redux'
import { validateDraftLot } from './validate'
import axios from 'axios'
import config from '../../config.js'
const { maxDocumentLimit, maxMediaLimit } = config.lot

let getNextOrder = (media) => {
  let maxOrder = Math.max(media.map(doc => {
    if (doc.hasOwnProperty('order')) {
      return doc.order
    } else {
      return 0
    }
  }))
  return maxOrder >= 0 ? maxOrder + 1 : 0
}

class LotCreateActions extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isRequest: false
    }
    this.isUnmounted = false
  }

  componentWillUnmount () {
    this.isUnmounted = true
  }

  render () {
    let {
      reduxForm,
      push,
      showNotification
    } = this.props
    return (
      <div className='custom-kind-actions'>
        <SaveButton
          label='Save And Show'
          submitOnEnter={false}
          className='save-button'
          disabled={this.state.isRequest}
          onClick={async () => {
            let values = reduxForm.getValues()
            let data = this.props.details
            if (!values.auction) {
              showNotification('Please choose Auction', 'warning')
              return
            }
            if (values.documents && values.documents.length > maxDocumentLimit) {
              showNotification(`maximum number of documents = ${maxDocumentLimit}`, 'warning')
              return
            }
            if (values.adminAttachments && values.adminAttachments.length > maxDocumentLimit) {
              showNotification(`maximum number of admin attachments = ${maxDocumentLimit}`, 'warning')
              return
            }
            if (values.media && values.media.length > maxMediaLimit) {
              showNotification(`maximum number of media files = ${maxMediaLimit}`, 'warning')
              return
            }
            const validateForm = validateDraftLot(Object.assign(values, data), { draft: values.draft, type: 'create' })
            if (!validateForm || validateForm.valid) {
              this.props.showSpinner()
              if (this.state.isRequest) {
                return
              }
              this.setState({
                isRequest: true
              }, async () => {
                if (this.props.details && this.props.details.forms) {
                  delete this.props.details.forms
                }
                const newLot = {
                  additionalTitle: values.additionalTitle || '',
                  bidIncrementCents: values.bidIncrementCents || 0,
                  currentBidCents: values.startPrice || 0,
                  description: values.description || '',
                  reserveCents: values.reserveCents || 0,
                  bidding: values.bidding || null,
                  biddingSwap: values.biddingSwap || false,
                  draft: values.draft || true,
                  count: values.count || 1,
                  countWeighed: values.auction.kindData.type === 'cattle' ? null : values.countWeighed || 0,
                  acceptenceOnTerms: values.acceptenceOnTerms || true,
                  saleConditions: values.saleConditions || '',
                  maxVendorBids: values.maxVendorBids || 0,
                  vendorSignedForm: values.vendorSignedForm || true,
                  adjustWeight: values.adjustWeight || 0,
                  weightGain: values.weightGain || 0,
                  startPrice: values.startPrice || 0,
                  details: this.props.details,
                  userFromAdminId: this.props.userId,
                  approvedFromCreation: values.approved || false,
                  optiweigh: values.optiweigh,
                  optiweighAllowed: values.optiweighAllowed,
                  mobEntryWeight: values.mobEntryWeight || 0,
                  oldDetails: values.oldDetails || null,
                  oldWeightGain: values.oldWeightGain || null,
                  usefulLinks: values.usefulLinks || [],
                  headOnNVD: values.headOnNVD || 0,
                  headAccordingToBuyer: values.headAccordingToBuyer || 0,
                  adminNotes: values.adminNotes || '',
                }
                await axios.post(`/auctions/${values.auction._id}/lots`, {
                  lot: newLot
                }).then(async (res) => {
                  let promises = []
                  if (values.media) {
                    values.media.forEach(async (newMedia) => {
                      promises.push(new Promise(async (resolve) => {
                        if (newMedia.rawFile && !newMedia.editMode) {
                          await axios({
                            method: 'POST',
                            url: `/lots/${res.data.lot._id}/media`,
                            data: newMedia.rawFile,
                            headers: {
                              'content-type': newMedia.mimeType,
                              'file-name': newMedia.name,
                              'file-order': getNextOrder(values.media)
                            }
                          }).then((response) => {
                            if (response.data.media && (response.data.media.mimeType.includes('image') || response.data.media.mimeType.includes('video'))) {
                              return axios.post(
                                `lots/${res.data.lot._id}/media/${response.data.media.key}/thumbnail`,
                                newMedia.rawThumbnail,
                                {
                                  headers: {
                                    'content-type': response.data.media.mimeType
                                  }
                                }).then(() => { resolve() })
                            }
                          })
                        }
                      }))
                    })
                  }
                  if (values.documents) {
                    values.documents.forEach(async (newDocument, index) => {
                      promises.push(new Promise(async (resolve) => {
                        if (newDocument.rawFile) {
                          await axios({
                            method: 'POST',
                            url: `/lots/${res.data.lot._id}/documents`,
                            data: newDocument.rawFile,
                            headers: {
                              'content-type': newDocument.rawFile.type,
                              'file-name': newDocument.name,
                              'file-order': index + 1,
                              'file-description': newDocument.description
                            }
                          }).then(() => {
                            resolve()
                            // push(`/lots/${res.data.lot._id}/show`)
                          })
                        }
                      }))
                    })
                  }

                  if (values.adminAttachments) {
                    values.adminAttachments.forEach(async (newDocument, index) => {
                      promises.push(new Promise(async (resolve) => {
                        if (newDocument.rawFile) {
                          await axios({
                            method: 'POST',
                            url: `/lots/${res.data.lot._id}/adminAttachments`,
                            data: newDocument.rawFile,
                            headers: {
                              'content-type': newDocument.rawFile.type,
                              'file-name': newDocument.name,
                              'file-order': index + 1,
                              'file-description': newDocument.description
                            }
                          }).then(() => {
                            resolve()
                            // push(`/lots/${res.data.lot._id}/show`)
                          })
                        }
                      }))
                    })
                  }

                  if (values.usefulPdfs && values.usefulPdfs.length <= maxDocumentLimit) {
                    let getNextOrder = () => {
                      let maxOrder = Math.max(...values.usefulPdfs.map(doc => {
                        if (doc.hasOwnProperty('order')) {
                          return doc.order
                        } else {
                          return 0
                        }
                      }))
                      return maxOrder >= 0 ? maxOrder + 1 : 0
                    }
                    values.usefulPdfs.forEach((newDocument) => {
                      if (newDocument.rawFile) {
                        promises.push(
                          axios({
                            method: 'POST',
                            url: `/lots/${res.data.lot._id}/usefulPdfs`,
                            data: newDocument.rawFile,
                            headers: {
                              'content-type': newDocument.rawFile.type,
                              'file-name': newDocument.name,
                              'file-order': getNextOrder(),
                              'file-description': newDocument.description || ''
                            }
                          })
                        )
                      }
                    })
                  }

                  await Promise.all(promises).then(async (allPromisesResponse) => {
                    if (allPromisesResponse[0] && allPromisesResponse[0].data && allPromisesResponse[0].data.newPdfFile) {
                      const allLinks = allPromisesResponse.map(
                        (oneLink) => oneLink.data && oneLink.data.newPdfFile ? oneLink.data.newPdfFile : null)
                        .filter(Boolean)
                        .flat()

                      newLot.usefulLinks = newLot.usefulLinks.concat(allLinks).filter(link => link.url)
                      await axios.put(`/auctions/${values.auction._id}/lots/${res.data.lot._id}`, {
                        lot: newLot
                      })
                    }

                    this.props.showSpinner()
                    push(`/lots/${res.data.lot._id}/show`)
                  })
                  if (!values.media && !values.documents && !values.adminAttachments) {
                    this.props.showSpinner()
                    push(`/lots/${res.data.lot._id}/show`)
                  }
                }).catch(error => {
                  this.props.showSpinner()
                  showNotification(error.message, 'warning')
                })
                if (!this.isUnmounted) {
                  this.setState({
                    isRequest: false
                  })
                }
              })
            } else {
              this.props.showValidationError(validateForm.message)
            }
          }}
        />
      </div>
    )
  }
}

export default connect(null, {
  push,
  showNotification
})(LotCreateActions)

import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import config from '../../../config'

export const HeadsCountSection = (props) => {
  const { defaultCountWeighed, kindData, defaultCount, newCount, newCountWeighed } = props
  const [currentCount, setCurrentCount] = useState(defaultCount)
  const [currentCountWeighed, setCurrentCountWeighed] = useState(defaultCountWeighed)

  useEffect(() => {
    if (newCount) {
      setCurrentCount(newCount)
    }
  }, [newCount])

  useEffect(() => {
    if (newCountWeighed) {
      setCurrentCountWeighed(newCountWeighed)
    }
  }, [newCountWeighed])

  return (
    <>
      <div className='depth-0'>
        <h3 className='dropdown-title'>Number of Head</h3>
        <HeadsInput
          {...props}
          value={currentCount || defaultCount || 1}
          field='count'
        />
      </div>
      {
        kindData.type !== 'cattle' &&
        <div className='depth-0'>
          <h3 className='dropdown-title'>Number of Head Weighed</h3>
          <HeadsInput
            {...props}
            value={currentCountWeighed || 0}
            field='countWeighed'
            kindDataType={kindData.type}
          />
        </div>
      }
    </>
  )
}

const HeadsInput = ({ value, kindDataType, field, onChange }) => {
  const inputProps = { min: 0, max: config.lot.maxHeads, step: '1' }
  const [currentValue, setCurrentValue] = useState(value)

  useEffect(() => {
    setCurrentValue(value)
  }, [value])

  const onChangeLocal = ({ target: { value } }) => {
    value = parseInt(value) || 0
    setCurrentValue(value)
  }

  const onBlur = ({ target: { value } }) => {
    value = parseInt(value) || 0
    if (value > config.lot.maxHeads) {
      value = config.lot.maxHeads
    }
    switch (field) {
      case 'count':
        if (value < 1 && kindDataType !== 'goat') {
          value = 1
        }
        if (currentValue !== null && value < currentValue) {
          value = currentValue
        }
        break
      case 'countWeighed':
        if (value < 1 && kindDataType !== 'goat') {
          value = 1
        }
        if (value > currentValue) {
          value = currentValue
        }
        break
      default:
        break
    }

    onChange({ value, field })
    setCurrentValue(value)
  }

  return (
    <TextField
      onChange={onChangeLocal}
      onBlur={onBlur}
      value={currentValue}
      type='number'
      inputProps={inputProps}
    />
  )
}

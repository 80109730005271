import React, { Component } from 'react'
import update from 'immutability-helper'
import { GET_ONE, GET_LIST, UPDATE, DELETE, showNotification, Datagrid, TextField, CardActions, EditButton, NumberField, BooleanField, DateField, ReferenceField } from 'react-admin'
import { connect } from 'react-redux'
import { msToTime, makePrice, generateUserId } from '../../../utils'
import {
  faAngleDown,
  faAngleUp,
  faCheck,
  faTimes,
  faFileAlt
} from '@fortawesome/free-solid-svg-icons/index'
import { dataProvider } from '../../../App'
import { InvoiceListComponent } from '../../invoices/model'
import { Collapse } from 'react-collapse'
import { OwnPagination } from '../../../components/OwnPagination'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Input from '@material-ui/core/Input'
import Slide from '@material-ui/core/Slide'
import config from '../../../config'
import { generateStatusString } from '../../../utils/fields'
import { professionOther } from '../../../constants'
import kindApiService from '../../../providers/kinds/kindApiService'

const INFO = 'info'
const BIDS = 'bids'
const UPCOMING_LOTS = 'upcoming'
const SOLD_LOTS = 'sold'
const PURCHASED_LOTS = 'purchased'
const INVOICES = 'invoices'
const CONNECTIVITY = 'connectivity'

function Transition(props) {
  return <Slide direction='up' {...props} />
}

const UserTitle = ({ userId, name, status, basePath, record }) => (
  <CardActions>
    <div className='card-actions'>
      <div className='info'>
        <b style={{ marginRight: '20px' }} >{name}</b>
        {userId && (<span style={{ marginRight: '20px' }}>{userId}</span>)}
        {status && (<span>{status}</span>)}
      </div>
      <div className='buttons' style={{ margin: 0, width: '73px' }}>
        <EditButton basePath={basePath} record={record} />
      </div>
    </div>
  </CardActions>
)

/*
const LinkRow = (name, url) => {
  return (
    <div className='rows'>
      <span className={`title ${url.length === 0 ? 'required-missed' : ''}`}>{name}</span>
      <span><a href={url} rel='noopener noreferrer' target='_blank'>Show</a></span>
    </div>
  )
}
*/
const CustomList = (data, { title = false } = {}) => (
  <div className='info-list'>
    {title && (<span className='title'>{title}</span>)}
    <ul className='list'>
      {
        data.map((item, index) => (
          <li key={index}>
            <span className={`title ${item.required && item.value.length === 0 ? 'required-missed' : ''}`}>{item.field}:</span>
            {
              typeof item.value === 'boolean'
                ? <span className='value'><FontAwesomeIcon icon={item.value ? faCheck : faTimes} /></span>
                : <span className='value'>{item.value}</span>
            }
          </li>
        ))
      }
    </ul>
  </div>
)

class UserShowBase extends Component {
  constructor(props) {
    super(props)
    this.state = {
      info: {
        user: {},
        isOpened: true
      },
      bids: {
        data: {},
        ids: [],
        isOpened: false,
        page: 1,
        total: 0,
        perPage: 10,
        field: '_id',
        order: 'ASC'
      },
      lots: {
        purchased: {
          data: {},
          ids: [],
          isOpened: false,
          page: 1,
          total: 0,
          perPage: 10,
          field: '_id',
          order: 'ASC'
        },
        sold: {
          data: {},
          ids: [],
          isOpened: false,
          page: 1,
          total: 0,
          perPage: 10,
          field: '_id',
          order: 'ASC'
        },
        upcoming: {
          data: {},
          ids: [],
          isOpened: false,
          page: 1,
          total: 0,
          perPage: 10,
          field: '_id',
          order: 'ASC'
        }
      },
      invoices: {
        isOpened: false
      },
      connectivity: {
        isOpened: false,
        data: {},
        ids: [],
        page: 1,
        total: 0,
        perPage: 10,
        field: '_id',
        order: 'ASC'
      },
      showEditModal: false,
      showDeleteModal: false,
      editValue: 0,
      workId: null,
      kindsList: [],
    }
    this.timerEnabled = true
    this.timerId = null
    this.changeCollapse = this.changeCollapse.bind(this)
    this.setPagePurchased = this.setPagePurchased.bind(this)
    this.setPageUpcoming = this.setPageUpcoming.bind(this)
    this.setPerPagePurchased = this.setPerPagePurchased.bind(this)
    this.setPageConnectivity = this.setPageConnectivity.bind(this)
    this.setPageBids = this.setPageBids.bind(this)
    this.setPerPageBids = this.setPerPageBids.bind(this)
    this.setSortBids = this.setSortBids.bind(this)
    this.setPerPageConnectivity = this.setPerPageConnectivity.bind(this)
    this.setPerPageUpcoming = this.setPerPageUpcoming.bind(this)
    this.setPerPageSold = this.setPerPageSold.bind(this)
    this.setSortUpcoming = this.setSortUpcoming.bind(this)
    this.setSortPurchased = this.setSortPurchased.bind(this)
    this.setSortSold = this.setSortSold.bind(this)
    this.setPageSold = this.setPageSold.bind(this)
    this.tickTock = this.tickTock.bind(this)
    this.loadData = this.loadData.bind(this)
    this.loadBids = this.loadBids.bind(this)
    this.setSort = this.setSort.bind(this)
    this.clearTimer = this.clearTimer.bind(this)
    this.checkStatus = this.checkStatus.bind(this)
    this.handleOpenEdit = this.handleOpenEdit.bind(this)
    this.handleOpenDelete = this.handleOpenDelete.bind(this)
    this.handleCloseEdit = this.handleCloseEdit.bind(this)
    this.handleCloseDelete = this.handleCloseDelete.bind(this)
    this.handleUpdate = this.handleUpdate.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.setSortConnectivity = this.setSortConnectivity.bind(this)
    this.initKinds()
  }

  initKinds = async () => {
    const kindsList = await kindApiService.getKinds()
    this.setState({ kindsList })
  }

  componentDidMount() {
    this.loadData()
  }

  componentWillUnmount() {
    this.clearTimer()
  }

  clearTimer(cb) {
    clearTimeout(this.timerTickId)
    clearTimeout(this.timerId)
    this.timerEnabled = false
    if (cb) {
      cb()
    }
  }

  async checkStatus() {
    if (this.timerEnabled) {
      await new Promise((resolve) => {
        this.timerId = setTimeout(() => {
          resolve()
        }, 10000)
      })
      if (!this.timerEnabled) {
        return
      }
      let user = await dataProvider(GET_ONE, 'users', { id: this.props.id })
      if (user.data.connectivity && user.data.connectivity.onlineState) {
        this.setState({
          info: update(this.state.info, {
            user: {
              connectivity: {
                onlineState: { $set: user.data.connectivity.onlineState }
              }
            }
          })
        }, this.checkStatus)
      } else {
        this.checkStatus()
      }
    }
  }

  async loadData() {
    let user = await dataProvider(GET_ONE, 'users', { id: this.props.id })
    if (user) {
      this.setState({
        info: update(this.state.info, {
          user: { $set: user.data }
        })
      }, () => {
        this.checkStatus()
      })
    }
    await this.loadBids()
    await this.getLots('purchased')
    await this.getLots('sold')
    await this.getLots('upcoming')
    await this.getConnectivityInfo()
  }

  async loadBids() {
    await dataProvider(GET_LIST, `bids`, {
      filter: { ...this.filter },
      pagination: {
        page: this.state.bids.page,
        perPage: this.state.bids.perPage
      },
      sort: {
        field: this.state.bids.field,
        order: this.state.bids.order
      },
      bids: true,
      userId: this.props.id,
      enabled: true
    })
      .then((bids) => {
        let newIds = []
        let newBids = {}
        bids.data.forEach(row => {
          newIds.push(row._id)
          newBids[row._id] = row
        })
        this.setState({
          bids: update(this.state.bids, {
            data: { $set: newBids },
            ids: { $set: newIds },
            total: { $set: bids.total }
          })
        }, () => {
          this.tickTock()
        })
      })
  }

  async getConnectivityInfo() {
    await dataProvider(GET_LIST, `connectivities`, {
      userId: this.props.id,
      filter: { ...this.filter },
      pagination: {
        page: this.state.connectivity.page,
        perPage: this.state.connectivity.perPage
      },
      sort: {
        field: this.state.connectivity.field,
        order: this.state.connectivity.order
      }
    })
      .then(({ data, total }) => {
        let newIds = []
        let newConnectivity = {}
        data.forEach(row => {
          newIds.push(row._id)
          newConnectivity[row._id] = row
        })
        this.setState({
          connectivity: update(this.state.connectivity, {
            data: { $set: newConnectivity },
            ids: { $set: newIds },
            total: { $set: total }
          })
        })
      })
  }

  async getLots(type) {
    await dataProvider(GET_LIST, `users/${this.props.id}/lots`, {
      filter: { ...this.filter },
      pagination: {
        page: this.state.lots[type].page,
        perPage: this.state.lots[type].perPage
      },
      sort: {
        field: this.state.lots[type].field,
        order: this.state.lots[type].order
      },
      lotsType: type,
      isUserLots: true
    })
      .then(({ data, total }) => {
        let newIds = []
        let newLots = {}
        switch (type) {
          case 'purchased':
            data.forEach(lot => {
              newIds.push(lot._id)
              newLots[lot._id] = lot
            })
            this.setState({
              lots: update(this.state.lots, {
                purchased: {
                  data: { $set: newLots },
                  ids: { $set: newIds },
                  total: { $set: total }
                }
              })
            })
            break
          case 'sold':
            data.forEach(lot => {
              newIds.push(lot._id)
              newLots[lot._id] = lot
            })
            this.setState({
              lots: update(this.state.lots, {
                sold: {
                  data: { $set: newLots },
                  ids: { $set: newIds },
                  total: { $set: total }
                }
              })
            })
            break
          case 'upcoming':
            data.forEach(lot => {
              newIds.push(lot._id)
              newLots[lot._id] = lot
            })
            this.setState({
              lots: update(this.state.lots, {
                upcoming: {
                  data: { $set: newLots },
                  ids: { $set: newIds },
                  total: { $set: total }
                }
              })
            })
            break
          default:
            break
        }
      })
  }

  getItem(url, id) {
    return dataProvider(GET_ONE, url, { id: id })
  }

  collapsible({ title, isOpened, changeCollapse, publicId, body }) {
    return (
      <div className='collapse-wrapper'>
        <div
          className='header'
          onClick={isOpened ? () => (changeCollapse(publicId, false)) : () => (changeCollapse(publicId, true))}
        >
          <p className='title'>
            {title}
          </p>
          <div
            className='button'
          >
            <FontAwesomeIcon icon={isOpened ? faAngleUp : faAngleDown} />
            <span>{isOpened ? 'Hide' : 'Show'}</span>
          </div>
        </div>
        <Collapse isOpened={isOpened}>
          <div className='body'>
            {body}
          </div>
        </Collapse>
      </div>
    )
  }

  changeCollapse(name, state) {
    switch (name) {
      case INFO:
        this.setState({
          info: update(this.state.info, {
            isOpened: { $set: state }
          })
        })
        break
      case CONNECTIVITY:
        this.setState({
          connectivity: update(this.state.connectivity, {
            isOpened: { $set: state }
          })
        })
        break
      case BIDS:
        this.setState({
          bids: update(this.state.bids, {
            isOpened: { $set: state }
          })
        })
        break
      case UPCOMING_LOTS:
      case PURCHASED_LOTS:
      case SOLD_LOTS:
        this.setState({
          lots: update(this.state.lots, {
            [name]: {
              isOpened: { $set: state }
            }
          })
        })
        break
      case INVOICES:
        this.setState({
          invoices: update(this.state.invoices, {
            isOpened: { $set: state }
          })
        })
        break
      default:
        break
    }
  }

  setSort(field) {
    const BIDS = /bids_/i
    let order = 'ASC'
    if (field.match(BIDS).length > 0) {
      if (this.state.bids.field === field) {
        order = this.state.bids.order === 'ASC' ? 'DESC' : 'ASC'
      }
      this.setState({
        bids: update(this.state.bids, {
          field: { $set: field },
          order: { $set: order }
        })
      }, this.loadData)
    }
  }

  setSortConnectivity(field) {
    let sortedField = field
    sortedField = field.replace('_', '')
    let order = this.state.connectivity.order === 'ASC' ? 'DESC' : 'ASC'
    this.setState({
      connectivity: update(this.state.connectivity, {
        field: { $set: sortedField },
        order: { $set: order }
      })
    }, this.getConnectivityInfo
    )
  }

  async tickTock() {
    if (this.timerEnabled) {
      this.timerTickId = await new Promise((resolve) => {
        setTimeout(() => {
          resolve()
        }, 1000)
      })
      let data = {}
      Object.keys(this.state.bids.data).forEach(key => {
        data[key] = {
          ...this.state.bids.data[key]
        }
      })
      this.setState({
        bids: update(this.state.bids, {
          data: { $set: data }
        })
      }, this.tickTock)
    }
  }

  setPerPageConnectivity(page) {
    this.setState({
      connectivity: update(this.state.connectivity, {
        perPage: { $set: page }
      })
    }, this.getConnectivityInfo)
  }

  setPageConnectivity(page) {
    this.setState({
      connectivity: update(this.state.connectivity, {
        page: { $set: page }
      })
    }, this.getConnectivityInfo)
  }

  renderConnectivityInfo({ ids, data, currentSort, filteredProps }) {
    const AuctionTime = ({ record = {} }) => <span>{new Date(record.auctionLiveAt).toLocaleTimeString()}</span>
    const ConnectionTimeStartField = ({ record = {} }) => <span>{record.timeConnected ? new Date(record.timeConnected).toLocaleTimeString() : '-'}</span>
    const ConnectionTimeEndField = ({ record = {} }) => <span>{record.timeDisconnected ? new Date(record.timeDisconnected).toLocaleTimeString() : '-'}</span>
    const ConnectionTimeConnectedField = ({ record = {} }) => {
      if (!record.timeDisconnected || !record.timeConnected) {
        return (
          <span>
            -
          </span>
        )
      }
      let diff = new Date(record.timeDisconnected).getTime() - new Date(record.timeConnected).getTime()
      return (
        <span>
          {msToTime(diff)}
        </span>
      )
    }
    const AuctionLinkField = ({ record = {} }) => {
      if (record.auction) {
        return <a href={`/#/auctions/${record.auction._id}/show`} className='vendor-link'>{record.auction.title}</a>
      } else {
        return null
      }
    }
    return (
      <div className='info-table-div-wrapper'>
        <Datagrid
          ids={ids}
          data={data}
          currentSort={currentSort}
          setSort={this.setSortConnectivity}
          {...filteredProps}
        >
          <AuctionLinkField label='Auction' sortable={false} />
          <DateField source='auctionLiveAt' label='Auction Date' />
          <AuctionTime label='Auction Time' />
          <TextField source='platform' label='App' />
          <ConnectionTimeStartField label='Time Connection Start' />
          <ConnectionTimeEndField label='Time Connection Ended' />
          <ConnectionTimeConnectedField label='Time Connected' />
          <TextField source='lotsTotalCount' label='Lot Total' />
          <TextField source='lotsWonCount' label='Lot Won' />
          <TextField source='lotsLostCount' label='Lot Lost' />
          <TextField source='ip' label='IP' />
        </Datagrid>
        <OwnPagination
          page={this.state.connectivity.page}
          rowsPerPage={this.state.connectivity.perPage}
          perPage={this.state.connectivity.perPage}
          total={this.state.connectivity.total}
          setPage={this.setPageConnectivity}
          setPerPage={this.setPerPageConnectivity}
        />
      </div>
    )
  }

  renderUserInfo(user) {
    /*const referee1 = [
      { field: 'First Name', value: user.referee1 ? user.referee1.firstName : null, required: true },
      { field: 'Last Name', value: user.referee1 ? user.referee1.lastName : null, required: true },
      { field: 'Trading Name', value: user.referee1 ? user.referee1.tradingName : null, required: true },
      { field: 'Email', value: user.referee1 ? user.referee2.email : null, required: true },
      { field: 'Phone', value: user.referee1 ? user.referee1.phone : null, required: true },
      { field: 'Relationship', value: user.referee1 ? user.referee1.relationship : null, required: true }
    ]
    const referee2 = [
      { field: 'First Name', value: user.referee2 ? user.referee2.firstName : null, required: true },
      { field: 'Last Name', value: user.referee2 ? user.referee2.lastName : null, required: true },
      { field: 'Trading Name', value: user.referee2 ? user.referee2.tradingName : null, required: true },
      { field: 'Email', value: user.referee2 ? user.referee2.email : null, required: true },
      { field: 'Phone', value: user.referee2 ? user.referee2.phone : null, required: true },
      { field: 'Relationship', value: user.referee2 ? user.referee2.relationship : null, required: true }
    ]*/
    const officeAddress = [
      { field: 'Address Line 1', value: user.officeAddress.address1 ? user.officeAddress.address1 : '' },
      { field: 'Address Line 2', value: user.officeAddress.address2 ? user.officeAddress.address2 : '' },
      { field: 'Town', value: user.officeAddress.town ? user.officeAddress.town : '' },
      { field: 'Postcode', value: user.officeAddress.postcode ? user.officeAddress.postcode : '' },
      { field: 'State', value: user.officeAddress.state ? user.officeAddress.state : '' }
    ]
    const postalAddress = [
      { field: 'Same as Office', value: user.postalAddress.sameAsOffice ? user.postalAddress.sameAsOffice : '' },
      { field: 'Address Line 1', value: user.postalAddress.address1 ? user.postalAddress.address1 : "" },
      { field: 'Address Line 2', value: user.postalAddress.address2 ? user.postalAddress.address2 : '' },
      { field: 'Town', value: user.postalAddress.town ? user.postalAddress.town : '' },
      { field: 'Postcode', value: user.postalAddress.postcode ? user.postalAddress.postcode : '' },
      { field: 'State', value: user.postalAddress.state ? user.postalAddress.state : '' }
    ]
    const contactNumbers = [
      { field: 'Mobile', value: user.phone, required: true },
      { field: 'Secondary Number', value: user.secondaryPhone }
    ]
    /*const bankDetails = [
      { field: 'Account Name', value: user.bankDetails && user.bankDetails.accountName ? user.bankDetails.accountName : '' },
      { field: 'BSB (Bank State Branch)', value: user.bankDetails && user.bankDetails.BSB ? user.bankDetails.BSB : '' },
      { field: 'Account Number', value: user.bankDetails && user.bankDetails.accountNumber ? user.bankDetails.accountNumber : '' },
    ]*/

    const customerDetails= [
      { field: 'Do you trade through a company', value:  user.doYouTradeThroughCompany ? user.doYouTradeThroughCompany : false },
      { field: 'Company Name', value: user.customerDetails && user.customerDetails.companyName ? user.customerDetails.companyName : '' },
      { field: 'ACN', value: user.customerDetails && user.customerDetails.ACN ? user.customerDetails.ACN : '' },
      { field: 'Full legal name of trading entity', value: user.customerDetails && user.customerDetails.legalNameOfTradingEntity ? user.customerDetails.legalNameOfTradingEntity : '' },
      { field: 'ABN', value: user.ABN ? user.ABN : '' },
      { field: 'Given Name(s)', value: user.customerDetails && user.customerDetails.givenName ? user.customerDetails.givenName : '' },
      { field: 'Last Name', value: user.customerDetails && user.customerDetails.lastName ? user.customerDetails.lastName : '' },
    ]

    return (
      <React.Fragment>
        <div className='columns info-column'>
          {this.renderRow('First Name:', user.firstName, { required: true })}
          {this.renderRow('Last Name:', user.lastName, { required: true })}
          {this.renderRow('Email:', user.email, { capitalize: false, required: true })}
          {this.renderRow('Role:', user.role)}
          {/* {this.renderRow('Type:', user.type)} */}
          {this.renderRow('Current Permission:', user.status)}
          {this.renderRow('Requested Permission:', user.status === 'approved-buyer-seller' ? '' : user.isRequestedAsBuyer && user.isRequestedAsSeller ? 'Buyer & Seller' : user.isRequestedAsBuyer ? 'Buyer' : user.isRequestedAsSeller ? 'Seller' : '')}
          {this.renderRow('Profession:', user.profession ? (user.profession === professionOther ? (user.otherProfession ? user.otherProfession : '') : user.profession) : '')}
          {this.renderApprovedSellingCategories(user.approvedStockTypes)}
          {this.renderRequestedSellingCategories(user.stockTypes)}
          {/* {user.type === 'seller' && this.renderRow('Seller Type:', user.sellerType)} */}
          {/* {user.type === 'seller' && this.renderRow('Sale Conditions:', user.saleConditions)} */}
          {/* {this.renderRow('Website:', user.website, { capitalize: false })} */}
          {/* {user.type === 'seller' && this.renderRow('Job Title:', user.jobTitle, { required: user.type === 'buyer' })} */}
          {user.tradingName && this.renderRow('Trading Name:', user.tradingName, { required: true })}
          {user.agencyName && this.renderRow('Agency Name:', user.agencyName, { required: true })}
          {this.renderRow('PIC:', user.PIC)}
          {this.renderRow('PIC State:', user.anotherStatePIC)}
          {this.renderRow('ABN:', user.ABN)}
          {this.renderRow('Additional State:', user.additionalState, { required: false })}
          {(config.exceptedStates.includes(user.officeAddress.state) && user.type === 'seller') || user.type === 'buyer' ? null : this.renderRow('Years In Industry:', user.yearsInIndustry)}

        </div>
        <div className='columns info-column'>
          {CustomList(contactNumbers, { title: 'Contact Numbers' })}
          {user.type === 'buyer' && this.renderRow('Buyer Agent Name:', user.agentName, { required: false })}
          {user.type === 'buyer' && this.renderRow('Buyer Agent Number:', user.agentPhone, { required: false })}
          {user.type === 'buyer' && this.renderRow('Buyer Agent Trading Name:', user.agentTradingName, { required: false })}
          {CustomList(customerDetails , { title: 'Customer Details' })}
          {CustomList(officeAddress, { title: 'Office Address' })}
          {CustomList(postalAddress, { title: 'Postal Address' })}
          {/* {CustomList(bankDetails, { title: 'Bank Details' })} */}
          <div className='rows'>
            <span className='terms'>Registered for GST:</span>
            {
              <span className='value'><FontAwesomeIcon icon={user.registeredForGST ? faCheck : faTimes} /></span>
            }
          </div>
          <div className='rows'>
            <span className='terms'>Are you using an Agent?:</span>
            {
              <span className='value'><FontAwesomeIcon icon={user.areYouUsingAgent ? faCheck : faTimes} /></span>
            }
          </div>
          {user.areYouUsingAgent && this.renderRow('Agent Name:', user.agentName)}
        </div>
        {/* {(config.exceptedStates.includes(user.officeAddress.state) && user.type === 'seller') || user.type === 'buyer'
          ? null
          : <div className='columns info-column'>
            {CustomList(referee1, { title: 'Referee 1' })}
            {CustomList(referee2, { title: 'Referee 2' })}
          </div>
        } */}

        <div className='columns info-column'>
          {user.type === 'seller' && user.discount && this.renderRow('Discount:', user.discount + '%')}
          {/* {user.type === 'seller' && config.exceptedStates.includes(user.officeAddress.state) && this.renderRow('Stock & Station license or permit number:', user.SSPermitNumber, { required: true })}
          {user.type === 'seller' && config.exceptedStates.includes(user.officeAddress.state) && this.renderRow('Permit Held For:', user.SSPermitNumberOfYears, { required: true })}
          {user.type === 'seller' && this.renderRow('Settlement terms & conditions:', user.saleConditions)}
          {user.type === 'seller' && config.exceptedStates.includes(user.officeAddress.state) && user.licenseFile && user.licenseFile.url && (LinkRow('Stock and station agent license or permit:', user.licenseFile.url))}
          {user.type === 'seller' && config.exceptedStates.includes(user.officeAddress.state) && !user.licenseFile && this.renderRow('Stock and station agent license or permit:', '', { required: true })}
          {user.type === 'seller' && user.policyFile && user.policyFile.url && (LinkRow('Link professional indemnity policy:', user.policyFile.url))}
          {user.type === 'seller' && !user.policyFile && this.renderRow('Link professional indemnity policy:', '', { required: true })} */}
          {user.createdAt && this.renderRow('Date joined Farmgate:', new Date(user.createdAt).toLocaleDateString())}
          {user.updatedData && [
            <div className='rows' key='last-modified'>
              <span>Last Modified By</span>
              <span>
                <a href={`/#/users/${user.updatedData.userId}/show`}>{`${user.updatedData.firstName} ${user.updatedData.lastName}`}</a>
              </span>
            </div>,
            <div className='rows' key='last-updated'>
              <span>Last Updated At</span>
              <span>
                <span>{`${new Date(user.updatedData.updatedAt).toLocaleString()}`}</span>
              </span>
            </div>
          ]}
          <div className='rows'>
            <span className='terms'>Agreed to Terms and Conditions:</span>
            {
              <span className='value'><FontAwesomeIcon icon={user.acceptedTerms ? faCheck : faTimes} /></span>
            }
          </div>
          {this.renderDispute(user.disputeStrikes)}
        </div>
      </React.Fragment>
    )
  }

  renderRow(name, value = '', { capitalize = true, required = false } = {}) {
    return (
      <div className='rows'>
        <span className={`${required && value.length === 0 ? 'required-missed' : ''}`}>{name}</span>
        <span>
          <span className={`user-info-value ${capitalize ? 'capitalize' : ''}`}>{value}</span>
        </span>
      </div>
    )
  }

  renderRowNotifications(name, value = '', redColorValue = false) {
    return (
      <div className='rows'>
        <span className={redColorValue ? 'rowNotificationsRed' : 'rowNotifications'}>{name}</span>
        <span>
          <span className={redColorValue ? 'rowNotificationsRed' : 'rowNotifications'}>{value}</span>
        </span>
      </div>
    )
  }

  renderAdminNotes(name, value = '') {
    return (
      <div className='rows admin-notes-row'>
        <span className='name'>{name}</span>
        <span>
          <span className='user-info-value'>{value}</span>
        </span>
      </div>
    )
  }

  renderAdminComments(user) {
    const adminComments = [...user.adminComments]
    return (
      <div className='admin-notes-wrapper'>
        <div className='admin-notes-title'>
          <p>Admin Notes:</p>
        </div>
        <div className='admin-notes-comments'>
          {adminComments.reverse().map((comment) => {
            return (
              <div
                className='admin-notes-comment'
              >
                <span>{`${new Date(comment.updatedAt).toLocaleDateString()} - ${comment.author && comment.author.firstName} ${comment.author && comment.author.lastName} - ${comment.comment}`}</span>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  capitalize(word) {
    return word && word[0].toUpperCase() + word.slice(1)
  }

  /* formatPropertyName (property) {
    let words = property.split('_')
    if (words.length === 1) {
      words = property.replace(/([a-z])([A-Z])/g, '$1 $2').split(' ')
    }
    let newName = ''
    words.forEach((word, index) => {
      word = this.capitalize(word)
      newName = index < words.length - 1 ? newName.concat(word + ' ') : newName.concat(word)
    })
    return newName
  } */

  formatPropertyName(property) {
    if (property.includes('cattle') || property.includes('sheep')) {
      if (property.includes('One')) {
        return 'Auto-Bids are Open'
      }
      if (property.includes('Ten')) {
        return 'Listings are Open'
      }
      if (property.includes('Open')) {
        return 'Auction is Live'
      }
    } else if (property.includes('lot')) {
      if (property.includes('won')) {
        return 'Lots Won'
      }
      if (property.includes('lost')) {
        return 'Lots Lost'
      }
      if (property.includes('notify')) {
        return 'Lot Closing'
      }
    } else return ''
  }

  renderSettings(user) {
    const settings = user.settings
    const pushPermissions = settings.pushPermissions
    const smsRemindersPermissions = settings.smsRemindersPermissions
    let push = []
    let smsCattle = []
    let smsSheep = []
    for (const property in pushPermissions) {
      push.push(this.renderRowNotifications(this.formatPropertyName(property) + ': ', pushPermissions[property] ? 'On' : 'Off', !pushPermissions[property]))
    }
    for (const property in smsRemindersPermissions) {
      if (property.includes('cattle')) {
        smsCattle.push(this.renderRowNotifications(this.formatPropertyName(property) + ': ', smsRemindersPermissions[property] ? 'On' : 'Off', !smsRemindersPermissions[property]))
      } else {
        smsSheep.push(this.renderRowNotifications(this.formatPropertyName(property) + ': ', smsRemindersPermissions[property] ? 'On' : 'Off', !smsRemindersPermissions[property]))
      }
    }
    smsCattle = smsCattle.reverse()
    smsSheep = smsSheep.reverse()

    return (
      <React.Fragment>
        <div className='columns info-column row'>
          {/* { CustomList(push, { title: 'Push Notifications' }) }
          { CustomList(sms, { title: 'SMS Notifications' }) } */}
          <div>
            <h3 className='notifications'>Cattle Text Notifications</h3>
            {smsCattle}
            <h3 className='notifications'>Sheep Text Notifications</h3>
            {smsSheep}
          </div>
          <div className='columns-settings'>
            <h3 className='notifications'>Push Notifications</h3>
            {push}
          </div>
        </div>
      </React.Fragment>
    )
  }

  renderDispute(value) {
    return (
      <div className='rows dispute'>
        <span>Dispute Strikes: </span>
        <span className='boxes-wrapper'>
          <div className='box-block'>
            <span className='box-value'>1</span>  <span className={`box ${value && value.includes(1) ? 'active' : ''}`} />
          </div>
          <div className='box-block'>
            <span className='box-value'>2</span> <span className={`box ${value && value.includes(2) ? 'active' : ''}`} />
          </div>
          <div className='box-block'>
            <span className='box-value'>3</span> <span className={`box ${value && value.includes(3) ? 'active' : ''}`} />
          </div>
        </span>
      </div>
    )
  }

  renderApprovedSellingCategories(value) {
    return (
      <div className='rows dispute'>
        <span>Approved Selling Categories: </span>
        <span className='boxes-wrapper'>
          {this.state.kindsList.map((item, index) => (
            <div className='box-block'>
              <span className={`box ${value && value.includes(item.title) ? 'active' : ''}`} />
              <span className='box-value'>{item.title}</span>
            </div>

          ))}
        </span>
      </div>
    )
  }

  renderRequestedSellingCategories(value) {
    return (
      <div className='rows dispute'>
        <span>Requested Selling Categories: </span>
        <span className='boxes-wrapper'>
          {this.state.kindsList.map((item, index) => (
            <div className='box-block'>
              <span className={`box ${value && value.includes(item.title) ? 'active' : ''}`} />
              <span className='box-value'>{item.title}</span>
            </div>

          ))}
        </span>
      </div>
    )
  }

  handleOpenEdit(record) {
    this.setState({
      showEditModal: true,
      workId: record._id,
      editValue: record.cents ? parseFloat(record.cents.slice(1)) : 0
    })
  }

  handleOpenDelete(record) {
    this.setState({
      showDeleteModal: true,
      workId: record._id
    })
  }

  handleCloseEdit() {
    this.setState({
      showEditModal: false
    })
  }

  handleCloseDelete() {
    this.setState({
      showDeleteModal: false
    })
  }

  handleUpdate() {
    dataProvider(UPDATE, 'bids', {
      id: this.state.workId,
      data: {
        bid: {
          cents: parseFloat((this.state.editValue * 100).toFixed(2)) // convert to cents
        }
      }
    })
      .then(this.loadBids)
      .catch((error) => {
        this.props.showNotification(error.message, 'warning')
      })
      .then(() => {
        this.setState({
          showEditModal: false
        })
      })
  }

  handleDelete() {
    dataProvider(DELETE, 'bids', {
      id: this.state.workId
    })
      .then(this.loadBids)
      .catch((error) => {
        this.props.showNotification(error.message, 'warning')
      })
      .then(() => {
        this.setState({
          showDeleteModal: false
        })
      })
  }

  setPageBids(page) {
    this.setState({
      bids: update(this.state.bids, {
        page: { $set: page }
      })
    }, this.loadBids)
  }

  setPerPageBids(perPage) {
    this.setState({
      bids: update(this.state.bids, {
        perPage: { $set: perPage }
      })
    }, this.loadBids)
  }

  setSortBids(field) {
    let order = 'ASC'
    if (this.state.bids.field === field) {
      order = this.state.bids.order === 'ASC' ? 'DESC' : 'ASC'
    }
    this.setState({
      bids: update(this.state.bids, {
        field: { $set: field },
        order: { $set: order }
      })
    }, this.loadBids)
  }

  renderUserBids({ ids, data, currentSort, filteredProps }) {
    const BidDateField = ({ record = {} }) => <span>{new Date(record.createdAt).toLocaleDateString()}</span>
    const BidTimeField = ({ record = {} }) => <span>{new Date(record.createdAt).toLocaleTimeString()}</span>
    const EditBidButton = ({ record = {} }) => <Button onClick={() => this.handleOpenEdit(record)}>Edit</Button>
    const WithdrawBidButton = ({ record = {} }) => <Button onClick={() => this.handleOpenDelete(record)}>Withdraw</Button>
    const AuctionID = ({ record = {} }) => {
      return (
        <span>
          {record &&
            <a href={`/#/auctions/${record._id}/show`} className='vendor-link'>{record.searchNumber}</a>
          }
        </span>
      )
    }
    const AuctionTitle = ({ record = {} }) => {
      return (
        <span>
          {record &&
            <a href={`/#/auctions/${record._id}/show`} className='vendor-link'>{record.title}</a>
          }
        </span>
      )
    }
    const LotTitle = ({ record = {} }) => {
      return (
        <span>
          {record.lot &&
            <a href={`/#/lots/${record.lot._id}/show`} className='vendor-link'>{record.lot.publicDetails.title}</a>
          }
        </span>
      )
    }
    const LotNumber = ({ record = {} }) => {
      return (
        <span>
          {record.lot &&
            <a href={`/#/lots/${record.lot._id}/show`} className='vendor-link'>{record.lot.number}</a>
          }
        </span>
      )
    }
    return ([
      <Dialog
        open={this.state.showEditModal}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
        key='edit-dialog'
      >
        <DialogTitle id='alert-dialog-slide-title'>
          Edit Bid
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            <Input
              value={this.state.editValue}
              placeholder='Bid Amount'
              name='Bid Amount'
              onChange={({ target: { value } }) => {
                this.setState({
                  editValue: value
                })
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseEdit} color='secondary'>
            Close
          </Button>
          <Button onClick={this.handleUpdate} color='primary'>
            Update
          </Button>
        </DialogActions>
      </Dialog>,
      <Dialog
        open={this.state.showDeleteModal}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
        key='delete-dialog'
      >
        <DialogTitle id='alert-dialog-slide-title'>
          Delete Bid
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            Are you sure you want to delete bid?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseDelete} color='secondary'>
            No
          </Button>
          <Button onClick={this.handleDelete} color='primary'>
            Yes
          </Button>
        </DialogActions>
      </Dialog>,
      <div className='datagrid-wrapper'>
        <Datagrid
          ids={ids}
          data={data}
          currentSort={currentSort}
          setSort={this.setSortBids}
          {...filteredProps}
          key='datagrid'
        >
          <ReferenceField source='auction' reference='auctions' label='Auction' allowEmpty>
            <AuctionTitle />
          </ReferenceField>
          <ReferenceField source='auction' reference='auctions' label='Auction ID' allowEmpty>
            <AuctionID />
          </ReferenceField>
          <LotNumber source='lot.number' label='Lot No' sortable />
          <LotTitle source='lot.publicDetails.title' label='Lot Description' sortable />
          <BidDateField label='Bid Date' />
          <BidTimeField label='Bid Time' />
          <TextField source='cents' label='Bid Amount' sortable />
          <TextField source='type' label='Bid Type' sortable={false} />
          <EditBidButton label='Edit Bid' />
          <WithdrawBidButton label='Delete Bid' />
        </Datagrid>
        <OwnPagination
          page={this.state.bids.page}
          rowsPerPage={this.state.bids.perPage}
          perPage={this.state.bids.perPage}
          total={this.state.bids.total}
          setPage={this.setPageBids}
          setPerPage={this.setPerPageBids}
        />
      </div>
    ])
  }

  renderInvoices() {
    return (
      <InvoiceListComponent {...this.props} />
    )
  }

  setPerPage(perPage) {
    this.setState({
      perPage
    }, this.loadData)
  }

  setPagePurchased(page) {
    this.setState({
      lots: update(this.state.lots, {
        purchased: {
          page: { $set: page }
        }
      })
    }, () => {
      this.getLots('purchased')
    })
  }

  setPageSold(page) {
    this.setState({
      lots: update(this.state.lots, {
        sold: {
          page: { $set: page }
        }
      })
    }, () => {
      this.getLots('sold')
    })
  }

  setPageUpcoming(page) {
    this.setState({
      lots: update(this.state.lots, {
        upcoming: {
          page: { $set: page }
        }
      })
    }, () => {
      this.getLots('upcoming')
    })
  }

  setPerPagePurchased(perPage) {
    this.setState({
      lots: update(this.state.lots, {
        purchased: {
          perPage: { $set: perPage }
        }
      })
    }, () => {
      this.getLots('purchased')
    })
  }

  setPerPageUpcoming(perPage) {
    this.setState({
      lots: update(this.state.lots, {
        upcoming: {
          perPage: { $set: perPage }
        }
      })
    }, () => {
      this.getLots('upcoming')
    })
  }

  setPerPageSold(perPage) {
    this.setState({
      lots: update(this.state.lots, {
        sold: {
          perPage: { $set: perPage }
        }
      })
    }, () => {
      this.getLots('sold')
    })
  }

  setSortUpcoming(field) {
    let order = 'ASC'
    if (this.state.lots.upcoming.field === field) {
      order = this.state.lots.upcoming.order === 'ASC' ? 'DESC' : 'ASC'
    }
    this.setState({
      lots: update(this.state.lots, {
        upcoming: {
          field: { $set: field },
          order: { $set: order }
        }
      })
    }, () => {
      this.getLots('upcoming')
    })
  }

  setSortPurchased(field) {
    let order = 'ASC'
    if (this.state.lots.purchased.field === field) {
      order = this.state.lots.purchased.order === 'ASC' ? 'DESC' : 'ASC'
    }
    this.setState({
      lots: update(this.state.lots, {
        purchased: {
          field: { $set: field },
          order: { $set: order }
        }
      })
    }, () => {
      this.getLots('purchased')
    })
  }

  setSortSold(field) {
    let order = 'ASC'
    if (this.state.lots.sold.field === field) {
      order = this.state.lots.sold.order === 'ASC' ? 'DESC' : 'ASC'
    }
    this.setState({
      lots: update(this.state.lots, {
        sold: {
          field: { $set: field },
          order: { $set: order }
        }
      })
    }, () => {
      this.getLots('sold')
    })
  }

  renderLots({ ids, data, currentSort, filteredProps, type }) {
    const LotId = ({ record = {} }) => <a href={`/#/lots/${record._id}/show`} className='self-link'>{record.searchNumericId}</a>
    const LotNumber = ({ record = {} }) => <a href={`/#/lots/${record._id}/show`} className='self-link'>{record.number}</a>
    const TitleLinkField = ({ record = {} }) => <a href={`/#/lots/${record._id}/show`} className='self-link'>{record.publicDetails.title}</a>
    const AuctionField = ({ record = {} }) => <a href={`/#/auctions/${record.auction}/show`} className='vendor-link'>{record.auctionData.title}</a>
    const SellerField = ({ record = {} }) => <a href={`/#/users/${record.createdBy}/show`} className='vendor-link'>{record.creatorData.firstName} {record.creatorData.lastName}</a>
    const WinnerField = ({ record = {} }) => <span className='vendor-link'>{record ? `${record.firstName} ${record.lastName}` : ''}</span>
    const AuctionTimeField = ({ record = {} }) => <span className='vendor-link'>{record ? new Date(record.auctionData.liveAt).toLocaleTimeString() : ''}</span>
    const PriceField = (data) => <span>{makePrice(data.record[data.source], { bidding: data.record.bidding })}</span>
    const StatusField = ({ record = {} }) => <span>{generateStatusString(record.status)}</span>
    const BuyerField = ({ record = {} }) => {
      /* if (record.status !== 'soldAfterAuction') {
        return (
          <ReferenceField record={record} source='winner._id' reference='users' label='Buyer/ Losing bidder(passed lots)' basePath='users' allowEmpty linkType={false} sortable={false}>
            <WinnerField />
          </ReferenceField>
        )
      } else {
        return <TextField />
      } */
      return (
        <ReferenceField record={record} source='winner._id' reference='users' label='Buyer/ Losing bidder(passed lots)' basePath='users' allowEmpty linkType={false} sortable={false}>
          <WinnerField />
        </ReferenceField>
      )
    }

    return (
      <div className='datagrid-wrapper'>
        <Datagrid
          ids={ids}
          data={data}
          currentSort={{ field: this.state.lots[type].field, order: this.state.lots[type].order }}
          setSort={type === 'upcoming'
            ? this.setSortUpcoming
            : type === 'purchased'
              ? this.setSortPurchased
              : type === 'sold'
                ? this.setSortSold : ''}
          {...filteredProps}
        >
          <LotId label='ID' sortable />
          <LotNumber label='Lot Number' sortable />
          <TitleLinkField label='Title' sortable />
          {type !== SOLD_LOTS && <AuctionField source='auctionData.title' label='Auction' sortable />}
          <DateField source='auctionData.liveAt' label='Auction Date' sortable />
          <AuctionTimeField label='Auction Time' sortable />
          <TextField source='kindData.title' label='Category' sortable />
          <NumberField source='count' label='Head' sortable />
          <TextField source='_startPrice' label='Start Price' sortable />
          <TextField source='_reserveCents' label='Reserve Price' sortable />
          {type !== 'upcoming' &&
            [
              <TextField source='_closedPrice' label='Purchased/Sold Price' sortable />
            ]
          }
          <TextField source='bidding' label='Bid Type' sortable />
          <TextField source='_bidIncrementCents' label='Bid Increment' sortable />
          <BooleanField source='draft' label='Draft' sortable />
          <BooleanField source='approved' label='Approved' sortable />
          <SellerField source='createdBy' label='Seller' sortable />
          {type !== 'upcoming' &&
            [
              <StatusField source='status' label='Closed Status' sortable />,
              <PriceField source='closedPrice' label='Closed Price' />,
              <BuyerField label='Buyer' />
            ]
          }
        </Datagrid>
        {type === 'purchased' &&
          <OwnPagination
            page={this.state.lots.purchased.page}
            rowsPerPage={this.state.lots.purchased.perPage}
            perPage={this.state.lots.purchased.perPage}
            total={this.state.lots.purchased.total}
            setPage={this.setPagePurchased}
            setPerPage={this.setPerPagePurchased}
          />
        }
        {type === 'upcoming' &&
          <OwnPagination
            page={this.state.lots.upcoming.page}
            rowsPerPage={this.state.lots.upcoming.perPage}
            perPage={this.state.lots.upcoming.perPage}
            total={this.state.lots.upcoming.total}
            setPage={this.setPageUpcoming}
            setPerPage={this.setPerPageUpcoming}
          />
        }
        {type === 'sold' &&
          <OwnPagination
            page={this.state.lots.sold.page}
            rowsPerPage={this.state.lots.sold.perPage}
            perPage={this.state.lots.sold.perPage}
            total={this.state.lots.sold.total}
            setPage={this.setPageSold}
            setPerPage={this.setPerPageSold}
          />
        }
      </div>
    )
  }

  render() {
    let filteredProps = { ...this.props }
    const {
      info,
      bids,
      lots,
      invoices,
      connectivity
    } = this.state
    const { basePath } = this.props
    delete filteredProps.hasList
    delete filteredProps.hasShow
    delete filteredProps.hasEdit
    return (
      <div className='users-wrapper'>
        <div className='datagrid-wrapper'>
          {Object.keys(info.user).length && (
            <React.Fragment>
              <UserTitle userId={generateUserId(info.user.type, info.user.numericId)} name={info.user.firstName + ' ' + info.user.lastName} status={info.user.connectivity && info.user.connectivity.onlineState ? info.user.connectivity.onlineState : ''} basePath={basePath} record={info.user} />
              {info.user.type === 'seller' && (info.user.status === 'approved' || info.user.status === 'approved-seller' || info.user.status === 'approved-buyer-seller') && <div className='btn-wrapper'>
                {info && (info.user.role === 'admin' || info.user.approvedStockTypes.includes("Cattle")) && <Button className='create-lot-btn'
                  onClick={() => {
                    this.props.history.push({
                      pathname: `/lots/create`,
                      search: `?userId=${this.props.id}&kind=cattle&saleConditions=${info.user.saleConditions}&agencyName=${info.user.agencyName}`
                    })
                  }}>
                  <FontAwesomeIcon color={'#3f51b5'} icon={faFileAlt} />
                  Create Cattle Lot
                </Button>
                }
                {info && (info.user.role === 'admin' || info.user.approvedStockTypes.includes("Sheep")) && <Button
                  className='create-lot-btn'
                  onClick={() => {
                    this.props.history.push({
                      pathname: `/lots/create`,
                      search: `?userId=${this.props.id}&kind=sheep&saleConditions=${info.user.saleConditions}&agencyName=${info.user.agencyName}`
                    })
                  }}>
                  <FontAwesomeIcon color={'#3f51b5'} icon={faFileAlt} />
                  Create Sheep Lot
                </Button>
                }
                {info && (info.user.role === 'admin' || info.user.approvedStockTypes.includes("Goat")) && <Button className='create-lot-btn'
                  onClick={() => {
                    this.props.history.push({
                      pathname: `/lots/create`,
                      search: `?userId=${this.props.id}&kind=goat&saleConditions=${info.user.saleConditions}&agencyName=${info.user.agencyName}`
                    })
                  }}>
                  <FontAwesomeIcon color={'#3f51b5'} icon={faFileAlt} />
                  Create Goat Lot
                </Button>
                }
                {info && (info.user.role === 'admin' || info.user.approvedStockTypes.includes("Sheep")) && <Button
                  className='create-lot-btn'
                  onClick={() => {
                    this.props.history.push({
                      pathname: `/lots/create`,
                      search: `?userId=${this.props.id}&kind=sheep&abbLamb=true&saleConditions=${info.user.saleConditions}&agencyName=${info.user.agencyName}`
                    })
                  }}>
                  <FontAwesomeIcon color={'#3f51b5'} icon={faFileAlt} />
                  AbbLamb Prime Lambs
                </Button>
                }
              </div>}
              {this.collapsible({
                title: 'Info',
                isOpened: info.isOpened,
                changeCollapse: this.changeCollapse,
                publicId: INFO,
                body: this.renderUserInfo(info.user)
              })}
              <div className='admin-notes'>
                {this.renderAdminComments(info.user)}
              </div>
              <div>
                {this.renderSettings(info.user)}
              </div>
              {info.user.logo &&
                <div className='logo'>
                  <h3>Logo:</h3>
                  <img alt={info.user.logo.name} src={info.user.logo.url} className='user-logo-image' />
                </div>
              }

            </React.Fragment>
          )}
        </div>
        <div className='user-info-table'>
          {Object.keys(info.user).length && (
            <React.Fragment>
              {this.collapsible({
                title: `Upcoming Lots, ${lots.upcoming.total} Lots`,
                isOpened: lots.upcoming.isOpened,
                changeCollapse: this.changeCollapse,
                publicId: UPCOMING_LOTS,
                body: this.renderLots({
                  ids: lots.upcoming.ids,
                  data: lots.upcoming.data,
                  type: UPCOMING_LOTS,
                  filteredProps: filteredProps
                })
              })}
            </React.Fragment>
          )}
        </div>
        <div className='user-info-table'>
          {Object.keys(info.user).length && (
            <React.Fragment>
              {this.collapsible({
                title: `Purchased Lots, ${lots.purchased.total} Lots`,
                isOpened: lots.purchased.isOpened,
                changeCollapse: this.changeCollapse,
                publicId: PURCHASED_LOTS,
                body: this.renderLots({
                  ids: lots.purchased.ids,
                  data: lots.purchased.data,
                  type: PURCHASED_LOTS,
                  currentSort: { field: bids.field, order: bids.order },
                  filteredProps: filteredProps
                })
              })}
            </React.Fragment>
          )}
        </div>
        <div className='user-info-table'>
          {Object.keys(info.user).length && (
            <React.Fragment>
              {this.collapsible({
                title: `Sold Lots, ${lots.sold.total} Lots`,
                isOpened: lots.sold.isOpened,
                changeCollapse: this.changeCollapse,
                publicId: SOLD_LOTS,
                body: this.renderLots({
                  ids: lots.sold.ids,
                  data: lots.sold.data,
                  type: SOLD_LOTS,
                  currentSort: { field: bids.field, order: bids.order },
                  filteredProps: filteredProps
                })
              })}
            </React.Fragment>
          )}
        </div>
        <div className='user-info-table'>
          {Object.keys(info.user).length && (
            <React.Fragment>
              {this.collapsible({
                title: 'Bids',
                isOpened: bids.isOpened,
                changeCollapse: this.changeCollapse,
                publicId: BIDS,
                body: this.renderUserBids({
                  ids: bids.ids,
                  data: bids.data,
                  currentSort: { field: bids.field, order: bids.order },
                  filteredProps: filteredProps
                })
              })}
            </React.Fragment>
          )}
        </div>
        <div className='user-info-table'>
          {Object.keys(info.user).length && (
            <React.Fragment>
              {this.collapsible({
                title: 'Invoices',
                isOpened: invoices.isOpened,
                changeCollapse: this.changeCollapse,
                publicId: INVOICES,
                body: this.renderInvoices({
                  currentSort: { field: bids.field, order: bids.order },
                  filteredProps: filteredProps
                })
              })}
            </React.Fragment>
          )}
        </div>
        <div className='user-info-table'>
          {Object.keys(info.user).length && (
            <React.Fragment>
              {this.collapsible({
                title: 'Connectivity',
                isOpened: connectivity.isOpened,
                changeCollapse: this.changeCollapse,
                publicId: CONNECTIVITY,
                body: this.renderConnectivityInfo({
                  ids: connectivity.ids,
                  data: connectivity.data,
                  currentSort: { field: bids.field, order: bids.order },
                  filteredProps: filteredProps
                })
              })}
            </React.Fragment>
          )}
        </div>
      </div>
    )
  }
}

export const UserShowComponent = connect(null, {
  showNotification
})(UserShowBase)

export const UserShow = (props) => (
  <div>
    <UserShowComponent {...props} />
  </div>
)

import Immutable from 'immutable'
import { findFieldByPublicId, checkNested } from '../../utils/form-lot'

const PIC_REGEXP = /^[0-9A-Z]{8}$/
const NSW_REGEXP = /^N[A-K]{1}[0-9]{6}$/
const QLD_REGEXP = /^Q[A-Z]{3}[0-9]{4}$/
const VIC_REGEXP = /^3[A-Z]{4}[0-9]{3}$/
const WA_REGEXP = /^W[A-Z]{3}[0-9]{4}$/
const SA_REGEXP = /^S[ABCK]{1}[0-9]{6}$/
const NT_REGEXP = /^T[A-Z]{3}[0-9]{4}$/
const TAS_REGEXP = /^M[A-Z]{3}[0-9]{4}$/
const WEANING_DETAILS_EXCEPTIONS = ['Calves', 'Vealers', 'Weaners', 'Yearlings', 'Lambs']
const MIN_WEIGHED_HEADS_PERCENTAGE = 10

export function validateDraftLot (draftLot, { draft = true, silent = false, type = 'edit' } = {}) {
  if (draft && draftLot.approved) {
    return {
      valid: false,
      message: 'Can`t approve draft lot'
    }
  }
  if (draftLot.optiweighAllowed && (!draftLot.optiweigh || !draftLot.assessmentOptiweigh || !draftLot.averageOptiweigh)) {
    return {
      valid: false,
      message: 'Please upload Optiweigh or turn it off'
    }
  }
  if (draftLot.optiweighAllowed && draft === false) {
    let bidCents = bidCentsValidation(draftLot)
    if (!bidCents.valid) {
      return bidCents
    }
    if (type === 'create') {
      if (draftLot.values && (draftLot.auction && draftLot.auction.kindData.type === 'cattle')) {
        const evenTempered = findFieldByPublicId(draftLot.values, 'evenTempered')
        const reactive = findFieldByPublicId(draftLot.values, 'reactive')
        const slightlyReactive = findFieldByPublicId(draftLot.values, 'slightlyReactive')
        if (!draftLot.optiweighAllowed && ((!evenTempered || !evenTempered.value) && (!reactive || !reactive.value) && (!slightlyReactive || !slightlyReactive.value))) {
          return {
            valid: false,
            message: 'Please fill at least one Temperament field'
          }
        }
      }
    } else {
      if (draftLot.details.values && (draftLot.auction && draftLot.kindData.type === 'cattle')) {
        const evenTempered = findFieldByPublicId(draftLot.details.values, 'evenTempered')
        const reactive = findFieldByPublicId(draftLot.details.values, 'reactive')
        const slightlyReactive = findFieldByPublicId(draftLot.details.values, 'slightlyReactive')
        if (!draftLot.optiweighAllowed && ((!evenTempered || !evenTempered.value) && (!reactive || !reactive.value) && (!slightlyReactive || !slightlyReactive.value))) {
          return {
            valid: false,
            message: 'Please fill at least one Temperament field'
          }
        }
      }
    }
    let customFormValidation = {}
    if (type === 'create') {
      customFormValidation = validateForm(draftLot.values, { draft })
    } else {
      customFormValidation = validateForm(draftLot.details.values, { draft })
    }
    if (customFormValidation.values.length === 0) {
      customFormValidation.invalid = false
    }
    if (customFormValidation.invalid) {
      let invalidElems = findAllInvalidFields(customFormValidation.values).filter(item => item.publicId !== 'individualAssessment')
      let msg
      if (invalidElems.length === 0) {
        return {
          valid: true,
          message: ''
        }
      }
      if (invalidElems.length > 1) {
        msg = 'Please fill in required fields with rеd asterisk'
      } else if (invalidElems.length > 0) {
        switch (true) {
          case invalidElems[0].publicId === 'vendorPic':
            let vendorDetails = draftLot.details.values.find((elem) => {
              return elem.title === 'Vendor Details'
            })
            let state = vendorDetails.values.find((elem) => {
              return elem.publicId === 'state'
            })
            switch (state.value) {
              case 'NSW':
                msg = 'Your State is NSW. PIC No. starts with N then 1 letter and 6 numbers.'
                break
              case 'QLD':
                msg = 'Your State is QLD. PIC No. starts with Q then 3 letters and 4 numbers.'
                break
              case 'VIC':
                msg = 'Your State is VIC. PIC No. starts with 3 then 4 letters and 3 numbers.'
                break
              case 'WA':
                msg = 'Your State is WA. PIC no. starts with W then 3 letters and 4 numbers.'
                break
              case 'SA':
                msg = 'Your State is SA. PIC no. starts with S then 1 letter and 6 numbers.'
                break
              case 'NT':
                msg = 'Your State is NT. PIC no. starts with T then 3 letters and 4 numbers.'
                break
              case 'TAS':
                msg = 'Your state is TAS. PIC no. starts with M then 3 letters and 4 numbers.'
                break
              default:
                msg = `Please Enter correct "${invalidElems[0].title}". based on the chosen state. This is a required field*`
                break
            }
            break
          default:
            msg = `Please enter ${invalidElems[0].title} This is a required field*`
        }
      }
      return {
        valid: false,
        message: msg
      }
    }
  } else if (!draft) {
    if (draftLot.description && !draftLot.description.trim()) {
      return {
        valid: false,
        message: 'Please Enter Stock Description'
      }
    }
    let bidCents = bidCentsValidation(draftLot)
    if (!bidCents.valid) {
      return bidCents
    }
    if (!draftLot.count) {
      return {
        valid: false,
        message: 'Please Enter Number of Head'
      }
    }
    if (!draftLot.countWeighed && draftLot.kindData && draftLot.kindData.type === 'sheep' && !draftLot.auctionData.abbLambSale) {
      return {
        valid: false,
        message: 'Please enter Number of Head Weighed'
      }
    }
    if (type === 'create') {
      if (draftLot.values && (draftLot.auction && draftLot.auction.kindData.type === 'cattle')) {
        const evenTempered = findFieldByPublicId(draftLot.values, 'evenTempered')
        const reactive = findFieldByPublicId(draftLot.values, 'reactive')
        const slightlyReactive = findFieldByPublicId(draftLot.values, 'slightlyReactive')
        if (!draftLot.optiweighAllowed && ((!evenTempered || !evenTempered.value) && (!reactive || !reactive.value) && (!slightlyReactive || !slightlyReactive.value))) {
          return {
            valid: false,
            message: 'Please fill at least one Temperament field'
          }
        }
      }
    } else {
      if (draftLot.details.values && (draftLot.auction && draftLot.kindData.type === 'cattle')) {
        const evenTempered = findFieldByPublicId(draftLot.details.values, 'evenTempered')
        const reactive = findFieldByPublicId(draftLot.details.values, 'reactive')
        const slightlyReactive = findFieldByPublicId(draftLot.details.values, 'slightlyReactive')
        if (!draftLot.optiweighAllowed && ((!evenTempered || !evenTempered.value) && (!reactive || !reactive.value) && (!slightlyReactive || !slightlyReactive.value))) {
          return {
            valid: false,
            message: 'Please fill at least one Temperament field'
          }
        }
      }
    }
    if (window.location.href.includes('Sheep') && draftLot.countWeighed && (draftLot.countWeighed * 100 / draftLot.count) < MIN_WEIGHED_HEADS_PERCENTAGE) {
      return {
        valid: false,
        message: 'Amount of weighed heads should be at least 10% of total amount of heads'
      }
    }
    let customFormValidation = {}
    if (type === 'create') {
      customFormValidation = validateForm(draftLot.values, { draft })
    } else {
      customFormValidation = validateForm(draftLot.details.values, { draft })
    }
    if (customFormValidation.invalid) {
      let invalidElems = findAllInvalidFields(customFormValidation.values)
      let msg
      if (invalidElems.length > 1) {
        msg = 'Please fill in required fields with rеd asterisk'
      } else if (invalidElems.length > 0) {
        switch (true) {
          case invalidElems[0].publicId === 'vendorPic':
            let vendorDetails = draftLot.details.values.find((elem) => {
              return elem.title === 'Vendor Details'
            })
            let state = vendorDetails.values.find((elem) => {
              return elem.publicId === 'state'
            })
            switch (state.value) {
              case 'NSW':
                msg = 'Your State is NSW. PIC No. starts with N then 1 letter and 6 numbers.'
                break
              case 'QLD':
                msg = 'Your State is QLD. PIC No. starts with Q then 3 letters and 4 numbers.'
                break
              case 'VIC':
                msg = 'Your State is VIC. PIC No. starts with 3 then 4 letters and 3 numbers.'
                break
              case 'WA':
                msg = 'Your State is WA. PIC no. starts with W then 3 letters and 4 numbers.'
                break
              case 'SA':
                msg = 'Your State is SA. PIC no. starts with S then 1 letter and 6 numbers.'
                break
              case 'NT':
                msg = 'Your State is NT. PIC no. starts with T then 3 letters and 4 numbers.'
                break
              case 'TAS':
                msg = 'Your state is TAS. PIC no. starts with M then 3 letters and 4 numbers.'
                break
              default:
                msg = `Please Enter correct "${invalidElems[0].title}". based on the chosen state. This is a required field*`
                break
            }
            break
          default:
            msg = `Please enter ${invalidElems[0].title} This is a required field*`
        }
      }
      return {
        valid: false,
        message: msg
      }
    }
  } else {
    return {
      valid: true,
      message: ''
    }
  }
}

function bidCentsValidation (draftLot) {
  if (draftLot.startPrice && draftLot.reserveCents &&
    draftLot.reserveCents < draftLot.startPrice) {
    return {
      valid: false,
      message: 'Your Reserve Price can\'t be lower than your Start Price'
    }
  }
  if (!draftLot.bidding) {
    return {
      valid: false,
      message: 'Please select bidding'
    }
  }
  if (!draftLot.bidIncrementCents) {
    return {
      valid: false,
      message: 'Please select bid increment'
    }
  }
  return {
    valid: true,
    message: ''
  }
}

export function findAllInvalidFields (elements,
  { ignoreRFNumber = true, ignoreClosedRFBool = true } = {}) {
  return findAllFields(elements, el => el.invalid,
    { ignoreRFNumber, ignoreClosedRFBool })
}

export function findAllFields (element, findFunc, options = {}) {
  if (!element) return []
  let resultArr = []
  if (!Array.isArray(element)) {
    let res = checkNested('findAll', element, findFunc, options)
    if (res.length) resultArr = resultArr.concat(res)
  } else {
    for (let child of element) {
      if (findFunc(child)) {
        resultArr = resultArr.concat(child)
      }
      let res = checkNested('findAll', child, findFunc, options)
      if (res.length) resultArr = resultArr.concat(res)
    }
  }
  return resultArr
}

/*
  recursively validate all form fields

  returns {
    values: {...} - same fields, but with updated 'invalid' prop
    invalid: true|false - is there at least one invalid field or not
  }
*/
export function validateForm (fields, { draft = false, change = true, context } = {}) {
  /* use change = false if possible, it just returns 'invalid: true' if values are invalid
    without creating Immutable objects
    which drastically increases performance */
  if (!context) {
    // this function is recursive, but we want to keep a reference to initial fields
    context = fields
  }
  let invalid = false
  let newFields = change ? Immutable.List(fields) : fields
  fields.forEach((field, index) => {
    let newField = change ? Immutable.Map(field) : field
    // reset field invalid state
    if (change) {
      newField = newField.set('invalid', false)
    }
    // handle nested types
    switch (field.type) {
      case 'group': {
        // handle fields inside groups
        let validatedNestedFields = validateForm(field.values, { draft, change, context })
        invalid = invalid || validatedNestedFields.invalid
        if (change) {
          newField = newField.set('values', validatedNestedFields.values)
        }
        break
      }
      case 'repeatForNumber': {
        // handle fields inside Individual Assessment
        let newNestedFields = change ? Immutable.List(field.values) : field.values
        let nestedInvalid = false
        field.values && field.values.forEach((nestedFields, index) => {
          let validatedNestedFields = validateForm(nestedFields, { draft, change, context })
          nestedInvalid = nestedInvalid || validatedNestedFields.invalid
          if (change) {
            newNestedFields = newNestedFields.set(index, validatedNestedFields.values)
          }
        })
        invalid = invalid || nestedInvalid
        if (change) {
          newField = newField.set('values', newNestedFields.toArray())
        }
        break
      }
      case 'repeatForBool': {
        // handle fields inside accordions
        let newNestedFields = change ? Immutable.List(field.values) : field.values
        let nestedInvalid = false
        field.values && field.values.forEach((nestedFields, index) => {
          let validatedNestedFields = validateForm(nestedFields, { draft, change, context })
          nestedInvalid = nestedInvalid || validatedNestedFields.invalid
          if (change) {
            newNestedFields = newNestedFields.set(index, validatedNestedFields.values)
          }
        })
        // if accordion is closed, validate it regardless of values inside
        invalid = invalid || (field.value ? nestedInvalid : false)
        if (change) {
          newField = newField.set('values', newNestedFields.toArray())
        }
        break
      }
      default:
        break
    }
    // field validation
    let isInvalid = isFieldInvalid({ field, draft, context })
    invalid = invalid || isInvalid
    if (change) {
      newField = newField.set('invalid', isInvalid)
      newFields = newFields.set(index, newField.toJS())
    }
  })
  return {
    values: change ? newFields.toArray() : newFields,
    invalid: invalid
  }
}

export function validateFormOptiweigh (fields, { draft = false, change = true, context } = {}) {
  /* use change = false if possible, it just returns 'invalid: true' if values are invalid
    without creating Immutable objects
    which drastically increases performance */
  if (!context) {
    // this function is recursive, but we want to keep a reference to initial fields
    context = fields
  }
  let invalid = false
  let newFields = change ? Immutable.List(fields) : fields
  fields.forEach((field, index) => {
    let newField = change ? Immutable.Map(field) : field
    // reset field invalid state
    if (change) {
      newField = newField.set('invalid', false)
    }
    // handle nested types
    switch (field.type) {
      case 'group': {
        // handle fields inside groups
        let validatedNestedFields = validateForm(field.values, { draft, change, context })
        invalid = invalid || validatedNestedFields.invalid
        if (change) {
          newField = newField.set('values', validatedNestedFields.values)
        }
        break
      }
      case 'repeatForNumber': {
        // handle fields inside Individual Assessment
        let newNestedFields = change ? Immutable.List(field.values) : field.values
        let nestedInvalid = false
        field.values.forEach((nestedFields, index) => {
          let validatedNestedFields = validateForm(nestedFields, { draft, change, context })
          nestedInvalid = nestedInvalid || validatedNestedFields.invalid
          if (change) {
            newNestedFields = newNestedFields.set(index, validatedNestedFields.values)
          }
        })
        invalid = invalid || nestedInvalid
        if (change) {
          newField = newField.set('values', newNestedFields.toArray())
        }
        break
      }
      case 'repeatForBool': {
        // handle fields inside accordions
        let newNestedFields = change ? Immutable.List(field.values) : field.values
        let nestedInvalid = false
        field.values.forEach((nestedFields, index) => {
          let validatedNestedFields = validateForm(nestedFields, { draft, change, context })
          nestedInvalid = nestedInvalid || validatedNestedFields.invalid
          if (change) {
            newNestedFields = newNestedFields.set(index, validatedNestedFields.values)
          }
        })
        // if accordion is closed, validate it regardless of values inside
        invalid = invalid || (field.value ? nestedInvalid : false)
        if (change) {
          newField = newField.set('values', newNestedFields.toArray())
        }
        break
      }
      default:
        break
    }
    // field validation
    let isInvalid = isFieldInvalid({ field, draft, context })
    invalid = invalid || isInvalid
    if (change) {
      newField = newField.set('invalid', isInvalid)
      newFields = newFields.set(index, newField.toJS())
    }
  })
  return {
    values: change ? newFields.toArray() : newFields,
    invalid: invalid
  }
}
// main field validation function
// you can add unique validations here
let isFieldInvalid = ({ field, draft, context }) => {
  let isInvalid = false
  switch (field.type) {
    case 'text':
    case 'textarea':
    case 'date':
    case 'dropdown':
    case 'email':
    case 'number':
    case 'phone':
    case 'user':
    case 'tradingName':
    case 'select':
      if (field.isRequired && !draft) {
        isInvalid = !field.value || field.value === 'NaN' || field.value.trim() === ''
      }
      break
    case 'weight':
      if (field.isRequired && !draft) {
        isInvalid = field.value === 0
      }
      break
    case 'rating':
      if (field.isRequired && !draft) {
        isInvalid = field.value === 0
      }
      break
    case 'picNumber':
      if (field.isRequired && !draft) {
        isInvalid = !PIC_REGEXP.test(field.value)
      } else if (!draft) {
        isInvalid = !PIC_REGEXP.test(field.value) && field.value.trim() !== ''
      }
      break
    case 'multiDropdown':
      if (field.isRequired && !draft) {
        isInvalid = !field.value || field.value.length === 0
      }
      break
    case 'repeatForNumber':
      if (!draft) {
        isInvalid = field.values.some(fieldset => {
          return validateForm(fieldset, draft, { change: false, context }).invalid
        })
      }
      break
    default:
      break
  }

  // validations for special cases
  if (!isInvalid) {
    switch (field.publicId) {
      case 'vendorPic':
        if (!draft) {
          // uncomment to enable PIC validation
          // let stateField = findFieldByPublicId(context, 'state')
          // isInvalid = Boolean(stateField && stateField.value && picCorrespondsToTheRules(field.value, stateField.value))
        }
        break
      case 'haveWeaned':
        if (!draft) {
          isInvalid = haveWeanedFieldIsNotValid(context)
        }
        break
      default:
        break
    }
  }

  return isInvalid
}

export function haveWeanedFieldIsNotValid (lotDetails) {
  let stockCategory = findFieldByPublicId(lotDetails, 'stockCategory')
  let haveWeaned = findFieldByPublicId(lotDetails, 'haveWeaned')
  if (!stockCategory || !haveWeaned) {
    return false
  }
  return !!(Array.isArray(stockCategory.value) && stockCategory.value.find(element => WEANING_DETAILS_EXCEPTIONS.includes(element)) && haveWeaned.value === null)
}

export function picCorrespondsToTheRules (pic, state) {
  switch (state) {
    case 'NSW':
      return !NSW_REGEXP.test(pic) ? 'PIC Numbers in NSW are 8 digits and start with a letter N then 1 letter in the range of A-K and 6 numbers.' : ''
    case 'QLD':
      return !QLD_REGEXP.test(pic) ? 'PIC Numbers in QLD are 8 digits and start with a letter Q then 3 letters then 4 numbers.' : ''
    case 'VIC':
      return !VIC_REGEXP.test(pic) ? 'PIC Numbers in VIC are 8 digits and start with a number 3 then 4 letters then 3 numbers.' : ''
    case 'WA':
      return !WA_REGEXP.test(pic) ? 'PIC Numbers in WA are 8 digits and start with a letter W then 3 letters then 4 numbers.' : ''
    case 'SA':
      return !SA_REGEXP.test(pic) ? 'PIC Numbers in SA are 8 digits and all start with SA or SB or SC or SK then 6 numbers.' : ''
    case 'NT':
      return !NT_REGEXP.test(pic) ? 'PIC Numbers in NT are 8 digits and start with a letter T then 3 letters then 4 numbers.' : ''
    case 'TAS':
      return !TAS_REGEXP.test(pic) ? 'PIC Numbers in TAS are 8 digits and start with a letter M then 3 letters then 4 numbers.' : ''
    default:
      return ''
  }
}

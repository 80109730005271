/* global FileReader */
import React, { Component } from 'react'
import {
  Edit,
  SimpleForm,
  SelectInput,
  GET_LIST,
  NumberInput,
  LongTextInput,
  Toolbar,
  SaveButton,
  TextInput
} from 'react-admin'
import {
  approvedOptions,
  removedOptions,
  draftOptions,
  withdrawnOptions,
  acceptenceOnTerms,
  biddingOptions
} from '../options'
import { dataProvider } from '../../../App'
import LotAssessmentFormEdit from '../../../components/LotAssessmentForm/AssessmentFormEdit'
import DocumentsInputField from '../../../components/DocumentsInputField/DocumentsInputField'
import MediaInputField from '../../../components/MediaInputField/MediaInputField'
import axios from 'axios'
import { Circle } from 'better-react-spinkit'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Slide from '@material-ui/core/Slide'
import Papa from 'papaparse'
import { UserAutoCompleteField } from '../../../components/userAutoCompleteField/userAutoCompleteFIeld'
import './styles.scss'
import Toggle from 'react-toggle'
import { BillForReoffered } from '../../../components/lots/edit/BillForReoffered'
import { findAverageFromArray } from '../../../utils/lots/lotService'
import { UsefulLinks } from '../../../components/auction/UsefulLinks/UsefulLinks'

function Transition (props) {
  return <Slide direction='up' {...props} />
}

export const LotTitle = ({ record = {} }) => {
  return <span>Lot {record ? `'${record._id}'` : ''}</span>
}
const choices = [
  { id: 'noBid', name: 'No Bid' },
  { id: 'passed', name: 'Passed in' },
  { id: 'sold', name: 'Sold' },
  { id: 'soldAfterAuction', name: 'Sold After Auction' }
]

const acceptableValues = [null, 1, 2, 3, 4, 5]

class Select extends Component {
  constructor (props) {
    super(props)
    this.options = this.props.options.filter((option) => {
      return option.kind === this.props.record.kind
    })
  }

  render () {
    return (<SelectInput className='auction-select' onChange={this.props.onChange} label='Auction' source='auction' choices={this.options} />)
  }
}

export class LotEdit extends Component {
  constructor (props, context) {
    super(props, context)
    this.state = {
      auctionOptions: [],
      userOptions: [],
      numberOptions: [],
      isAuctionChanged: false,
      spinner: false,
      showValidationMessage: false,
      validationMessage: '',
      soldAfterAuction: null,
      withdrawnValue: null,
      dataFromJson: null,
      weights: null,
      adjustWeight: null,
      text: '',
      chosenUser: null,
      soldAABefore: null,
      optiweighId: null,
      averageData: null,
      assessmentOptiweigh: null,
      optiweighAllowed: null,
      takeFeeForReoffered: null,
      status: null,
      auctionType: null
    }
    this.loadAuctionOptions = this.loadAuctionOptions.bind(this)
    this.saveUser = this.saveUser.bind(this)
    this.loadAuctionOptions()
  }

  componentDidMount () {
    axios.get(`/check-lot-numbers/${this.props.id}`).then(res => {
      let max = res.data.maxNumber
      let numberOptions = []
      for (let i = 1; i <= max; i++) {
        numberOptions.push({ name: i, id: i })
      }
      this.setState({ numberOptions })
    })
  }

  saveUser (user) {
    this.setState({
      chosenUser: user
    })
  }

  loadAuctionOptions () {
    dataProvider(GET_LIST, 'auctions', {
      pagination: {
        page: this.state.page, perPage: this.state.perPage
      },
      sort: {
        field: this.state.field, order: this.state.order
      },
      state: ['future', 'open']
    })
      .then(({ data }) => {
        let options = []
        data.forEach((auction) => {
          options.push({
            id: auction._id,
            name: `${auction.searchNumber} / ${auction.title} / ${auction.description ? auction.description : '---'}`,
            kind: auction.kind,
            abbLambSale : auction.abbLambSale
          })
        })
        this.setState({
          auctionOptions: options
        })
      })
  }

  handleAuctionChange (e, value, record) {
    if (value !== record.auction) {
      this.setState({ isAuctionChanged: true })
    } else if (this.state.isAuctionChanged) {
      this.setState({ isAuctionChanged: false })
    }
  }

  toggleSpinner = () => {
    this.setState({
      spinner: !this.state.spinner
    })
  }

  showValidationMessage = (validationMessage) => {
    this.setState({
      showValidationMessage: !this.state.showValidationMessage,
      validationMessage
    })
  }

  soldAfterAuctionChange = (e, value) => {
    this.setState({ soldAfterAuction: value })
  }

  withdrawnChange = (e, value) => {
    this.setState({ withdrawnValue: value })
  }

  handleClose = () => {
    this.setState({
      showValidationMessage: false
    })
  }

  
  // While importing old lots , Modifying the assesment forms based on public id
  updateDraftLotDetails = (draftLot) => {
    const conditionFrameIndex = draftLot.details.values.findIndex(item => item.title === 'Condition and Frame')
    const conditionFrameIndexGoat = draftLot.details.values.findIndex(item => item.title === 'Condition')
    if (conditionFrameIndex !== -1) {
      draftLot.details.values[conditionFrameIndex].title = 'Frame'
      const conditionIndex = draftLot.details.values[conditionFrameIndex].values.findIndex(x => x.title === 'Condition')
      if (conditionIndex !== -1) {
        draftLot.details.values[conditionFrameIndex].values.splice(conditionIndex, 1)
      }
    }
    if (conditionFrameIndexGoat !== -1) {
      draftLot.details.values[conditionFrameIndexGoat].title = 'Frame'
      const conditionIndex = draftLot.details.values[conditionFrameIndexGoat].values.findIndex(x => x.title === 'Condition')
      if (conditionIndex !== -1) {
        draftLot.details.values[conditionFrameIndexGoat].values.splice(conditionIndex, 1)
      }
    }

    const joiningDetailsIndex = draftLot.details.values.findIndex(item => item.title === 'Joining Details')
    if (joiningDetailsIndex !== -1) {
      const stationMatedIndex = draftLot.details.values[joiningDetailsIndex].values.findIndex(x => x.title === 'Station Mated?')
      if (stationMatedIndex !== -1) {
        draftLot.details.values[joiningDetailsIndex].values.splice(stationMatedIndex, 1)
      }
      const continuouslyMatedIndex = draftLot.details.values[joiningDetailsIndex].values.findIndex(x => x.title === 'Continuously Joined?')
      if (continuouslyMatedIndex !== -1) {
        draftLot.details.values[joiningDetailsIndex].values[continuouslyMatedIndex].title = 'Continuously Mated'
      }
      const setDateIndex = draftLot.details.values[joiningDetailsIndex].values.findIndex(x => x.title === 'Joined at Set Dates?')
      if (setDateIndex !== -1) {
        draftLot.details.values[joiningDetailsIndex].values[setDateIndex].title = 'Joined?'
      }

      draftLot.details.values[joiningDetailsIndex].values.sort((a, b) => {
        const titles = ["Joined?", "A.I", "Continuously Mated", "Access to Bulls/Stags?", "Lot been Preg Tested?"];
        return titles.indexOf(a.title) - titles.indexOf(b.title);
      });
    }

    const breedingQualityIndex = draftLot.details.values.findIndex(item => item.title === 'Breeding Quality')
    if (breedingQualityIndex !== -1) {
      draftLot.details.values.splice(breedingQualityIndex, 1)
    }

    // Sorting Joining Details fields 
    if (draftLot.kindData.type === "cattle") {
      draftLot.details.values[joiningDetailsIndex].values.sort((a, b) => {
        const titles = ["Joined?", "A.I", "Continuously Mated", "Access to Bulls/Stags?", "Lot been Preg Tested?"];
        return titles.indexOf(a.title) - titles.indexOf(b.title);
      });
    }
    if (draftLot.kindData.type === "sheep") {
      draftLot.details.values[joiningDetailsIndex].values.sort((a, b) => {
        const titles = ["Joined?", "A.I?", "Continuously Mated", "Access to Rams/Bucks/Stags?", "Scanned?", "Additional Joining Details"];
        return titles.indexOf(a.title) - titles.indexOf(b.title);
      });
    }

    if (draftLot.kindData.type === "goat") {
      draftLot.details.values[joiningDetailsIndex].values.sort((a, b) => {
        const titles = ["Joined?", "A.I?", "Continuously Mated", "Scanned?", "Additional Joining Details"];
        return titles.indexOf(a.title) - titles.indexOf(b.title);
      });
    }
  }

  adminImportForm = (event, record) => {
    const kind = record.kindData.type || 'cattle'
    this.setState({
      spinner: true
    })
    let file = event.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.readAsText(file, 'utf-8')
      reader.onload = (e) => {
        try {
          let draftLot = JSON.parse(e.target.result)
          this.updateDraftLotDetails(draftLot)
          if (draftLot['bidding'] && draftLot['bidding'].includes('head')) {
            if (draftLot['startPrice']) {
              draftLot['startPrice'] = draftLot['startPrice'] * 100
            }
            if (draftLot['reserveCents']) {
              draftLot['reserveCents'] = draftLot['reserveCents'] * 100
            }
          }
          let stateObj
          if (kind === 'cattle') {
            stateObj = {
              optiweighAllowed: draftLot.optiweighAllowed,
              adjustWeight: draftLot.adjustWeight,
              dataFromJson: draftLot,
              optiweighId: draftLot.optiweigh ? draftLot.optiweigh._id : null,
              assessmentOptiweigh: draftLot.optiweigh ? draftLot.optiweigh.assessmentData : null,
              averageData: draftLot.optiweigh ? draftLot.optiweigh.averageData : null,
              oldWeightGain: draftLot.oldWeightGain ? draftLot.oldWeightGain : null,
              oldDetails: draftLot.oldDetails ? draftLot.oldDetails : null,
              spinner: false
            }
          } else {
            stateObj = {
              adjustWeight: draftLot.adjustWeight,
              dataFromJson: draftLot,
              spinner: false
            }
          }
          this.setState(stateObj)
        } catch (err) {
          console.error(err)
          this.setState({
            spinner: false
          })
        }
      }
      reader.onerror = (err) => {
        console.error(err)
        this.setState({
          spinner: false
        })
      }
    }
  }

  parseFatScore (value) {
    if (acceptableValues.find(el => el === value)) {
      return value
    } else {
      return null
    }
  }

  importWeights (event, record) {
    const kind = record.kindData.type || 'cattle'
    let file = event.target.files[0]
    if (file) {
      Papa.parse(file, {
        error: () => {
          console.log('Error parsing CSV')
        },
        complete: (results) => {
          results.data = results.data.filter((elem) => elem.length > 1)
          if (results.data.length > 2000) {
            console.log(`Maximum allowed upload of head weighted is 2000  per lot`)
            return
          }
          if (results.errors.length > 0) {
            console.log('Invalid CSV')
          }
          let newAssessment = []
          let totalWeight = 0
          let totalScore = 0
          let weightAmount = 0
          let scoreAmount = 0
          const teethOptions = [
            {
              value: '0',
              label: '0',
              id: 'teeth0'
            },
            {
              value: '1-2',
              label: '1-2',
              id: 'teeth12'
            },
            {
              value: '3-4',
              label: '3-4',
              id: 'teeth34'
            },
            {
              value: '5-6',
              label: '5-6',
              id: 'teeth56'
            },
            {
              value: '7',
              label: '7',
              id: 'teeth7'
            },
            {
              value: '8',
              label: '8',
              id: 'teeth8'
            }
          ]
          const sheepHornOptions = [
            {
              value: 'Polled',
              label: 'Polled',
              id: 'polled'
            },
            {
              value: 'Horned',
              label: 'Horned',
              id: 'horned'
            },
            {
              value: 'PP',
              label: 'PP',
              id: 'pp'
            },
            {
              value: 'PH',
              label: 'PH',
              id: 'ph'
            },
            {
              value: 'HH',
              label: 'HH',
              id: 'hh'
            },
            {
              value: 'Scur',
              label: 'Scur',
              id: 'scur'
            }
          ]
          const cattleHornOptions = [
            {
              value: 'Polled',
              label: 'Polled',
              id: 'polled'
            },
            {
              value: 'Tipped',
              label: 'Tipped',
              id: 'tipped'
            },
            {
              value: 'Horned',
              label: 'Horned',
              id: 'horned'
            },
            {
              value: 'Dehorned',
              label: 'Dehorned',
              id: 'dehorned'
            }
          ]
          const cattleBlemishesOptions = [
            'Pink Eye (PE)',
            'Pink Eye x2 (PE2)',
            'Blue Eye (BE)',
            'Blue Eye x2 (BE2)',
            'One Eye (OE)',
            'Pop Eye (POP)',
            'Wart (W)',
            'Ringworm (RW)',
            'Needle Lump (NL)',
            'Lice (L)',
            'Tick (T)',
            'Split Hoof (SH)',
            'Long Hooves or Paddle Foot (LH or PF)'
          ]
          if (kind === 'cattle') {
            for (let i = 1; i < results.data.length; i++) {
              let itemValues = []
              for (let [index, category] of results.data[0].entries()) {
                let title
                let publicId
                let type
                let required
                let options = null
                if (category.includes('Ass') || category.includes('Weight')) {
                  title = 'Ass/Weight (Live)'
                  publicId = 'weightLive'
                  type = 'weight'
                  required = true
                } else if (category.includes('Fat')) {
                  title = 'Fat Score'
                  publicId = 'fatScore'
                  type = 'rating'
                  required = true
                } else if (category.includes('Teeth')) {
                  title = 'Teeth'
                  publicId = 'animalTeeth'
                  type = 'select'
                  required = false
                  options = teethOptions
                } else if (category.includes('Horn')) {
                  title = 'Horned'
                  publicId = 'animalHorn'
                  type = 'select'
                  required = false
                  options = kind === 'cattle' ? cattleHornOptions : sheepHornOptions
                } else if (category.includes('Blemishes')) {
                  title = 'Blemishes'
                  publicId = 'animalBlemishes'
                  type = 'multiDropdown'
                  required = false
                  options = cattleBlemishesOptions
                } else if (category.includes('Individual')) {
                  title = 'Individual ID no.'
                  publicId = 'animalIndividualId'
                  type = 'text'
                  required = false
                }
                let newItem
                if (options) {
                  newItem = {
                    isMarked: true,
                    isPublic: true,
                    isRequired: required,
                    publicId: publicId,
                    title: title,
                    type: type,
                    value: (publicId.includes('fat') || publicId.includes('weight')) ? Number(results.data[i][index]) : results.data[i][index],
                    options: options
                  }
                } else {
                  if (publicId.includes('weight') && results.data[i][index]) {
                    totalWeight += results.data[i][index]
                    weightAmount++
                  }
                  if (publicId.includes('fat') && results.data[i][index]) {
                    totalScore += results.data[i][index]
                    scoreAmount++
                  }
                  newItem = {
                    isMarked: true,
                    isPublic: true,
                    isRequired: required,
                    publicId: publicId,
                    title: title,
                    type: type,
                    value: (publicId.includes('fat') || publicId.includes('weight')) ? Number(results.data[i][index]) : results.data[i][index]
                  }
                }
                itemValues.push(newItem)
              }
              const publicIds = ['weightLive', 'fatScore', 'animalTeeth', 'animalHorn', 'animalBlemishes', 'animalIndividualId']
              publicIds.forEach(id => {
                let title
                let publicId
                let type
                let required
                let options = null
                if (id.includes('weightLive')) {
                  title = 'Ass/Weight (Live)'
                  publicId = 'weightLive'
                  type = 'weight'
                  required = true
                } else if (id.includes('fatScore')) {
                  title = 'Fat Score'
                  publicId = 'fatScore'
                  type = 'rating'
                  required = true
                } else if (id.includes('animalTeeth')) {
                  title = 'Teeth'
                  publicId = 'animalTeeth'
                  type = 'select'
                  required = false
                  options = teethOptions
                } else if (id.includes('animalHorn')) {
                  title = 'Horned'
                  publicId = 'animalHorn'
                  type = 'select'
                  required = false
                  options = kind === 'cattle' ? cattleHornOptions : sheepHornOptions
                } else if (id.includes('Blemishes')) {
                  title = 'Blemishes'
                  publicId = 'animalBlemishes'
                  type = 'multiDropdown'
                  required = false
                  options = cattleBlemishesOptions
                } else if (id.includes('animalIndividualId')) {
                  title = 'Individual ID no.'
                  publicId = 'animalIndividualId'
                  type = 'text'
                  required = false
                }
                if ((itemValues.findIndex(item => item.publicId === id)) === -1) {
                  if (options) {
                    const newItem = {
                      isMarked: true,
                      isPublic: true,
                      isRequired: required,
                      publicId: publicId,
                      title: title,
                      type: type,
                      value: '',
                      options: options
                    }
                    itemValues.push(newItem)
                  } else {
                    const newItem = {
                      isMarked: true,
                      isPublic: true,
                      isRequired: required,
                      publicId: publicId,
                      title: title,
                      type: type,
                      value: ''
                    }
                    itemValues.push(newItem)
                  }
                }
              })
              newAssessment.push(itemValues)
            }
          } else {
            for (let i = 1; i < results.data.length; i++) {
              let itemValues = []
              for (let [index, category] of results.data[0].entries()) {
                let title
                let publicId
                let type
                let required
                let options = null
                if (category.includes('Ass') || category.includes('Weight')) {
                  title = 'Ass/Weight (Live)'
                  publicId = 'weightLive'
                  type = 'weight'
                  required = true
                } else if (category.includes('Fat')) {
                  title = 'Fat Score'
                  publicId = 'fatScore'
                  type = 'rating'
                  required = true
                } else if (category.includes('Teeth')) {
                  title = 'Teeth'
                  publicId = 'animalTeeth'
                  type = 'select'
                  required = false
                  options = teethOptions
                } else if (category.includes('Horn')) {
                  title = 'Horned'
                  publicId = 'animalHorn'
                  type = 'select'
                  required = false
                  options = kind === 'cattle' ? cattleHornOptions : sheepHornOptions
                } else if (category.includes('Individual')) {
                  title = 'Individual ID no.'
                  publicId = 'animalIndividualId'
                  type = 'text'
                  required = false
                } else {
                  title = null
                }
                if (title) {
                  let newItem
                  if (options) {
                    newItem = {
                      isMarked: true,
                      isPublic: true,
                      isRequired: required,
                      publicId: publicId,
                      title: title,
                      type: type,
                      value: (publicId.includes('fat') || publicId.includes('weight')) ? Number(results.data[i][index]) : results.data[i][index],
                      options: options
                    }
                  } else {
                    if (publicId.includes('weight') && results.data[i][index]) {
                      totalWeight += results.data[i][index]
                      weightAmount++
                      results.data[i][index] = Number(results.data[i][index])
                    }
                    if (publicId.includes('fat') && results.data[i][index]) {
                      totalScore += results.data[i][index]
                      scoreAmount++
                      results.data[i][index] = Number(results.data[i][index])
                    }
                    newItem = {
                      isMarked: true,
                      isPublic: true,
                      isRequired: required,
                      publicId: publicId,
                      title: title,
                      type: type,
                      value: (publicId.includes('fat') || publicId.includes('weight')) ? Number(results.data[i][index]) : results.data[i][index]
                    }
                  }
                  itemValues.push(newItem)
                }
              }
              const publicIds = ['weightLive', 'fatScore', 'animalTeeth', 'animalHorn', 'animalIndividualId']
              publicIds.forEach(id => {
                let title
                let publicId
                let type
                let required
                let options = null
                if (id.includes('weightLive')) {
                  title = 'Ass/Weight (Live)'
                  publicId = 'weightLive'
                  type = 'weight'
                  required = true
                } else if (id.includes('fatScore')) {
                  title = 'Fat Score'
                  publicId = 'fatScore'
                  type = 'rating'
                  required = true
                } else if (id.includes('animalTeeth')) {
                  title = 'Teeth'
                  publicId = 'animalTeeth'
                  type = 'select'
                  required = false
                  options = teethOptions
                } else if (id.includes('animalHorn')) {
                  title = 'Horned'
                  publicId = 'animalHorn'
                  type = 'select'
                  required = false
                  options = kind === 'cattle' ? cattleHornOptions : sheepHornOptions
                } else if (id.includes('animalIndividualId')) {
                  title = 'Individual ID no.'
                  publicId = 'animalIndividualId'
                  type = 'text'
                  required = false
                }
                if ((itemValues.findIndex(item => item.publicId === id)) === -1) {
                  if (options) {
                    const newItem = {
                      isMarked: true,
                      isPublic: true,
                      isRequired: required,
                      publicId: publicId,
                      title: title,
                      type: type,
                      value: '',
                      options: options
                    }
                    itemValues.push(newItem)
                  } else {
                    const newItem = {
                      isMarked: true,
                      isPublic: true,
                      isRequired: required,
                      publicId: publicId,
                      title: title,
                      type: type,
                      value: ''
                    }
                    itemValues.push(newItem)
                  }
                }
              })
              newAssessment.push(itemValues)
            }
          }
          const avgWeight = totalWeight / weightAmount || 0
          const avgScore = totalScore / scoreAmount || 0
          this.setState({
            weights: newAssessment,
            avgWeight: avgWeight,
            avgScore: avgScore
          })
        }
      })
    }
  }

  parseOptiweighAssessment (event) {
    let file = event.target.files[0]
    if (file) {
      Papa.parse(file, {
        error: () => {
          console.log('Error parsing CSV')
          window.alert('Error parsing CSV')
        },
        complete: async (results) => {
          results.data = results.data.filter((elem) => elem.length > 1)
          if (results.errors.length > 0) {
            console.log(results.errors)
            console.log(results.data)
          }
          let counts = []
          let avarageArray = []
          if (results.data[0].some(item => item.includes('Count'))) {
            let min = 0
            let max = 0
            let prev = 0
            for (let i = 1; i < results.data.length; i++) {
              let numbers = results.data[i][0].split(' to ')
              let startWeight = Number(numbers[0])
              let endWeight = Number(numbers[1])
              let count = Number(results.data[i][1])
              if (!min && startWeight && count) {
                min = startWeight
              } else {
                if (startWeight < min) {
                  min = startWeight
                }
              }
              if (endWeight > max && count) {
                max = endWeight
              }
              if (startWeight && endWeight && startWeight >= prev && endWeight > startWeight) {
                counts.push({
                  startWeight, endWeight, count
                })
                avarageArray.push({ average: (startWeight + endWeight) / 2, numberOfHeads: count })
                prev = endWeight
              }
            }

            // Remove sections without head from start
            const firstNotNullIndex = counts.findIndex((item) => item.count > 0)
            if (firstNotNullIndex >= 0) {
              counts = counts.splice(firstNotNullIndex, counts.length)
            }

            //  Remove sections without head from end
            const lastNotNullIndex = counts.lastIndexOf((item) => item.count > 0)
            if (lastNotNullIndex >= 0) {
              counts = counts.splice(0, lastNotNullIndex)
            }

            const averageWeight = findAverageFromArray(avarageArray)
            let resultObj = {
              minWeight: min,
              maxWeight: max,
              averageWeight: averageWeight,
              counts
            }
            if (resultObj.counts.length === 0) {
              window.alert('Not valid amount in CSV')
            } else {
              const url = this.state.optiweighId ? `/optiweigh/${this.state.optiweighId}` : '/optiweigh'
              const resp = this.state.optiweighId ? await axios.put(url, { assessmentData: resultObj }) : await axios.post(url, { assessmentData: resultObj })
              if (resp && resp.data) {
                try {
                  await this.setState({
                    assessmentOptiweigh: resultObj,
                    optiweighId: resp.data.optiweigh._id
                  })
                } catch (e) {
                  console.log(e)
                  window.alert(e)
                }
              }
            }
          } else {
            window.alert('Wrong CSV')
          }
        }
      })
    }
  }

  parseOptiweighAverage (event) {
    let file = event.target.files[0]
    if (file) {
      Papa.parse(file, {
        error: () => {
          console.log('Error parsing CSV')
          window.alert('Error parsing CSV')
        },
        complete: async (results) => {
          results.data = results.data.filter((elem) => elem.length > 1)
          if (results.errors.length > 0) {
            console.log('Invalid CSV')
            window.alert('Invalid CSV')
          }
          let data = []
          if (results.data[0].some(item => item.includes('Date'))) {
            for (let i = 1; i < results.data.length; i++) {
              let date = results.data[i][0]
              let weight = Number(results.data[i][1])
              let averageDailyGain = Number(results.data[i][2])
              if (date && weight) {
                data.push({
                  date, weight, averageDailyGain
                })
              }
            }
            const url = this.state.optiweighId ? `/optiweigh/${this.state.optiweighId}` : '/optiweigh'
            const resp = this.state.optiweighId ? await axios.put(url, { averageData: data }) : await axios.post(url, { averageData: data })
            if (resp && resp.data) {
              try {
                await this.setState({
                  averageData: data,
                  optiweighId: resp.data.optiweigh._id
                })
              } catch (e) {
                console.log(e)
                window.alert(e)
              }
            }
          } else {
            window.alert('Wrong CSV')
          }
        }
      })
    }
  }

  render () {
    let {
      dataFromJson,
      weights,
      adjustWeight,
      chosenUser,
      optiweighAllowed,
      optiweighId,
      averageData,
      assessmentOptiweigh
    } = this.state
    const EmptyToolbar = props => (<Toolbar {...props} className='hidden-toolbar'>
      <SaveButton label='' submitOnEnter={false} />
    </Toolbar>)
    const BiddingOptions = ({ record } = {}) => {
      if (record.kindData.type === 'sheep') {
        return (
          <SelectInput 
          //disabled={record.state === 'closed'} 
          source='bidding' choices={[{ id: 'head', name: 'Head' }]} />)
      } else {
        return (<SelectInput 
         // disabled={record.state === 'closed'} 
          source='bidding' choices={biddingOptions} />)
      }
    }
    const OptiweighButtons = ({ record = {} }) => {
      if (optiweighAllowed && record.optiweigh) {
        if (assessmentOptiweigh === null && record.optiweigh.assessmentData) {
          this.setState({
            assessmentOptiweigh: record.optiweigh.assessmentData,
            optiweighId: record.optiweigh._id
          })
        }
        if (averageData === null && record.optiweigh.averageData && !!record.optiweigh.averageData.length) {
          this.setState({
            averageData: record.optiweigh.averageData,
            optiweighId: record.optiweigh._id
          })
        }
      }
      const hiddenAverageInput = React.useRef(null)
      const handleAverageClick = event => {
        hiddenAverageInput.current.click()
      }
      const hiddenAssessmentInput = React.useRef(null)
      const handleAssessmentClick = event => {
        hiddenAssessmentInput.current.click()
      }
      if (record.kindData.type === 'cattle') {
        return (<div className='button-group-container bottom'>
          <Button disabled={!optiweighAllowed} onClick={handleAverageClick} color='primary'
            variant={averageData ? 'contained' : 'outlined'}>Average Data</Button>
          <Button disabled={!optiweighAllowed} onClick={handleAssessmentClick} color='primary'
            variant={assessmentOptiweigh ? 'contained' : 'outlined'}>Assessment Data</Button>
          <input
            disabled={!optiweighAllowed}
            style={{ display: 'none' }}
            type='file'
            accept='.csv'
            ref={hiddenAverageInput}
            onChange={async (event) => this.parseOptiweighAverage(event)}
            title={'Optiweigh'}
          />
          <input
            disabled={!optiweighAllowed}
            style={{ display: 'none' }}
            type='file'
            accept='.csv'
            ref={hiddenAssessmentInput}
            onChange={async (event) => {
              this.parseOptiweighAssessment(event)
            }}
            title={'Optiweigh'}
          />
        </div>)
      } else return null
    }
    const DocumentField = ({ record } = {}) => {
      return <DocumentsInputField record={record} />
    }
    const NumberFiled = ({ record = {} }) => {
      return record.approved && <SelectInput 
     // disabled={record.state === 'closed'} 
      source='number' label='Number'
        choices={this.state.numberOptions} />
    }
    const TitleField = ({ record = {} }) => {
      if (record.publicDetails && record.publicDetails.title) {
        return <TextInput source='lotTitle' label='Lot Title' defaultValue={`${record.publicDetails.title} ${record.publicDetails.summary.age}`} disabled />
      }
      return <TextInput source='lotTitle' label='Lot Title' disabled />
    }
    const AdditionalTitleField = ({ record = {} }) => {
      if (record.additionalTitle) {
        return <TextInput source='additionalTitle' label='Additional Title' defaultValue={record.additionalTitle} />
      }
      return <TextInput source='additionalTitle' label='Additional Title' />
    }
    const AuctionSelect = ({ record } = {}) => {
      let options;
      if(record.auctionData.abbLambSale){
        options = this.state.auctionOptions.filter((option) => {
          return option.kind === record.kind && option.abbLambSale 
        })
      }else {
         options = this.state.auctionOptions.filter((option) => {
          return option.kind === record.kind && !option.abbLambSale 
        })
      }

      if (options.length) {
        return <Select onChange={(e, value) => { this.handleAuctionChange(e, value, record) }}
         record={record}
         //options={this.state.auctionOptions} 
         options={options} 
          />
      } else {
        return null
      }
    }
    const ApprovedField = ({ record = {} }) => {
      if (this.state.dataFromJson) {
        return <SelectInput 
        //disabled={record.state === 'closed'}
         source='approved' label='Approved'
          choices={approvedOptions}
          defaultValue={approvedOptions.find(option => option.id === this.state.dataFromJson.approved)} />
      }
      return <SelectInput 
      //disabled={record.state === 'closed'}
       source='approved' label='Approved'
        choices={approvedOptions} />
    }
    const DraftField = ({ record = {} }) => {
      if (this.state.dataFromJson) {
        return <SelectInput 
       // disabled={record.state === 'closed'} 
        source='draft' label='In draft' choices={draftOptions}
          defaultValue={draftOptions.find(option => option.id === this.state.dataFromJson.draft)} />
      }
      return <SelectInput
       //disabled={record.state === 'closed'} 
       source='draft' label='In draft' choices={draftOptions} />
    }
    const RemovedField = ({ record = {} }) => {
      return <SelectInput  
      //disabled={record.state === 'closed'}
      source='removed' label='Removed'
        choices={removedOptions} />
    }
    const StartPriceField = ({ record = {} }) => {
      if (this.state.dataFromJson) {
        return <NumberInput defaultValue={this.state.dataFromJson.startPrice} source='startPrice'
          label='Start Price(cents)' step={1} />
      }
      return <NumberInput defaultValue={record.startPrice} source='startPrice' label='Start Price(cents)' step={1} />
    }
    const BidIncrementCents = ({ record = {} }) => {
      if (this.state.dataFromJson) {
        return <NumberInput defaultValue={this.state.dataFromJson.bidIncrementCents} source='bidIncrementCents'
          label='Bid increment(cents)' step={1} />
      }
      return <NumberInput defaultValue={record.bidIncrementCents} source='bidIncrementCents'
        label='Bid increment(cents)' step={1} />
    }
    const ReserveCents = ({ record = {} }) => {
      if (this.state.dataFromJson) {
        return <NumberInput defaultValue={this.state.dataFromJson.reserveCents} source='reserveCents'
          label='Reserve price(cents)' step={1} />
      }
      return <NumberInput source='reserveCents' label='Reserve price(cents)' step={1} />
    }
    const ConditionsOfSaleField = ({ record = {} }) => {
      if (this.state.dataFromJson) {
        return <LongTextInput defaultValue={this.state.dataFromJson.saleConditions} disabled={record.state === 'closed'}
          source='saleConditions' resettable />
      }
      return <LongTextInput disabled={record.state === 'closed'} source='saleConditions' resettable />
    }
    const DescriptionField = ({ record = {} }) => {
      return <LongTextInput defaultValue={this.state.dataFromJson ? this.state.dataFromJson.description : ''}
        disabled={record.state === 'closed'} source='description' title='Description' resettable />
    }
    const AcceptenceOnTermsField = ({ record = {} }) => {
      if (this.state.dataFromJson) {
        return <SelectInput disabled={record.state === 'closed'} source='acceptenceOnTerms' label='Acceptance on Terms'
          choices={acceptenceOnTerms}
          defaultValue={acceptenceOnTerms.find(option => option.id === this.state.dataFromJson.acceptenceOnTerms)} />
      }
      return <SelectInput disabled={record.state === 'closed'} source='acceptenceOnTerms' label='Acceptance on Terms'
        choices={acceptenceOnTerms} />
    }
    const WithdrawnFiled = ({ record = {} }) => {
      if (this.state.dataFromJson) {
        return <SelectInput 
       // disabled={record.state === 'closed'} 
        source='withdrawn' label='Is Withdrawn'
          onChange={this.withdrawnChange} choices={withdrawnOptions}
          defaultValue={withdrawnOptions.find(option => option.id === this.state.dataFromJson.withdrawn)} />
      }
      return <SelectInput 
     // disabled={record.state === 'closed'} 
      source='withdrawn' label='Is Withdrawn'
        onChange={this.withdrawnChange} choices={withdrawnOptions} />
    }
    const MaxVendorBidsField = ({ record = {} }) => {
      if (this.state.dataFromJson) {
        return <NumberInput disabled={record.state === 'closed'} source='maxVendorBids' label='Max Vendor Bids' step={1}
          defaultValue={this.state.dataFromJson.maxVendorBids} />
      }
      return <NumberInput disabled={record.state === 'closed'} source='maxVendorBids' label='Max Vendor Bids' step={1} />
    }
    const StatusChange = ({ record = {} }) => {
      if (this.state.soldAfterAuction === null) {
        this.setState({ soldAfterAuction: record.soldAfterAuction })
      }
      if (this.state.soldAABefore === null) {
        this.setState({ soldAABefore: record.soldAfterAuction })
      }
      if (this.state.status === null) {
        this.setState({ status: record.status || 'noBid' })
      }
      if (record.state === 'closed') {
        return (
          <SelectInput source='status' choices={choices} onChange={(event, value) => {
            this.setState({ status: value, soldAfterAuction: value === 'soldAfterAuction' })
          }
          } />)
      } else {
        return null
      }
    }
    const ChooseUserSoldAfterAuction = ({ record = {} }) => {
      return (
        <div>
          {(this.state.soldAfterAuction === true || (record.state === 'closed' && this.state.status !== 'noBid')) &&
          <UserAutoCompleteField chosenUser={this.state.chosenUser} record={record} saveUser={this.saveUser} />}
        </div>
      )
    }
    const UploadButtons = ({ record = {} }) => {
      const hiddenCSVInput = React.useRef(null)
      const handleCSVClick = event => {
        hiddenCSVInput.current.click()
      }
      const hiddenFileInput = React.useRef(null)
      const handleClick = event => {
        hiddenFileInput.current.click()
      }
      return (
        <div className='button-group-container'>
          <Button onClick={handleClick} color='primary' variant='outlined'>Import Lot</Button>
          <Button onClick={handleCSVClick} color='primary' variant='outlined'>Import Weights</Button>
          <input
            style={{ display: 'none' }}
            type='file'
            accept='.json'
            ref={hiddenFileInput}
            onChange={(event) => this.adminImportForm(event, record)}
          />
          <input
            style={{ display: 'none' }}
            type='file'
            accept='.csv'
            ref={hiddenCSVInput}
            onChange={async (event) => this.importWeights(event, record)}
            title={'Import Weights'}
          />
        </div>)
    }
    const CurrentBidCentsField = ({ record = {} }) => {
      return (<div>
        {this.state.soldAfterAuction && <NumberInput source='closedPrice' label='New Price' step={1} />}
      </div>)
    }
    const OptiweighAllowed = ({ record = {} }) => {
      if (optiweighAllowed === null) {
        this.setState({ optiweighAllowed: record.optiweighAllowed || false })
      }
      if (record.kindData.type === 'cattle') {
        return (
          <div className='toggle-container'>
            <Toggle
              defaultChecked={optiweighAllowed}
              onChange={() => {
                this.setState({
                  optiweighAllowed: !optiweighAllowed
                })
              }
              } />
            <span>Allow optiweigh</span>
          </div>)
      } else return null
    }
    const NewPriceInput = ({ record = {} }) => {
      if (record.state === 'closed' && this.state.status !== 'noBid' && this.state.status !== 'soldAfterAuction') {
        return (
          <NumberInput source='closedPrice' label='New price' />)
      } else {
        return null
      }
    }

    const HeadOnNVDField = ({ record = {} }) => {
      if (this.state.dataFromJson) {
        return <NumberInput defaultValue={this.state.dataFromJson.headOnNVD} source='headOnNVD' label='Number of head on NVD' step={1} />
      }
      return <NumberInput source='headOnNVD' label='Number of head on NVD' step={1} />
    }

    const HeadAccordingToBuyerField = ({ record = {} }) => {
      if (this.state.dataFromJson) {
        return <NumberInput defaultValue={this.state.dataFromJson.headAccordingToBuyer} source='headAccordingToBuyer' label='Number of head according to Buyer' step={1} />
      }
      return <NumberInput source='headAccordingToBuyer' label='Number of head according to Buyer' step={1} />
    }

    const AdminNotesField = ({ record = {} }) => {
      if (this.state.dataFromJson) {
        return <TextInput defaultValue={this.state.dataFromJson.adminNotes} source='adminNotes' label='Admin Notes' />
      }
      return <TextInput source='adminNotes' label='Admin Notes' />
    }

    const AdminAttachmentsField = ({ record } = {}) => {
      return <DocumentsInputField record={record} sourceField='adminAttachments' />
    }

    return (
      <Edit {...this.props} title={<LotTitle />}>
        <SimpleForm className='edit-simple-form' toolbar={<EmptyToolbar />}>
          {this.state.spinner &&
          <div className='spinner-wrapper'>
            <div className='spinner'>
              <Circle size={100} />
            </div>
          </div>}
          {
            this.state.showValidationMessage &&
            <Dialog
              open={this.state.showValidationMessage}
              TransitionComponent={Transition}
              keepMounted
              onClose={this.handleClose}
              aria-labelledby='alert-dialog-slide-title'
              aria-describedby='alert-dialog-slide-description'
              className='spinner-wrapper'
            >
              <DialogTitle id='alert-dialog-slide-title'>
                {'Lot Form Validation'}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id='alert-dialog-slide-description'>
                  {this.state.validationMessage}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleClose} color='primary'>
                Ok
                </Button>
              </DialogActions>
            </Dialog>
          }
          <UploadButtons />
          <OptiweighButtons />
          <TitleField/>
          <AdditionalTitleField/>
          {this.state.auctionOptions.length > 0 && <AuctionSelect />}
          <OptiweighAllowed />
          <BillForReoffered isChoosenAuction={this.state.isAuctionChanged} />
          <StatusChange />
          <ChooseUserSoldAfterAuction />
          <NewPriceInput />
          <CurrentBidCentsField />
          <ApprovedField />
          {this.state.isAuctionChanged ? null : <NumberFiled />}
          <DraftField />
          <RemovedField />
          <HeadOnNVDField />
          <HeadAccordingToBuyerField />
          <AdminNotesField />
          <AdminAttachmentsField />
          <StartPriceField />
          <BidIncrementCents />
          <ReserveCents />
          <BiddingOptions />
          <NumberInput source='lotDiscount' label='Lot Discount(%)' step={1} defaultValue={0} />
          <ConditionsOfSaleField />
          <DescriptionField />
          <AcceptenceOnTermsField />
          <WithdrawnFiled />
          <MaxVendorBidsField />
          <MediaInputField toggleSpinner={this.toggleSpinner} onSubmit={() => { /* mock inheritance */ }} />
          <DocumentField />
          <UsefulLinks />
          {(!dataFromJson && !weights) &&
          <LotAssessmentFormEdit
            kindList={this.state.kindsList}
            showValidationError={(message) => { this.showValidationMessage(message) }}
            chosenUser={chosenUser?.value || null} optiweigh={optiweighId}
            optiweighAllowed={optiweighAllowed} assessmentOptiweigh={assessmentOptiweigh}
            averageOptiweigh={averageData} toggleSpinner={this.toggleSpinner}
            onSubmit={() => { /* mock inheritance */ }} />}
          {(!dataFromJson && weights) &&
          <LotAssessmentFormEdit
            kindList={this.state.kindsList}
            showValidationError={(message) => { this.showValidationMessage(message) }}
            chosenUser={chosenUser?.value || null} optiweigh={optiweighId}
            optiweighAllowed={optiweighAllowed} weights={weights}
            assessmentOptiweigh={assessmentOptiweigh} averageOptiweigh={averageData}
            toggleSpinner={this.toggleSpinner} onSubmit={() => { /* mock inheritance */ }} />}
          {(dataFromJson && weights) &&
          <LotAssessmentFormEdit
            kindList={this.state.kindsList}
            showValidationError={(message) => { this.showValidationMessage(message) }}
            chosenUser={chosenUser?.value || null} optiweigh={optiweighId}
            optiweighAllowed={optiweighAllowed} weights={weights} dataFromJson={dataFromJson}
            adjustWeight={adjustWeight} assessmentOptiweigh={assessmentOptiweigh}
            averageOptiweigh={averageData} toggleSpinner={this.toggleSpinner}
            onSubmit={() => { /* mock inheritance */ }} />}
          {(dataFromJson && !weights) &&
          <LotAssessmentFormEdit
            kindList={this.state.kindsList}
            showValidationError={(message) => { this.showValidationMessage(message) }}
            chosenUser={chosenUser?.value || null} optiweigh={optiweighId}
            optiweighAllowed={optiweighAllowed} dataFromJson={dataFromJson}
            adjustWeight={adjustWeight} assessmentOptiweigh={assessmentOptiweigh}
            averageOptiweigh={averageData} toggleSpinner={this.toggleSpinner}
            onSubmit={() => { /* mock inheritance */ }} />}
        </SimpleForm>
      </Edit>)
  }
}

import React, { useState,useEffect } from 'react'
import Select from 'react-select'
import {
  SimpleForm,
  TextInput,
  NumberInput,
  FileInput,
  ImageField,
  SelectInput,
  SelectArrayInput,
  Create,
  LongTextInput,
  ReferenceInput,
  FormDataConsumer,
  BooleanInput
} from 'react-admin'
import * as Datetime from 'react-datetime'
import { Field } from 'redux-form'
import axios from 'axios'
// import debounce from 'lodash/debounce'
import UserFetch from './userFetch'
import moment from 'moment'

import { centsToDollars } from '../../../utils'
import auctionCreateService from '../auctionService'
import { TimeFormatInput } from '../edit/TimeFormatInput'
import './styles.css'
import { UsefulLinks } from '../../../components/auction/UsefulLinks/UsefulLinks'
import { AuctionTags } from '../../../components/auction/AuctionTags/AuctionTags'
import { AdditionDateInformation } from '../../../components/auction/AdditionDateInformation/AdditionDateInformation'
import { approvedOptions } from '../../lots/options'
import { AuctionTabs } from '../../../components/auction/AuctionTabs/AuctionTabs'

const locations = auctionCreateService.locations
const reminderLocations = auctionCreateService.reminderLocations
const auctionTypes = auctionCreateService.auctionTypes
const auctionPublic = auctionCreateService.auctionPublic
const statuses = auctionCreateService.statuses
const userOperations = auctionCreateService.userOperations
const selectAllUserOperations = auctionCreateService.selectAllUserOperations
const selectAllStatus = auctionCreateService.selectAllStatus
const selectAllStates = auctionCreateService.selectAllStates

const validateAuctionCreation = (values) => {
  const errors = {}
  if (!values.title) {
    errors.title = ['The title is required']
  }
  if (!values.location || !values.location.state || values.location.state.length === 0) {
    errors.location = {}
    errors.location.state = ['The Location State field is required']
  }
  if (!values.lotOpeningSeconds) {
    errors.lotOpeningSeconds = ['The Lot Opening Seconds is required']
  }
  if (!values.auctionMaxSeconds) {
    errors.auctionMaxSeconds = ['The Auction Max Seconds is required']
  }
  if (!values.kindId) {
    errors.kindId = ['Category field is required']
  }
  if (!values.liveAt) {
    errors.liveAt = ['Live At field is required']
  }
  if (!values.auctionType) {
    errors.auctionType = ['Auction Type field is required']
  }
  if (!values.logo) {
    errors.logo = ['Logo for Web field is required']
  }
  if (!values.logoIos) {
    errors.logoIos = ['Logo for iOs field is required']
  }
  if (values.additionalDateInfo) {
    if ((values.additionalDateInfo.title && !values.additionalDateInfo.date) || (!values.additionalDateInfo.title && values.additionalDateInfo.date)) {
      errors.additionalDateInfo = ['Title and Date are required for Additional Date Info.']
    }
  }
  return errors
}

const DateTime = (params) => {
  let { input, label, meta: { touched, error } } = params
  return (
    <div>
      <Datetime
        timeFormat
        dateFormat='DD/MM/YYYY'
        label={label}
        error={Boolean(touched && error)}
        helperText={touched && error}
        inputProps={{ placeholder: 'Live At' }}
        className={touched && error ? 'error-style' : null}
        {...input}
      />
      {input.value._d && <p style={{ maxWidth: '250px' }} >{`${moment(input.value._d).tz('Australia/Sydney').format('ddd, DD MMMM, h:mm A')} (SYD): `} <span style={{ fontSize: '14px' }}>{`${moment(input.value._d).tz('Australia/Perth').format('h:mm A')} WA -- ${moment(input.value._d).tz('Australia/Darwin').format('h:mm A')} NT -- ${moment(input.value._d).tz('Australia/Queensland').format('h:mm A')} QLD -- ${moment(input.value._d).tz('Australia/Adelaide').format('h:mm A')} SA -- ${moment(input.value._d).tz('Australia/Sydney').format('h:mm A')} NSW -- ${moment(input.value._d).tz('Australia/Victoria').format('h:mm A')} VIC -- ${moment(input.value._d).tz('Australia/Tasmania').format('h:mm A')} TAS`}</span></p>}
    </div>
  )
}

export const AuctionType = () => {
  return (
    <div className='auction-type'>
      <FormDataConsumer>
        {({ formData }) => {
          return (
            <>
              <SelectInput source='auctionType' choices={auctionTypes} />
              {formData && formData.auctionType === 'sequential' &&
                <NumberInput source='numberOfActiveLots' label='Number Of Active Lots' step={1} />}
              {formData && formData.auctionType === 'sequential' &&
                <NumberInput source='lotAddedDelay' label='Lot Added Delay' step={1} />}
            </>
          )
        }
        }
      </FormDataConsumer>
    </div>
  )
}


export const StatesAndRegions = (props) => {
  let { regions } = props
  let [selectedAllLocationData, setSelectedAllLocationData] = useState('');
  let [assignedData, setAssignedData] = useState([]);

  return (
    <FormDataConsumer>
      {({ formData }) => {

        if (assignedData.length > 0 || selectedAllLocationData === 'All') {
          if (formData && formData.smsReminders && formData.smsReminders.states && formData.smsReminders.states.length > 0) {
            formData.smsReminders.states = assignedData;
          }
        }
        if (formData && formData.smsReminders && formData.smsReminders.states && formData.smsReminders.states.length > 0 && !formData.smsReminders.states.includes('Select Region')) {

          formData.smsReminders.regions = []
        }

        const updateValue = (value) => {
          if (!Object.values(value).includes('All') || !Object.values(value).includes('Select Region') || !Object.values(value).includes('disable')) {
            setSelectedAllLocationData('')
          }

          if (Object.values(value).includes('All')) {
            setAssignedData(selectAllStates)
          } else if (Object.values(value).includes('Select Region')) {

            setAssignedData(['Select Region'])
          } else if (Object.values(value).includes('disable')) {
            setAssignedData(['disable'])
          } else {
            if (assignedData.includes('All') && !Object.values(value).includes('All')) {
              setAssignedData([])
              setSelectedAllLocationData('All')
            } else {
              setAssignedData([])
            }

          }
        }

        return (
          <>
            <SelectArrayInput source='smsReminders.states' label='SMS Reminder States' choices={reminderLocations} onChange={updateValue} />
            {
              formData && formData.smsReminders && formData.smsReminders.states && formData.smsReminders.states.length > 0 && formData.smsReminders.states.includes("Select Region") &&
              <SelectArrayInput source='smsReminders.regions' label='Regions' choices={regions} />
            }

          </>
        )
      }
      }
    </FormDataConsumer>
  )
}
export const AuctionCreate = (props) => {
  let [kind, setKind] = useState(null)
  let [specialPrice, setSpecialPrice] = useState(null)
  let [price, setPrice] = useState(0)
  let [chosenKind, setChosenKind] = useState('')
  let [isPrivateSale, setIsPrivateSale] = useState(false)
  let [isStudSale, setIsStudSale] = useState(false)
  let [allowAutoBid, setAllowAutoBid] = useState(false)
  let [allowUpcomingBid, setAllowUpcomingBid] = useState(false)
  let [isAbbLambSale, setIsAbbLambSale] = useState(false)
  let [isRequiresTabs, setIsRequiresTabs] = useState(false)
  let [regions,setRegions] = useState(null);
  let [addNewLotToSpecificAgents, setAddNewLotToSpecificAgents] = useState(false)
  const [chosenUsers, setChosenUsers] = useState(null)
  const { users } = UserFetch()

  const onChangePrivateSale = () => {
    let privateSale = !isPrivateSale
    setIsPrivateSale(privateSale)
    if (!privateSale && !isStudSale) {
      setAddNewLotToSpecificAgents(false)
    }
  }
  const onChangeStud = () => {
    let studSale = !isStudSale
    setIsStudSale(studSale)
    if (!isPrivateSale && !studSale) {
      setAddNewLotToSpecificAgents(false)
    }
  }
  const onChangeAutoBid = () => {
    setAllowAutoBid(!allowAutoBid)
  }
  const onChangeUpcomingBid = () => {
    setAllowUpcomingBid(!allowUpcomingBid)
  }
  const onChangeAbbLambSale = () => {
    setIsAbbLambSale(!isAbbLambSale)
  }
  const onChangeRequiresTabs = () => {
    setIsRequiresTabs(!isRequiresTabs)
  }
  const onChangeAddNewLotToSpecificAgents = () => {
    setAddNewLotToSpecificAgents(!addNewLotToSpecificAgents)
  }
  const handleChange = (selected) => {
    setChosenUsers(selected)
  }
  const getPostcodeData = async () => {
    let url = '/postcodes'
    const res = await axios.get(url);
    let data = [];
    if(res && res.data) {
      res.data.postcodesData.forEach(element => {
        data.push(`${element.region} (${element.state})`)
    });
    }
    data = Array.from(new Set(data));
    data =  data.map ((d)=>{
     return {id : d, name : d}
    })
    setRegions(data);
  }
  useEffect(() => {
   getPostcodeData();
  }, [])

  const UserStatuses = () => {
    return (
      <FormDataConsumer>
        {({ formData }) => {
          if (formData && formData.smsReminders && formData.smsReminders.userStatus && formData.smsReminders.userStatus.length > 0 && formData.smsReminders.userStatus.includes('All')) {
            formData.smsReminders.userStatus = selectAllStatus
          }
          return (
            <SelectArrayInput source='smsReminders.userStatus' label='SMS Reminder Status ' choices={statuses} />
          )
        }}

      </FormDataConsumer>
    )
  }
  const UserProfessions = () => {
    return (
      <FormDataConsumer>
        {({ formData }) => {
          if (formData && formData.smsReminders && formData.smsReminders.userTypes && formData.smsReminders.userTypes.length > 0 && formData.smsReminders.userTypes.includes('All')) {
            formData.smsReminders.userTypes = selectAllUserOperations
          }
          return (
            <SelectArrayInput source='smsReminders.userTypes' label='SMS Reminder Professions ' choices={userOperations} />
          )
        }}

      </FormDataConsumer>
    )
  }

  const ListingFeeForBullsAndRams = () => {
    return (
      <FormDataConsumer>
        {({ formData }) => {
          if (formData && formData.kindId !== chosenKind) {
            axios.get(`/kinds/${formData.kindId}`).then(res => {
              if (res.data && res.data.kind) {
                setKind(res.data.kind)
                setSpecialPrice(res.data.kind.specialPriceCents || 0)
              }
            })
          }

          if (kind && kind.type === 'cattle') {
            return (
              <NumberInput source='specialPriceFee' label='Listing fee(dollars) Bulls' step={1}
                defaultValue={centsToDollars(specialPrice)} />
            )
          } else if (kind && kind.type === 'sheep') {
            return (
              <NumberInput source='specialPriceFee' label='Listing fee(dollars) Rams' step={1}
                defaultValue={centsToDollars(specialPrice)} />
            )
          } else if (kind && kind.type === 'goat') {
            return (
              <NumberInput source='specialPriceFee' label='Listing fee(dollars) Buck' step={1}
                defaultValue={centsToDollars(specialPrice)} />
            )
          } else {
            return null
          }
        }}
      </FormDataConsumer>
    )
  }

  return (
    <Create {...props}>
      <SimpleForm validate={validateAuctionCreation} redirect='show'>
        <TextInput source='title' />
        <Field name='liveAt' source='liveAt' label='' component={DateTime} />
        <AuctionType />
        <ReferenceInput label='Category' source='kindId' reference='kinds'>
          <SelectInput optionText='title' />
        </ReferenceInput>
        <SelectInput defaultValue={false} source='approved' label='Approved' choices={approvedOptions} />
        <SelectArrayInput source='location.state' label='Location State' choices={locations} />
        <BooleanInput label='Show lot number' source='showLotNumbers' />
        <NumberInput source='viewDetailsDays' label='View details days' defaultValue={10} />
        <StatesAndRegions  regions = {regions}/>
        <UserStatuses />
        <UserProfessions />
        <SelectInput source='public' choices={auctionPublic} />
        <TimeFormatInput defaultValue source={'auctionOpenTimer'} label='Auction Open Timer' />
        <TimeFormatInput defaultValue source={'addLotOpenTimer'} label='Add lot Open Timer' />
        <TimeFormatInput defaultValue source={'addLotCloseTimer'} label='Add Lot Close Timer' />
        <TimeFormatInput defaultValue source={'lotOpeningSeconds'} label='Lot Opening Timer' />
        <TimeFormatInput defaultValue source={'bidAddedSeconds'} label='Bid Added Timer' />
        <TimeFormatInput defaultValue source={'lotMaxSeconds'} label='Lot Max Timer' />
        <FormDataConsumer>
          {({ formData, record }) => {
            return (
              <>
                {formData && formData.auctionType !== 'sequential' && formData && formData.auctionType !== 'synchronous' &&
                  <TimeFormatInput record={record} defaultValue source={'standbyTimer'} label='Standby Timer' />
                }
                {/* {formData && formData.auctionType !== 'sequential' && formData.auctionType !== 'synchronous' && formData.auctionType !== 'simultaneous' && formData.auctionType !== 'sequentialOffline' &&
                  <TimeFormatInput record={record} defaultValue source={'auctionMaxSeconds'} label='Auction Max Timer' />
                } */}
              </>
            )
          }
          }
        </FormDataConsumer>
        <BooleanInput source='privateSale' label='Is this a private sale?' onChange={onChangePrivateSale} />
        <BooleanInput label='Is this a Stud Sale' source='studSale' onChange={onChangeStud} />
        <BooleanInput source='allowAutoBid' label='Allow Auto-bids?' onChange={onChangeAutoBid} />
        <BooleanInput source='allowUpcomingBid' label='Allow bids on upcoming Lot?' onChange={onChangeUpcomingBid} />
        <BooleanInput source='abbLambSale' label='Is this an AbbLamb Sale?' onChange={onChangeAbbLambSale} />
        <BooleanInput source='requiresTabs' label='Auction Requires Tabs?' onChange={onChangeRequiresTabs} />
        {isRequiresTabs && <AuctionTabs/> }
        {(isPrivateSale || isStudSale) && <BooleanInput source='addNewLotToSpecificAgents' label='show add new lot to specific users' defaultValue={addNewLotToSpecificAgents}
          onChange={onChangeAddNewLotToSpecificAgents} />}
        {chosenUsers && <SelectArrayInput className='specific-agents' defaultValue={chosenUsers ? chosenUsers.map(e => e.value) : []} source='specificAgents' label='User/Users' />}
        {addNewLotToSpecificAgents &&
          <Select options={users} placeholder='User/Users' isMulti onChange={handleChange} />
        }
        <FormDataConsumer>
          {({ formData }) => {
            let kindPrice = 0
            if (formData.kindId && formData.kindId !== chosenKind) {
              axios.get(`/kinds/${formData.kindId}`).then(res => {
                if (res.data && res.data.kind) {
                  kindPrice = res.data.kind.listingPriceCents
                }
                setPrice(kindPrice)
                setChosenKind(formData.kindId)
              })
              return (
                <NumberInput className='listingFee-input' source='listingFee' label='Listing fee(dollars)' step={1}
                  defaultValue={centsToDollars(price)} />
              )
            } else {
              return (
                <NumberInput className='listingFee-input' source='listingFee' label='Listing fee(dollars)' step={1}
                  defaultValue={centsToDollars(price)} />
              )
            }
          }}
        </FormDataConsumer>
        <ListingFeeForBullsAndRams />
        {isPrivateSale && <NumberInput source='auctionSetUpFee' label='Auction set up fee(dollars)' step={1} />}
        <AuctionTags />
        <UsefulLinks />
        {isPrivateSale && <NumberInput source='auctionDiscount' label='Auction Discount(%)' step={1} defaultValue={0} />}
        {isPrivateSale && isStudSale && <Field name='additionalDateInfo' source='additionalDateInfo' label='' component={AdditionDateInformation} />}
        <FileInput source='image' label='Image' accept='.jpg,.jpeg,.png' placeholder={<p>Drop your file here</p>}>
          <ImageField source='url' />
        </FileInput>
        <FileInput source='logo' label='Logo for Web' accept='.jpg,.jpeg,.png' placeholder={<p>Drop your file here</p>}>
          <ImageField source='url' />
        </FileInput>
        <FileInput source='logoIos' label='Logo for iOs' accept='.jpg,.jpeg,.png'
          placeholder={<p>Drop your file here</p>}>
          <ImageField source='url' />
        </FileInput>
        <BooleanInput source='showCalculator' label='Show Stock Co. calculator in catalogue?' />
        <LongTextInput label='Auction Overview Description' source='descriptionShort' />
        <LongTextInput label='In Catalogue Description' source='description' />
        <LongTextInput label='Admin Notes' source='adminNotes' />
      </SimpleForm>
    </Create>
  )
}

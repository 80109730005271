import React, { Component, Fragment, useEffect, useState } from 'react'
import axios from 'axios'
import update from 'immutability-helper'
import { TextField, Show, SimpleShowLayout } from 'react-admin'
import { AuctionTitle as AuctionTitleHeader } from '../model'
import {
  resumeAuction,
 // addMinute,
 // addFiveMinutes,
  saveAndExport,
  stopNotification,
  publishNotification,
  cancelAuction,
  runNextLotInSequentialAuction,
  closeAuction
} from '../configurations/model'
import socketService from '../../../utils/socketConnection'
import ActionButton from '../../../components/ActionButton'
import auctionCreateService from '../auctionService'
import { pick } from 'lodash'
import { auctionDefaultProps } from '../../../utils/propsFields'
import SocketService from '../../../utils/auction/show/socketService'
import Bids from '../../../components/auction/show/Bids/BidsContainer'
import { UserBuyersOnline } from '../../../components/auction/show/UserBuyersOnline'
import { UserBuyersDisconnected } from '../../../components/auction/show/UserBuyersDisconnected'
import { VendorsTable } from '../../../components/auction/show/VendorsTable'
import { LiveLotsTable } from '../../../components/auction/show/LiveLotsTable'
import { AuctionInfo } from './Fields/AuctionInfoField'
import {
  CustomPauseButton,
  CustomFreezeButton,
  CustomAddMinuteToActiveLots,
  CustomEditMinuteToAuctionLotMaxTime
} from './Buttons/index'
import Button from '@material-ui/core/Button'
import { PreBid } from '../../../components/auction/show/PrebidTable'
import { LotsTable } from '../../../components/auction/show/LotsTable'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import formatTitleForType from '../../../helpers/formatTitleForType'
import { ConfirmationDialog } from '../../../components/auction/show/ConfirmationDialog'

const delay = 1000

const locations = [
  { id: 'NSW', name: 'NSW' },
  { id: 'NT', name: 'NT' },
  { id: 'QLD', name: 'QLD' },
  { id: 'SA', name: 'SA' },
  { id: 'TAS', name: 'TAS' },
  { id: 'VIC', name: 'VIC' },
  { id: 'WA', name: 'WA' },
  {id: 'Select Region',name : 'Select Region'},
  {id : 'All',  name : 'All' },
]

const modalTypes = {
  sms: 'sms',
  cancel: 'cancel',
  closeAuction: 'close-auction'
}
const statuses = auctionCreateService.statuses
const userOperations = auctionCreateService.userOperations
const selectAllUserOperations = auctionCreateService.selectAllUserOperations
const selectAllStatus = auctionCreateService.selectAllStatus
const selectAllStates = auctionCreateService.selectAllStates
export class AuctionShowComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      record: null,
      notification: '',
      showEditModal: false,
      editValue: { biddingType: '', value: 0 },
      search: '',
      timerEnabled: true,
      editAdminBidShow: false,
      showActiveLot: false,
      smsSelectvalue: [],
      regionsData: null,
      smsReminders : {
        userTypes : [],
        userStatus : [],
        states : [],
        regions : []
      },
      confirmationModalOpen: false,
      confirmationCancelAuction: true,
      modalType: modalTypes.sms,
      timesToConfirmCancel: 3,
      propsForModal: null,
      resultsSent: false
    }
    this.requestSended = false
    this.isSubscribed = false
    this.updateLotTableInProgress = false
    this.debounced = null
    this.timerId = null
    this.bidTableRef = null
    this.userBuyersOnlineTableRef = React.createRef()
    this.userBuyersDisconnectedTableRef = React.createRef()
    this.lotsRef = React.createRef()
    this.lotsLiveRef = React.createRef()
    this.activeLotRef = React.createRef()
  }

  async componentDidMount() {
    this.connect()
    let record = await axios.get(`/auctions/${this.props.id}`)
    if (record.data.auctionType === 'sequentialOffline') {
      record.data.auctionType = 'On Property (Single Sequential)'
    }
    this.setState({ record: record.data.auction, resultsSent: !!record.data.auction.resultsSent })
    this.getPostcodeData()
    window.visibly.onVisible(this.connect)
    window.addEventListener('beforeunload', (ev) => {
      ev.preventDefault()
      this.props.changeWindowModal({ showActiveLot: false })
    })
  }

  componentDidUpdate() {
    let subscribe = () => {
      this.connect()
    }
    subscribe()
  }

  connect = () => {
    if (document.visibilityState === 'visible') {
      if (this.lotsLiveRef.current && this.lotsRef.current) {
        if (this.isSubscribed && socketService.sockets['bids'].connected && socketService.sockets['catalog'].connected) {
          return
        }
        this.isSubscribed = true
        socketService.sockets['bids'].on('lot-update', this.lotUpdate)
        // socketService.sockets['bids'].on('change-live', this.changeActiveLot)
        socketService.sockets['catalog'].on('auction', this.auctionUpdate)
        socketService.sockets['catalog'].on('update-draft-lot', this.updateLotTable)
        this.connectConnectivity()
      } else {
        setTimeout(this.connect, 1000)
      }
    }
  }
   getPostcodeData = async () => {
    let url = '/postcodes'
    const res = await axios.get(url);
    let data = [];
    if(res && res.data) {
      res.data.postcodesData.forEach(element => {
        data.push(`${element.region} (${element.state})`)
    });
    }
    data = Array.from(new Set(data));
    data =  data.map ((d)=>{
     return {id : d, name : d}
    })
    this.setState({regionsData : data})
   // setRegions(data);
  }

  updateLotTable = () => {
    let lotsTable = this.lotsRef.current
    if (!this.updateLotTableInProgress) {
      this.updateLotTableInProgress = true
      setTimeout(() => {
        if (lotsTable) {
          lotsTable.loadData()
          this.updateLotTableInProgress = false
        }
      }, delay)
    }
  }

  userUpdate = async () => {
    if (!this.requestSended) {
      this.requestSended = true
      setTimeout(() => {
        if (this.userBuyersOnlineTableRef.current && this.userBuyersOnlineTableRef.current.loadUserBuyers) {
          this.userBuyersOnlineTableRef.current.loadUserBuyers()
        }
        if (this.userBuyersDisconnectedTableRef.current && this.userBuyersDisconnectedTableRef.current.loadUserBuyers) {
          this.userBuyersDisconnectedTableRef.current.loadUserBuyers()
        }
        this.requestSended = false
      }, delay)
    }
  }

  connectConnectivity = () => {
    this.socketService = new SocketService()
    this.socket = this.socketService.socket
    this.socket.connect()
    this.socket.on('connectivity-update', this.userUpdate)
  }

  clearTimer = (cb) => {
    this.setState({
      timerEnabled: false
    }, () => {
      clearTimeout(this.timerId)
      if (cb) {
        cb()
      }
    })
  }

  auctionUpdate = async (data) => {
    if (data.doc._id === this.props.id) {
      let record = await axios.get(`/auctions/${data.doc._id}`)
      if (record.data.auctionType === 'sequentialOffline') {
        record.data.auctionType = 'On Property (Single Sequential)'
      }
      this.setState({ record: record.data.auction })
    }
  }

  handleClose = () => {
    this.setState({
      confirmationModalOpen: false,
      timesToConfirmCancel: 3
    })
  }

  checkIfRefsReady = () => {
    return this.lotsRef.current && this.lotsLiveRef.current && this.bidTableRef && this.userBuyersOnlineTableRef.current
  }

  lotUpdate = (data) => {
    const updateLotsBySocket = () => {
      if (this.lotsRef.current) {
        this.lotsRef.current.updateLotsBySocket(data)
      } else {
        setTimeout(updateLotsBySocket, 1000)
      }
    }

    const updateLiveLotsBySocket = () => {
      if (this.lotsLiveRef.current) {
        this.lotsLiveRef.current.updateLiveLotsBySocket(data)
      } else {
        setTimeout(updateLiveLotsBySocket, 1000)
      }
    }

    const loadBids = () => {
      if (this.bidTableRef) {
        this.bidTableRef.wrappedInstance.loadBids()
      } else {
        setTimeout(loadBids, 1000)
      }
    }

    const loadUserBuyers = () => {
      if (this.userBuyersOnlineTableRef.current && this.userBuyersOnlineTableRef.current.loadUserBuyers) {
        this.userBuyersOnlineTableRef.current.loadUserBuyers()
      } else {
        setTimeout(loadUserBuyers, 1000)
      }
    }

    updateLotsBySocket()
    updateLiveLotsBySocket()
    loadBids()
    loadUserBuyers()
  }

  updateValue = ({ target: { value } }, type) => {
    switch (type) {
      case 'select-states':
        if(!(value.includes('Select Region'))) {
          this.state.smsReminders.regions.splice(0,this.state.smsReminders.regions.length)
        }
        // this.setState({ smsReminders:{states : value} })
        if (value.includes('All')) {
          this.setState({
            smsReminders: update(this.state.smsReminders, {
              states: { $set: selectAllStates }
            })
          })
        } else if (value.includes('Select Region')) {
          value.splice(0, value.length)
          value.push('Select Region')
          this.setState({
            smsReminders: update(this.state.smsReminders, {
              states: { $set: value }
            })
          })
        }
        else {
         // console.log(this.state.smsReminders);
          if(this.state.smsReminders.states.includes('All') && !value.includes('All')){
            this.setState({
              smsReminders: update(this.state.smsReminders, {
                states: { $set: [] }
              })
            })
          }else {
            this.setState({
              smsReminders: update(this.state.smsReminders, {
                states: { $set: value }
              })
            })
          }

        }
       
        
        break
      case 'select-status':
        // this.setState({ smsReminders:{userStatus : value} })
        if (value.includes('All')) {
          this.setState({
            smsReminders: update(this.state.smsReminders, {
              userStatus: { $set: selectAllStatus }
            })
          })
        } else {
          if(this.state.smsReminders.userStatus.includes('All') && !value.includes('All')){
            this.setState({
              smsReminders: update(this.state.smsReminders, {
                userStatus: { $set: [] }
              })
            })
          } else {
            this.setState({
              smsReminders: update(this.state.smsReminders, {
                userStatus: { $set: value }
              })
            })
          }

        }

        break
      case 'select-types':
        // this.setState({ smsReminders:{userTypes : value} })
        if (value.includes('All')) {
          this.setState({
            smsReminders: update(this.state.smsReminders, {
              userTypes: { $set: selectAllUserOperations }
            })
          })
        } else {
          if(this.state.smsReminders.userTypes.includes('All') && !value.includes('All')){
            this.setState({
              smsReminders: update(this.state.smsReminders, {
                userTypes: { $set: [] }
              })
            })
          } else {
            this.setState({
              smsReminders: update(this.state.smsReminders, {
                userTypes: { $set: value }
              })
            })
          }

        }

        break
      case 'select-regions':
        this.setState({
          smsReminders: update(this.state.smsReminders, {
            regions: { $set: value }
          })
        })
        break
      default:
        break
    }

  }

  async componentWillUnmount() {
    this.props.changeWindowModal({ show: false, auction: {} })
    if (this.socket) {
      this.socket.close()
    }
    this.clearTimer()
    try {
      await new Promise((resolve, reject) => {
        socketService.sockets.emit('bids', 'leave-auction', { _id: this.props.id }, (err) => {
          if (err) {
            reject(err)
          } else {
            resolve()
          }
        })
      })
      socketService.sockets['bids'].off('lot-update', this.lotUpdate)
      socketService.sockets['catalog'].off('auction', this.auctionUpdate)
      socketService.sockets['catalog'].off('update-draft-lot', this.updateLotTable)
    } catch (error) {
      console.warn(error)
    }
  }

  editAdminBid = (auction) => {
    this.props.changeWindowModal({ showAdminBid: true, auction: auction })
  }

  openActiveLot = (auction) => {
    this.props.changeWindowModal({ showActiveLot: true, auction: auction })
  }
  render() {
    const { record,smsReminders,regionsData } = this.state
    const AuctionTitle = ({ record = {} }) => {
      let title = `Details, ${record.searchNumber}`
      if (!this.state.record) {
        this.setState({
          record
        })
      }
      if (record.cancelled) {
        title += ' (Cancelled)'
      }
      return (
        <h2 className='title'>{title}</h2>
      )
    }

    const AdminBidButton = ({ record = {} }) => {
      return (
        <div>
          <Button onClick={() => { this.editAdminBid(record) }} variant='outlined' color='primary'>Admin Bid</Button>
        </div>
      )
    }
    const ActiveLotButton = ({ record = {} }) => {
      return (
        <div>
          <Button onClick={() => { this.openActiveLot(record) }} variant='outlined' color='primary'>Open Active Lot</Button>
        </div>
      )
    }

    let defaultProps = pick(this.props, auctionDefaultProps)

    const sendSms = () => {
      this.setState({ confirmationModalOpen: false })
      switch (this.state.smsType) {
        case 'auctionIsLive':
          axios.post(`/auctions/${this.state.record._id}/send-sms`, {
            type: 'auctionIsLive',
            smsReminders: this.state.smsReminders
          })
          break
        case 'auctionIsOpen':
          axios.post(`/auctions/${this.state.record._id}/send-sms`, {
            type: 'auctionIsOpen',
            smsReminders: this.state.smsReminders
          })
          break
        case 'listingsAreOpen':
          axios.post(`/auctions/${this.state.record._id}/send-sms`, {
            type: 'listingsAreOpen',
            states: this.state.smsSelectvalue
          })
          break
        default:
          break
      }
    }

    const sendAuctionIsLiveSMS = () => {
      this.setState({
        confirmationModalOpen: true,
        smsType: 'auctionIsLive',
        modalType: modalTypes.sms
      })
    }

    const sendAuctionIsOpenSMS = () => {
      this.setState({
        confirmationModalOpen: true,
        smsType: 'auctionIsOpen',
        modalType: modalTypes.sms
      })
    }

    /*const sendListingsAreOpenSMS = () => {
      this.setState({
        confirmationModalOpen: true,
        smsType: 'listingsAreOpen',
        modalType: modalTypes.sms
      })
    }*/

    const handleCancelAuction = async (props) => {
      this.setState({
        confirmationModalOpen: true,
        modalType: modalTypes.cancel,
        propsForModal: props
      })
    }

    const handleCloseLots = async (props) => {
      this.setState({
        confirmationModalOpen: true,
        modalType: modalTypes.closeAuction,
        propsForModal: props
      })
    }

    const handleOpenAuction = async (props) => {
      console.log(this.props, 'this')
      let auction_id = this.props.id
      let data = {
        auction_id
      }

      let res = await sendOpenAuctionRequest(data)
      if (res.status === 200) {
        this.props.showNotification('Auction Opened Successfully!', 'success')
        window.location.reload()

      }
    }

    const sendOpenAuctionRequest = async (data) => {
      try {
        return await axios(`lots/open-auction`, {
          method: 'post',
          data

        })
      } catch (e) {
        throw e
      }
    }

    /* const handleGenarateInvoice = async (props) => {
      let auction_id = this.props.id
      let data = {
        auction_id
      }

      let res = await sendGenarateInvoicerequest(data)
      if (res.status === 200) {
        this.props.showNotification('Invoce Generated  Successfully!', 'success')

      }
    } */

    // const sendGenarateInvoicerequest = async (data) => {
    //   try {
    //     return await axios(`lots/genarate-invoice`, {
    //       method: 'post',
    //       data

    //     })
    //   } catch (e) {
    //     throw e
    //   }
    // }

    
    // const handleseqAuctionResultEmails = async () => {
    //   try {
    //     const res = await axios.post(`/auctions/${this.state.record._id}/send-auction-result-emails`)
    //     if (res.status === 200) {
    //       this.props.showNotification('Emails were sent successfully', 'success')
    //       this.setState({ resultsSent: res.data.resultsSent })
    //     }
    //   } catch (e) {
    //     throw e
    //   }
    // }

    const handleConfirmCancel = () => {
      if (this.state.timesToConfirmCancel > 1) {
        const newTimesToConfirmCancel = this.state.timesToConfirmCancel - 1
        this.setState({ timesToConfirmCancel: newTimesToConfirmCancel })
      } else {
        cancelAuction(this.state.propsForModal)
        this.setState({ confirmationModalOpen: false })
      }
    }

    const handleConfirmClose = () => {
      if (this.state.timesToConfirmCancel > 1) {
        const newTimesToConfirmCancel = this.state.timesToConfirmCancel - 1
        this.setState({ timesToConfirmCancel: newTimesToConfirmCancel })
      } else {
        closeAuction(this.state.propsForModal)
        this.setState({ confirmationModalOpen: false })
      }
    }
    const handleAuctionResultEmails = async () => {
      console.log(this.props, 'this')
      let auction_id = this.props.id
      let data = {
        auction_id
      }
      let res = await sendRequest(data)
      if (res.status === 200) {
        this.props.showNotification('Emails were sent successfully and will be delivered shortly!', 'success')
        this.setState({ resultsSent: res.data.resultsSent })
      }
    }
    const sendRequest = async (data) => {
      try {
        return await axios(`lots/manual-auction-results`, {
          method: 'post',
          data

        })
      } catch (e) {
        throw e
      }
    }

    const NotificationInputField = ({ record = {} }) => {
      const [notification, setNotification] = useState(record.notification)
      const handleNotification = ({ target: { value } }) => { setNotification(value) }

      useEffect(() => {
        if (record.notification) {
          setNotification(record.notification)
        } else {
          setNotification('')
        }
      }, [record.notification])

      return (
        <div className='notification-input-field-container'>
          <div className='input-wrapper'>
            <input type='text' value={notification} onChange={handleNotification} />
          </div>
          <div className='inside-buttons'>
            <ActionButton data={this.props} notification={notification} onClick={publishNotification}
              variant='outlined' color='primary'>
              Publish Notification
            </ActionButton>
            <ActionButton data={this.props} onClick={stopNotification} variant='outlined' color='primary'>
              Stop Notification
            </ActionButton>
          </div>
        </div>

      )
    }

    return (
      <Fragment>
        <div className='auction-details'>
          <Show {...defaultProps} title={<AuctionTitleHeader />}>
            <SimpleShowLayout className='auction-info-wrapper'>
              <AuctionTitle />
              <ConfirmationDialog
                confirmationModalOpen={this.state.confirmationModalOpen}
                sendSms={sendSms}
                modalType={this.state.modalType}
                timesToConfirmCancel={this.state.timesToConfirmCancel}
                handleConfirmCancel={handleConfirmCancel}
                handleClose={this.handleClose}
                handleConfirmClose={handleConfirmClose}
                auction={this.state.record}
              />
              <SimpleShowLayout className='auction-info auction-info1'>
                <SimpleShowLayout className='info-wrapper'>
                  <AuctionInfo auctionData={this.state.record} className='info-table' fields={[
                    {
                      source: 'title',
                      label: 'Title'
                    },
                    {
                      source: 'number',
                      label: 'Number'
                    },
                    {
                      source: 'auctionType',
                      label: 'Auction Type'
                    },
                    {
                      source: 'kind',
                      label: 'Category',
                      basePath: true
                    },
                    {
                      source: 'approved',
                      label: 'Approved',
                      type: 'boolean'
                    },
                    {
                      source: 'location.state',
                      label: 'Location'
                    },
                    {
                      source: 'viewDetailsDays',
                      label: 'View Details days'
                    },
                    {
                      source: 'lotCount',
                      label: 'Lot Count'
                    },
                    {
                      source: 'headCount',
                      label: 'Head Count'
                    },
                    {
                      source: 'auctionTags',
                      label: 'Tags',
                      type: 'tags'
                    }
                  ]} />
                  <AuctionInfo auctionData={this.state.record} className='info-table' fields={[
                    {
                      source: 'showLotNumbers',
                      label: 'Show Lot Number',
                      type: 'boolean'
                    },
                    {
                      source: 'public',
                      label: 'Public',
                      type: 'boolean'
                    },
                    {
                      source: 'privateSale',
                      label: 'Is this a Private Sale?',
                      type: 'boolean'
                    },
                    {
                      source: 'studSale',
                      label: 'Is this a Stud Sale?',
                      type: 'boolean'
                    },
                    {
                      source: 'allowAutoBid',
                      label: 'Allow Auto-bids?',
                      type: 'boolean'
                    },
                    {
                      source: 'allowUpcomingBid',
                      label: 'Allow bids on upcoming Lot?',
                      type: 'boolean'
                    },
                    {
                      source: 'abbLambSale',
                      label: 'Is this an AbbLamb Sale?',
                      type: 'boolean'
                    },
                    {
                      source: 'requiresTabs',
                      label: 'Auction Requires Tabs?',
                      type: 'boolean'
                    },
                  ]} />
                  <AuctionInfo auctionData={this.state.record} className='info-table' fields={[
                    {
                      source: '_state',
                      label: 'Auction Status',
                      type: 'state'
                    },
                    {
                      type: 'liveAt',
                      source: 'localLiveAt',
                      label: 'Live At'
                    },
                    {
                      type: 'liveIn',
                      source: 'localLiveAt',
                      label: 'Live In'
                    },
                    {
                      type: 'liveSince',
                      source: 'localLiveAt',
                      label: 'Live Since'
                    },
                    {
                      type: 'timeRemaining',
                      source: 'leftTime',
                      label: 'Time Remaining',
                      withTickTock: true
                    }
                  ]} />
                  <AuctionInfo auctionData={this.state.record} className='info-table' fields={[
                    {
                      source: 'auctionOpenTimer',
                      label: 'Auction Open Timer',
                      type: 'timeFormatMinutes'
                    },
                    {
                      source: 'addLotOpenTimer',
                      label: 'Add lot Open timer',
                      type: 'timeFormatMinutes'
                    },
                    {
                      source: 'addLotCloseTimer',
                      label: 'Add Lot Close Timer',
                      type: 'timeFormatMinutes'
                    },
                    {
                      source: 'lotOpeningSeconds',
                      label: 'Lot Opening Time',
                      type: 'time'
                    },
                    {
                      source: 'bidAddedSeconds',
                      label: 'Bid Added Time',
                      type: 'time'
                    },
                    {
                      source: 'lotMaxSeconds',
                      label: 'Lot max Time',
                      type: 'time'
                    },
                    {
                      source: 'standbyTimer',
                      label: 'Standby Timer',
                      type: 'time'
                    },
                    // {
                    //   source: 'auctionMaxSeconds',
                    //   label: 'Auction Max Time',
                    //   type: 'time'
                    // }
                  ]} />
                  <AuctionInfo auctionData={this.state.record} className='info-table' fields={[
                    {
                      source: 'listingFee',
                      label: 'Listing Fee'
                    },
                    {
                      source: 'specialPriceFee',
                      label: 'Listing Fee (ram/buck/bull)'
                    }
                  ]} />
                  <AuctionInfo auctionData={this.state.record} className='info-table' fields={[
                    {
                      label: 'SMS Reminders(Auto)',
                      source : ''
                    },
                    {
                     // source: 'smsReminderRegions',
                     source : 'smsReminders.states',
                      label: 'States'
                    },
                    {
                     // source: 'regions',
                     source : 'smsReminders.regions',
                      label: 'Regions'
                    },
                    {
                      source : 'smsReminders.userStatus',
                       label: 'Status'
                     },
                     {
                      source : 'smsReminders.userTypes',
                       label: 'Profession'
                     }
                  ]} />
                  {this.state.record && this.state.record.privateSale &&
                    <AuctionInfo auctionData={this.state.record} className='info-table' fields={[
                      {
                        source: 'auctionSetUpFee',
                        label: 'Auction Set Up Fee'
                      },
                      {
                        source: 'auctionDiscount',
                        label: 'Auction Discount(%)'
                      }
                    ]}
                    />
                  }
                  <TextField className='description-layout' label='Auction Overview Description:' source='descriptionShort' />
                  <TextField className='description-layout' label='In Catalogue Description:' source='description' />
                  <AuctionInfo className='info-table auction-tabs-wrapper' fields={[
                    {
                      source: 'auctionTabs',
                      label: 'Auction Tabs',
                      type: 'auctionTabs'
                    }
                  ]} />
                  <TextField className='admin-layout' label='Admin Notes:' source='adminNotes' />
                  <AuctionInfo className='info-table auction-useful-links-wrapper' fields={[
                    {
                      source: 'usefulLinks',
                      label: 'Useful links',
                      type: 'usefulLinks'
                    }
                  ]} />
                  <AuctionInfo className='info-table auction-image-wrapper' fields={[
                    {
                      source: 'image.url',
                      label: 'Image'
                    }
                  ]} />
                  <AuctionInfo className='info-table auction-image-wrapper' fields={[]} />
                  <AuctionInfo className='info-table auction-image-wrapper' fields={[
                    {
                      source: 'logo.url',
                      label: 'Logo for Web',
                      type: 'logo'
                    }
                  ]} />
                  <AuctionInfo className='info-table auction-image-wrapper' fields={[]} />
                  <AuctionInfo className='info-table auction-image-wrapper' fields={[
                    {
                      source: 'logoIos.url',
                      label: 'Logo for iOs',
                      type: 'logoIos'
                    }
                  ]} />
                </SimpleShowLayout>

                <SimpleShowLayout className='buttons-wrapper'>
                  <SimpleShowLayout className='inside-buttons'>
                    <CustomPauseButton props={this.props} />
                    <CustomFreezeButton props={this.props} />
                  </SimpleShowLayout>
                  <SimpleShowLayout className='inside-buttons'>
                    <ActionButton data={this.props} onClick={resumeAuction} variant='outlined' color='primary'>
                      Resume Auction
                    </ActionButton>
                     
                  </SimpleShowLayout>
                  <SimpleShowLayout className='inside-buttons'>
                    <CustomAddMinuteToActiveLots props={this.props} minutes={1} />
                    <CustomAddMinuteToActiveLots props={this.props} minutes={5} />
                  </SimpleShowLayout>
                  {
                    record && record.auctionType === 'synchronous' &&
                    <SimpleShowLayout className='inside-buttons'>
                      <CustomEditMinuteToAuctionLotMaxTime props={this.props} minutes={-1} />
                      <CustomEditMinuteToAuctionLotMaxTime props={this.props} minutes={-5} />
                    </SimpleShowLayout>
                  }
                  <SimpleShowLayout className='inside-buttons alone-button'>
                    <ActionButton data={this.props} onClick={saveAndExport} variant='outlined' color='primary'>
                      Save and Export
                    </ActionButton>
                  </SimpleShowLayout>
                  <NotificationInputField />
                  <SimpleShowLayout className='inside-buttons alone-button'>
                    <AdminBidButton />
                  </SimpleShowLayout>
                  {(record && record.state === 'live' && record.auctionType === 'sequentialOffline') &&
                    <SimpleShowLayout className='inside-buttons alone-button'>
                      <ActiveLotButton />
                    </SimpleShowLayout>
                  }
                  <SimpleShowLayout>
                  <div style={{ marginBottom: '-20px', color: '#B9B9B9' }}>
                    <p>SMS Reminders(Manual) :</p>
                  </div>
                  </SimpleShowLayout>
                  
                  <SimpleShowLayout>
                    <div style={{ margin: '10px' }}>
                      <FormControl>
                        <InputLabel style={{ zIndex: '10', marginTop: '-10px' }} id='Choose-States'>Select
                          States</InputLabel>
                        <Select
                          onChange={(e)=>this.updateValue(e,'select-states')}
                          value={this.state.smsReminders.states ? this.state.smsReminders.states : []}
                          multiple
                          style={{ borderBottom: '1px solid' }}
                          labelId='Choose-States'
                        >
                          {locations.map((option, index) => (
                            <MenuItem key={index} value={option.id}>{formatTitleForType(option.id)}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </SimpleShowLayout>
                  {
                   smsReminders.states && smsReminders.states.includes('Select Region')  && 
                   <SimpleShowLayout>
                    <div style={{ margin: '10px' }}>
                      <FormControl>
                          <InputLabel style={{ zIndex: '10', marginTop: '-10px' }} id='Choose-Status'>Select
                          Region</InputLabel>
                        <Select
                          onChange={(e) => this.updateValue(e,'select-regions')}
                          value={this.state.smsReminders.regions ? this.state.smsReminders.regions : []}
                          multiple
                          style={{ borderBottom: '1px solid' }}
                          labelId='Choose-Status'
                        >
                          {regionsData.map((option, index) => (
                            <MenuItem key={index} value={option.id}>{formatTitleForType(option.id)}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </SimpleShowLayout>

                  }
                  <SimpleShowLayout>
                    <div style={{ margin: '10px' }}>
                      <FormControl>
                          <InputLabel style={{ zIndex: '10', marginTop: '-10px' }} id='Choose-Status'>Select
                          Status</InputLabel>
                        <Select
                          onChange={(e) => this.updateValue(e,'select-status')}
                          value={this.state.smsReminders.userStatus ? this.state.smsReminders.userStatus : []}
                          multiple
                          style={{ borderBottom: '1px solid' }}
                          labelId='Choose-Status'
                        >
                          {statuses.map((option, index) => (
                            <MenuItem key={index} value={option.id}>{formatTitleForType(option.id)}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </SimpleShowLayout>
                  <SimpleShowLayout>
                    <div style={{ margin: '10px' }}>
                      <FormControl>
                          <InputLabel style={{ zIndex: '10', marginTop: '-10px' }} id='Choose-Types'>Select
                          Profession</InputLabel>
                        <Select
                          onChange={(e) => this.updateValue(e,'select-types')}
                          value={this.state.smsReminders.userTypes ? this.state.smsReminders.userTypes : []}
                          multiple
                          style={{ borderBottom: '1px solid' }}
                          labelId='Choose-Types'
                        >
                          {userOperations.map((option, index) => (
                            <MenuItem key={index} value={option.id}>{formatTitleForType(option.id)}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </SimpleShowLayout>
                  <SimpleShowLayout>
                    <Button  onClick={sendAuctionIsLiveSMS} variant='outlined' color='primary' 
                    disabled = {!((smsReminders.states.includes('Select Region') ? smsReminders.regions.length > 0 : smsReminders.states.length > 0) && smsReminders.userStatus.length > 0 && smsReminders.userTypes.length > 0)}>
                      Send Auction is Live SMS
                    </Button>
                    {/* <Button onClick={sendListingsAreOpenSMS} variant='outlined' color='primary'>
                      Send Listings Are Open SMS
                    </Button> */}
                  </SimpleShowLayout>
                  <SimpleShowLayout>
                    <Button onClick={sendAuctionIsOpenSMS} variant='outlined'
                      color='primary'
                      disabled = {!((smsReminders.states.includes('Select Region') ? smsReminders.regions.length > 0 : smsReminders.states.length > 0) && smsReminders.userStatus.length > 0 && smsReminders.userTypes.length > 0)}
                      >Send Auction is Open SMS</Button>
                  </SimpleShowLayout>
                  {record && record.auctionType === 'sequential' &&
                    <SimpleShowLayout>
                      <ActionButton data={this.props} onClick={runNextLotInSequentialAuction} variant='outlined'
                        color='primary'>Launch next Lot in Auction</ActionButton>
                    </SimpleShowLayout>
                  }
                  <SimpleShowLayout className='inside-buttons alone-button'>
                    <ActionButton
                      data={this.props}
                      onClick={handleCancelAuction}
                      variant='outlined'
                      color='primary'
                      disabledField='cancelled'>
                      Cancel Auction
                    </ActionButton>
                  </SimpleShowLayout>

                  {(record && record.state === 'closed') &&
                    <SimpleShowLayout className='inside-buttons alone-button'>
                      <ActionButton data={this.props}
                        // onClick={record.auctionType === 'sequentialOffline' ? handleseqAuctionResultEmails : handleAuctionResultEmails }
                        onClick={handleAuctionResultEmails}

                        variant={this.state.resultsSent ? 'contained' : 'outlined'}
                        color='primary'
                        disabledField='cancelled'>
                        Send auction result emails
                      </ActionButton>
                    </SimpleShowLayout>
                  }

                  <SimpleShowLayout className='inside-buttons alone-button'>
                    <ActionButton
                      data={this.props}
                      onClick={handleCloseLots}
                      variant='outlined'
                      color='primary'
                      disabledField='cancelled'>
                      Close Auction
                    </ActionButton>
                  </SimpleShowLayout>
                  {(record && record.state === 'closed') &&
                    <SimpleShowLayout className='inside-buttons alone-button'>
                      <ActionButton
                        data={this.props}
                        onClick={handleOpenAuction}
                        variant='outlined'
                        color='primary'
                        disabledField='cancelled'>
                        Open Auction
                      </ActionButton>
                    </SimpleShowLayout>
                  }
                  {/* {(record && record.state === 'closed') &&
                    <SimpleShowLayout className='inside-buttons alone-button'>
                      <ActionButton
                        data={this.props}
                        onClick={handleGenarateInvoice}
                        variant='outlined'
                        color='primary'
                        disabledField='cancelled'>
                        Genarate Invoice
                      </ActionButton>
                    </SimpleShowLayout>
                  } */}
                </SimpleShowLayout>
              </SimpleShowLayout>
              <LotsTable ref={this.lotsRef} />
              <LiveLotsTable ref={this.lotsLiveRef} auction={this.state.record} auctionId={this.props.id} data= {this.props} />
              <VendorsTable auctionId={this.props.id} />
              <UserBuyersDisconnected ref={this.userBuyersDisconnectedTableRef} auctionId={this.props.id} />
              <UserBuyersOnline ref={this.userBuyersOnlineTableRef} auctionId={this.props.id} />
              <Bids ref={ref => { this.bidTableRef = ref }} auctionId={this.props.id} />
              <PreBid auction={this.state.record} auctionId={this.props.id} />
            </SimpleShowLayout>
          </Show>
        </div>
      </Fragment>
    )
  }
}

import React from 'react'
import { Admin, Resource} from 'react-admin'
import { Route } from 'react-router-dom';
import axios from 'axios'
import { createHashHistory } from 'history'
import { Provider } from 'react-redux'
import createAdminStore from './createAdminStore'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faListUl,
  faGavel,
  faFileAlt,
  faUser,
  faMoneyBillWaveAlt,
  faEnvelope,
  faChartBar,
  faQuestionCircle,
  faExclamationCircle,
  faToolbox,
  faColumns,
  faEye,
  faEyeSlash,
  faAsterisk,
  faBook,
  faPlusSquare,
  faMinusSquare,
  faAngleDown,
  faAngleUp,
  faChartLine,
  faAddressBook,
  faBell
} from '@fortawesome/free-solid-svg-icons'
import socketService from './utils/socketConnection'

/* Home */
import { HomeComponent } from './models/home/model'
/* Home */
/* Users */
import { UserList } from './models/users/model'
import { UserCreate } from './models/users/create/model'
import { UserEdit } from './models/users/edit/model'
import { UserShow } from './models/users/show/model'
/* Users */
/* Lots */
import { LotList } from './models/lots/model'
import { LotShow } from './models/lots/show/model'
import { LotEdit } from './models/lots/edit/model'
import { LotCreate } from './models/lots/create/model'
/* Lots */
import { FaqList, FaqCreate, FaqEdit } from './models/faqs/model'
import { StateList, PostcodeCreate, PostcodeEdit } from './models/postcodes/model'
import { PostcodeList} from './models/postcodes/main'
import { KindList, KindShow, KindEdit } from './models/kinds/model'
import { TermList, TermEdit, TermCreate } from './models/terms/model'
import { AssessList, AssessEdit, AssessCreate } from './models/how-to-assess/model'
import { ToolList, ToolEdit, ToolCreate } from './models/tools/model'
import { VideoList, VideoEdit, VideoCreate } from './models/how-to-videos/model'
/* Auctions */
import { AuctionList } from './models/auctions/model'
import { AuctionEdit } from './models/auctions/edit/model'
import { AuctionCreate } from './models/auctions/create/model'
import AuctionShow from './models/auctions/show/modelContainer'
/* Auctions */
import { ContactUsList } from './models/contact-us/model'
import { ReportList } from './models/reports/model'
import { InvoiceEdit, InvoiceList } from './models/invoices/model'
import NotFound from './cards/NotFound'
import { restProvider } from './providers/restProvider'
import authProvider from './providers/authProvider'
import appLayout from './theme/appLayout'
import './App.css'
import './styles/main.css'
/* Emails */
import { EmailsList } from './models/emails/model'
/* Emails */
import defaultMessages from 'ra-language-english'
/* Notifications */
import { NotificationsConfig } from './models/notifications/model'
/* Notifications */
import JssProvider from 'react-jss/lib/JssProvider'
import { ReOfferList } from './models/reoffers/model'
import {TermsVersionsShowComponent} from './models/terms/TermsVersionsShow'

axios.interceptors.request.use(function (config) {
  config.headers.platform = 'web'
  config.headers['X-Content-Type-Options'] = 'nosniff'
  config.headers['Content-Security-Policy'] = 'script-src self'
  config.headers['Referrer-Policy'] = 'no-referrer'
  config.headers['Feature-Policy'] = 'camera'
  return config
}, function (error) {
  throw error
})
const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = {}
  }
  options.url = url
  return axios.request(options)
}
export const dataProvider = restProvider(httpClient)
const history = createHashHistory()
socketService.connection()

const i18nProvider = locale => {
  return defaultMessages
}

const escapeRegex = /([[\].#*$><+~=|^:(),"'`\s])/g
let classCounter = 0

// Heavily inspired of Material UI:
// @see https://github.com/mui-org/material-ui/blob/9cf73828e523451de456ba3dbf2ab15f87cf8504/src/styles/createGenerateClassName.js
// The issue with the MUI function is that is create a new index for each
// new `withStyles`, so we handle have to write our own counter
export const generateClassName = (rule, styleSheet) => {
  classCounter += 1

  /* if (process.env.NODE_ENV === 'production') {
    return `c${classCounter}`
  } */

  if (styleSheet && styleSheet.options.classNamePrefix) {
    let prefix = styleSheet.options.classNamePrefix
    // Sanitize the string as will be used to prefix the generated class name.
    prefix = prefix.replace(escapeRegex, '-')

    if (prefix.match(/^Mui/)) {
      return `${prefix}-${rule.key}`
    }

    return `${prefix}-${rule.key}-${classCounter}`
  }

  return `${rule.key}-${classCounter}`
}

const App = () => (
  <Provider store={createAdminStore({
    authProvider,
    dataProvider,
    i18nProvider,
    history
  })}>
    <JssProvider generateClassName={generateClassName}>
      <Admin appLayout={appLayout}
        authProvider={authProvider}
        title='Farmgate'
        catchAll={NotFound}
        dataProvider={dataProvider} history={history}
        customRoutes={[
                  <Route path="/custom" component={PostcodeList} />,
                  <Route path="/terms-version" component={TermsVersionsShowComponent} />,
                   
              ]}
        >
        <Resource
          name='home'
          options={{ label: 'Home', icon: 'columns' }}
          list={HomeComponent}
        />
        <Resource
          name='auctions'
          options={{ label: 'Auctions', icon: 'gavel' }}
          list={AuctionList}
          show={AuctionShow}
          edit={AuctionEdit}
          create={AuctionCreate}
        />
        <Resource
          name='lots'
          options={{ label: 'Lots', icon: 'file-alt' }}
          list={LotList}
          show={LotShow}
          edit={LotEdit}
          create={LotCreate}
        />
        <Resource
          name='users'
          options={{ label: 'Users', icon: 'user' }}
          list={UserList}
          create={UserCreate}
          edit={UserEdit}
          show={UserShow}
        />
        <Resource
          name='how-to-assess-steps'
          options={{ label: 'How To Assess', icon: 'toolbox' }}
          list={AssessList}
          edit={AssessEdit}
          create={AssessCreate}
        />
        <Resource
          name='invoices'
          options={{ label: 'Invoices', icon: 'toolbox' }}
          list={InvoiceList}
          edit={InvoiceEdit}
        />
        <Resource
          name='reports'
          options={{ label: 'Reports', icon: 'chart-line' }}
          list={ReportList}
        />
        <Resource
          name='kinds'
          options={{ label: 'Assessment Forms', icon: 'list-ul' }}
          list={KindList}
          show={KindShow}
          edit={KindEdit}
        />
        <Resource
          name='reoffers'
          options={{ label: 'Re Offers', icon: 'list-ul' }}
          list={ReOfferList}
          
        />
        <Resource
          name='faqs'
          options={{ label: 'FAQs', icon: 'question-circle' }}
          list={FaqList}
          edit={FaqEdit}
          create={FaqCreate}
        />
        <Resource
          name='terms'
          options={{ label: 'Terms', icon: 'exclamation-circle' }}
          list={TermList}
          edit={TermEdit}
          create={TermCreate}
        />
        <Resource
          name='tools'
          options={{ label: 'Getting Started', icon: 'book' }}
          list={ToolList}
          edit={ToolEdit}
          create={ToolCreate}
        />
        <Resource
          name='how-to-videos'
          options={{ label: 'How To Videos', icon: 'toolbox' }}
          list={VideoList}
          edit={VideoEdit}
          create={VideoCreate}
        />
        <Resource
          name='contacts'
          options={{ label: 'Contact Us', icon: 'address-book' }}
          list={ContactUsList}
        />
        <Resource
          name='emails/config'
          options={{ label: 'Emails', icon: 'envelope' }}
          list={EmailsList}
        />
        <Resource
          name='notifications/config'
          options={{ label: 'Notifications', icon: 'bell' }}
          list={NotificationsConfig}
        />
         <Resource
          name='postcodes'
          options={{ label: 'PostCodes', icon: 'question-circle' }}
          list = {StateList}
          edit={PostcodeEdit}
          create={PostcodeCreate}
        />
      </Admin>
    </JssProvider>
  </Provider>
)

library.add(faListUl)
library.add(faGavel)
library.add(faFileAlt)
library.add(faUser)
library.add(faMoneyBillWaveAlt)
library.add(faChartBar)
library.add(faEnvelope)
library.add(faQuestionCircle)
library.add(faExclamationCircle)
library.add(faToolbox)
library.add(faColumns)
library.add(faEye)
library.add(faEyeSlash)
library.add(faAsterisk)
library.add(faBook)
library.add(faPlusSquare)
library.add(faMinusSquare)
library.add(faAngleDown)
library.add(faAngleUp)
library.add(faAddressBook)
library.add(faChartLine)
library.add(faBell)

export default App

import React, { useState } from 'react'
import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  FileInput,
  ImageField,
  required,
  CheckboxGroupInput,
  BooleanInput
} from 'react-admin'
import Checkbox from '@material-ui/core/Checkbox'
import { roleOptions, tradeThroughCompanyOptions,statusOptions, discountOptions, states } from '../options'
import EditToolbar from '../../../components/EditToolbar'
import config from '../../../config'
import AdminComments from '../../../components/AdminComments/AdminComments'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { professionOther } from '../../../constants'
import { getUserTypeByStatus } from '../../../utils'

let strikes = [
  { id: '1', name: '1' },
  { id: '2', name: '2' },
  { id: '3', name: '3' }
]
let currantState = null

const validateUserEdit = (values) => {
  const errors = {}

  if (values.profession === '') {
    errors.profession = ['Required field']
  }
  if (values.profession === professionOther && values.otherProfession === '') {
    errors.otherProfession = ['Required field']
  }
  if (values.phone.search(/^\d*$/) === -1 && values.phone.length > 0) {
    errors.phone = ['Phone number must contain only digits']
  }
  if (values.secondaryPhone.search(/^\d*$/) === -1 && values.secondaryPhone.length > 0) {
    errors.secondaryPhone = ['Phone number must contain only digits']
  }
  /*
  if (!values.SSPermitNumber && values.type === 'seller' && values.status === 'approved') {
    errors.SSPermitNumber = ['Required field']
  }
  if (!values.SSPermitNumberOfYears && values.type === 'seller' && values.status === 'approved') {
    errors.SSPermitNumberOfYears = ['Required field']
  }
  if (!values.policyFile && values.type === 'seller' && values.status === 'approved') {
    errors.policyFile = ['Required field']
  }
  if (!values.licenseFile && values.type === 'seller' && values.status === 'approved') {
    errors.licenseFile = ['Required field']
  }
  */
  if (values.yearsInIndustry && values.yearsInIndustry.search(/^\d*$/) === -1 && values.yearsInIndustry.length > 0) {
    errors.yearsInIndustry = ['Years In Industry must contain only digits']
  }
  /*if (values.bankDetails && values.bankDetails.BSB.search(/^\d{6}$/) === -1 && values.bankDetails.BSB.length > 0) {
    errors.bankDetails = {}
    errors.bankDetails.BSB = ['Please Enter 6 Digit BSB']
  }*/
  return errors
}

const BuyerFields = ({ record = {} }) => {
  return record.type === 'buyer' && (
    <div>
      <TextInput source='agentName' label='Agent Name' />
      <TextInput source='agentPhone' label='Agent Number' />
      <TextInput source='agentTradingName' label='Agent Trading Name' />
    </div>
  )
}

const PicField = ({ record = {} }) => {
  return (
    <TextInput
      source="PIC"
      label="PIC"
      validate={record.status === "registered" ? false : required()}
    />
  );
};

export const UserEdit = (props) => {
  const [officeAddressState, setOfficeAddressState] = useState('')
  const [additionalState, setAdditionalState] = useState('')
  const [isValidate, setIsValidate] = useState(null)
  const [userType, setUserType] = useState(null)
  let [profession, setProfession] = useState(null)
  let [professionOptions, setProfessionOptions] = useState([])
  let [professionOptionsLoaded, setProfessionOptionsLoaded] = useState(null)
  let [kindOptions, setKindOptions] = useState([])
  let [kindOptionsLoaded, setKindOptionsLoaded] = useState(null)
  const [anotherStatePicAvailable, setAnotherStatePicAvailable] = useState(null)
  const [tradeThroughCompany, setTradeThroughCompany] = useState(null)
  const [registeredForGST, setRegisteredForGST] = useState(null)
  const [areYouUsingAgent, setAreYouUsingAgent] = useState(null)
  let [showSelect, setShowSelect] = useState(true)
  let [userTags, setUserTags] = useState([])
  let [userTagsSet, setUserTagsSet] = useState(false)
  if (!userTagsSet) {
    axios.get('/user-tags').then((response) => {
      setUserTags(response.data['user-tags'])
      setUserTagsSet(true)
    })
  }
  if (!professionOptionsLoaded) {
    axios.get('/professions').then((response) => {
      let arrayProfessions = response.data.professions.map((document, index) => {
        document.id = document.name
        return document
      })
      setProfessionOptions(arrayProfessions)
      setProfessionOptionsLoaded(true)
    })
  }
  if (!kindOptionsLoaded) {
    axios.get('/kinds').then((response) => {
      setKindOptions(response.data.kinds)
      setKindOptionsLoaded(true)
    })
  }

  const { ...newProps } = props
  if (!additionalState) {
    console.log(additionalState)
  }

  function onChangeState(e, value) {
    setOfficeAddressState(value)
  }

  function onChangeAdditionalState(e, value) {
    setAdditionalState(value)
  }

  function onChangeStatus(e, value, record) {
    if (config.requiredStatuses.includes(value)) {
      setIsValidate(true)
    } else {
      setIsValidate(false)
    }
    setUserType(getUserTypeByStatus(value, record.type))
  }

  function onChangeProfession(e, value) {
    setProfession(value)
  }

  const validateRequired = () => {
    return isValidate ? required() : null
  }

  const OfficeAddress = ({ record }) => {
    if (currantState !== record.officeAddress.state || !officeAddressState) {
      currantState = record.officeAddress.state
      setOfficeAddressState(record.officeAddress.state)
    }
    if (isValidate === null) {
      if (config.requiredStatuses.includes(record.status)) {
        setIsValidate(true)
      } else {
        setIsValidate(false)
      }
    }
    return (
      <div className='address-block'>
        <p className='title'>Office Address:</p>
        <div className='inputs'>
          <TextInput source='officeAddress.address1' label='Address 1' validate={validateRequired()} />
          <TextInput source='officeAddress.address2' label='Address 2' />
          <TextInput source='officeAddress.town' label='Town' validate={validateRequired()} />
          <TextInput source='officeAddress.postcode' label='Postcode' validate={validateRequired()} />
          <SelectInput onChange={onChangeState} source='officeAddress.state' choices={states} label='State' validate={validateRequired()} />
        </div>
      </div>
    )
  }

  const IndastryReferees = ({ record = {} }) => {
    return record.type === 'seller' && !config.exceptedStates.includes(officeAddressState) && (
      <React.Fragment>
        <div className='address-block'>
          <p className='title'>Referee 1</p>
          <div className='inputs'>
            <TextInput validate={validateRequired()} source='referee1.firstName' label='First Name' />
            <TextInput validate={validateRequired()} source='referee1.lastName' label='Last Name' />
            <TextInput validate={validateRequired()} source='referee1.tradingName' label='Trading Name' />
            <TextInput validate={validateRequired()} source='referee1.email' type='email' label='Email' />
            <TextInput validate={validateRequired()} source='referee1.phone' label='Phone' />
            <TextInput validate={validateRequired()} source='referee1.relationship' label='Relationship' />
          </div>
        </div>
        <div className='address-block'>
          <p className='title'>Referee 2</p>
          <div className='inputs'>
            <TextInput validate={validateRequired()} source='referee2.firstName' label='First Name' />
            <TextInput validate={validateRequired()} source='referee2.lastName' label='Last Name' />
            <TextInput validate={validateRequired()} source='referee2.tradingName' label='Trading Name' />
            <TextInput validate={validateRequired()} source='referee2.email' type='email' label='Email' />
            <TextInput validate={validateRequired()} source='referee2.phone' label='Phone' />
            <TextInput validate={validateRequired()} source='referee2.relationship' label='Relationship' />
          </div>
        </div>
      </React.Fragment>
    )
  }

  const YearsinIndustry = ({ record = {} }) => {
    return record.type === 'seller' && (
      <div>
        {config.exceptedStates.includes(officeAddressState) ? null : <TextInput textAlign={'left'} source='yearsInIndustry' label='No. Years in Industry' />}
      </div>
    )
  }

  /*
    const SpecialConditionsofSale = ({ record = {} }) => {
      return record.type === 'seller' && (
        <div>
          <TextInput source='saleConditions' label='Special Conditions of Sale' />
        </div>
      )
    }
  */

  const AddressBlock = () => {
    return (
      <div className='address-block'>
        <p className='title'>Postal Address:</p>
        <div className='inputs'>
          <TextInput source='postalAddress.address1' label='Address 1' validate={validateRequired()} />
          <TextInput source='postalAddress.address2' label='Address 2' />
          <TextInput source='postalAddress.town' label='Town' validate={validateRequired()} />
          <TextInput source='postalAddress.postcode' label='Postcode' validate={validateRequired()} />
          <SelectInput source='postalAddress.state' choices={states} label='State' validate={validateRequired()} />
        </div>
      </div>
    )
  }

  /*const BankDetails = () => {
    return (
      <div className='address-block'>
        <p className='title'>Bank Details:</p>
        <div className='inputs'>
          <TextInput source='bankDetails.accountName' label='Account Name' />
          <TextInput source='bankDetails.BSB' label='BSB (Bank State Branch)' />
          <TextInput source='bankDetails.accountNumber' label='Account Number' />
        </div>
      </div>
    )
  }*/

  const CustomerDetails = ({ record = {} }) => {
    return (
      <div className='address-block'>
        <p className='title'>Customer Details:</p>
        <div className='inputs'>
          <TradeThroughCompanyBoolean source='doYouTradeThroughCompany' record={record}/>
          <TextInput  validate={tradeThroughCompany ? validateRequired() : null } source='customerDetails.companyName' label='Company Name' />
          <TextInput  validate={tradeThroughCompany ? null : validateRequired()} source='customerDetails.legalNameOfTradingEntity' label='Full legal name of trading entity' />
          <TextInput  validate={tradeThroughCompany ? validateRequired() : null } source='customerDetails.ACN' label='ACN' />
          <TextInput  validate={tradeThroughCompany ? null : validateRequired()} source='ABN' label='ABN' />
          <TextInput  validate={validateRequired()} source='customerDetails.givenName' label='Given Name(s)' />
          <TextInput  validate={validateRequired()} source='customerDetails.lastName' label='Last Name' />
        </div>
      </div>
    )
  }

  const TradeThroughCompanyBoolean = ({ record = {} }) => {
    if (tradeThroughCompany === null) {
      if (record.hasOwnProperty('doYouTradeThroughCompany')) {
        setTradeThroughCompany(record.doYouTradeThroughCompany)
      } else {
        setTradeThroughCompany(false)
        record.doYouTradeThroughCompany = false
      }
    }

    return (
      <div>
        <SelectInput
          source='doYouTradeThroughCompany' label='Do you trade through a company?'
          choices={tradeThroughCompanyOptions} onChange={()=> {
            setTradeThroughCompany(!tradeThroughCompany)
            record.doYouTradeThroughCompany = !tradeThroughCompany
          }} validate={validateRequired()} 
        />

      </div>

    )
  }

  const UserDiscount = ({ record = {} }) => {
    if (record.type === 'seller') {
      return (
        <SelectInput source='discount' label='Discount %' choices={discountOptions} />
      )
    } else {
      return ''
    }
  }

  const AdditionalFieldPIC = ({ record = {} }) => {
    if (anotherStatePicAvailable) {
      if (record.type === 'buyer') {
        return <SelectInput choices={states} source='anotherStatePIC' label='PIC State' validate={required()} />
      } else {
        return <SelectInput choices={states} source='anotherStatePIC' label='PIC State' />
      }
    } else {
      return <span />
    }
  }

  const AdditionalFieldPICBoolean = ({ record = {} }) => {
    if (anotherStatePicAvailable === null) {
      if (record.hasOwnProperty('anotherStatePicAvailable')) {
        setAnotherStatePicAvailable(record.anotherStatePicAvailable)
      } else {
        setAnotherStatePicAvailable(!!record.anotherStatePIC)
        record.anotherStatePicAvailable = !!record.anotherStatePIC
      }
    }
    return (
      <div>
        <div className='checkBoxLabel'>Is PIC No. located in another state</div>
        <Checkbox
          checked={anotherStatePicAvailable}
          onChange={() => {
            setAnotherStatePicAvailable(!anotherStatePicAvailable)
            record.anotherStatePicAvailable = !anotherStatePicAvailable
          }}
          color='primary'
          className='check-box'
        />
      </div>

    )
  }

  const GSTRegisteredBoolean = ({ record = {} }) => {
    if (registeredForGST === null) {
      if (record.hasOwnProperty('registeredForGST')) {
        setRegisteredForGST(record.registeredForGST)
      } else {
        setRegisteredForGST(false)
        record.registeredForGST = false
      }
    }
    return (
      <div>
        <div className='checkBoxLabel'>Registered for GST</div>
        <Checkbox
          checked={registeredForGST}
          onChange={() => {
            setRegisteredForGST(!registeredForGST)
            record.registeredForGST = !registeredForGST
          }}
          color='primary'
          className='check-box'
        />
      </div>

    )
  }

  const UsingAgentBoolean = ({ record = {} }) => {
    if (areYouUsingAgent === null) {
      if (record.hasOwnProperty('areYouUsingAgent')) {
        setAreYouUsingAgent(record.areYouUsingAgent)
      } else {
        setAreYouUsingAgent(false)
        record.areYouUsingAgent = false
      }
    }
    return (
      <div>
        <div className='checkBoxLabel'>Are you using an Agent?</div>
        <Checkbox
          checked={areYouUsingAgent}
          onChange={() => {
            setAreYouUsingAgent(!areYouUsingAgent)
            record.areYouUsingAgent = !areYouUsingAgent
          }}
          color='primary'
          className='check-box'
        />
      </div>

    )
  }

  const AgentField = ({ record = {} }) => {
    if (areYouUsingAgent) {
      return <TextInput source='agentName' label='Agent Name' />

    } else {
      return <span />
    }
  }

  const UserStatus = ({ record }) => {
    return (
      <SelectInput onChange={(e, value) => { onChangeStatus(e, value, record) }} source='status' label='Status' choices={statusOptions} validate={validateRequired()} />
    )
  }

  /*
  const UserType = ({ record }) => {
    if (userType === null) {
      setUserType(record.type)
    }
    return (
      <TextInput disabled defaultValue={capitalize(userType)} label='Type' />
    )
  }
  */

  const RequestedPermission = ({ record }) => {
    return (
      <TextInput disabled source='requestedPermission' defaultValue={record.status === 'approved-buyer-seller' ? '' : record.isRequestedAsBuyer && record.isRequestedAsSeller ? 'Buyer & Seller' : record.isRequestedAsBuyer ? 'Buyer' : record.isRequestedAsSeller ? 'Seller' : 'NA'} label='Requested Permission' />
    )
  }

  const RoleField = ({ record }) => {
    if (userType === null) {
      setUserType(record.type)
    }
    return <SelectInput
      disabled={userType === 'buyer'}
      source='role' label='Role'
      choices={roleOptions} validate={validateRequired()} />
  }

  const ProfessionFields = ({ record }) => {
    if (profession === null) {
      setProfession(record.profession)
    }
    return (
      <div>
        <SelectInput onChange={onChangeProfession} source='profession' label='Profession' choices={professionOptions} validate={validateRequired()} />
        {profession === professionOther && <TextInput source='otherProfession' label='Other Profession' validate={validateRequired()} />}
      </div>
    )
  }

  const ApprovedSellingCategories = () => {
    return (
      <CheckboxGroupInput source='approvedStockTypes' label='Approved Selling Categories' choices={kindOptions} optionValue="title" optionText="title" />
    )
  }

  const RequestedSellingCategories = () => {
    return (
      <CheckboxGroupInput source='stockTypes' label='Requested Selling Categories' choices={kindOptions} optionValue="title" optionText="title"  />
    )
  }

  return (
    <Edit title='Edit' {...newProps}>
      <SimpleForm validate={validateUserEdit} className='user-edit-form' toolbar={<EditToolbar user />}>
        <TextInput source='firstName' label='First Name' validate={validateRequired()} />
        <TextInput source='lastName' label='Last Name' validate={validateRequired()} />
        <TextInput source='email' label='Email' type='email' validate={validateRequired()} />
        <RoleField />
        <UserStatus />
        {/* <UserType /> */}
        <RequestedPermission />
        <ProfessionFields />
        <ApprovedSellingCategories />
        <RequestedSellingCategories />
        {/* <SelectInput source='sellerType' label='Seller Type' choices={sellerTypeOptions} validate={validateRequired()} /> */}
        <SelectInput onChange={onChangeAdditionalState} source='additionalState' choices={states} label='Additional State' />
        {/* 
        <SpecialConditionsofSale />
        <TextInput source='website' label='Website' />
        <TextInput source='jobTitle' label='Job Title' />
        */}
        <TextInput source='agencyName' label='Agency Name' />
        <TextInput source='tradingName' label='Trading Name' validate={validateRequired()} />
        <BuyerFields />
        <PicField />
        <AdditionalFieldPICBoolean source='anotherStatePicAvailable' />
        <AdditionalFieldPIC />
        <TextInput  validate={tradeThroughCompany ? null : validateRequired()} source='ABN' label='ABN' />
        <YearsinIndustry />
        {showSelect && <SelectInput source='userTag' choices={userTags} optionText='title' optionValue='title' />}
        {!showSelect && <TextInput source='userTag' label='New Tag' />}
        <FontAwesomeIcon
          className='add-button'
          icon='plus-square'
          onClick={() => setShowSelect(!showSelect)}
        />
        <TextInput source='phone' label='Phone' validate={validateRequired()} />
        <TextInput source='secondaryPhone' label='Secondary Phone' />
        <CheckboxGroupInput source='disputeStrikes' label='Dispute Strikes' choices={strikes} />
        <CustomerDetails />
        <OfficeAddress />
        <AddressBlock />
        {/* <BankDetails /> */}
        <GSTRegisteredBoolean source='registeredForGST' />
        <UsingAgentBoolean source='areYouUsingAgent' />
        <AgentField />
        <IndastryReferees />
        <FileInput source='logo' label='Logo' accept='image/*' placeholder={<p>Drop your file here</p>}>
          <ImageField source='url' />
        </FileInput>
        {/* <VendorForm /> */}
        <UserDiscount />
        <AdminComments {...newProps} onSubmit={() => { /* mock inheritance */ }} />
        <p>Push Notifications:</p>
        <BooleanInput source='settings.pushPermissions.lot_won' label='Lot Won' />
        <BooleanInput source='settings.pushPermissions.lot_lost' label='Lot Lost' />
        <BooleanInput source='settings.pushPermissions.lot_notify_closed' label='Lot Closing' />
        <p>Cattle Text Notifications:</p>
        <BooleanInput source='settings.smsRemindersPermissions.cattleAuctionOpenReminder' label='Auction is Live' />
        <BooleanInput source='settings.smsRemindersPermissions.cattleOneDayReminder' label='Auto-Bids are Open' />
        <BooleanInput source='settings.smsRemindersPermissions.cattleTenDaysReminder' label='Listings are Open' />
        <p>Sheep Text Notifications:</p>
        <BooleanInput source='settings.smsRemindersPermissions.sheepAuctionOpenReminder' label='Auction is Live' />
        <BooleanInput source='settings.smsRemindersPermissions.sheepOneDayReminder' label='Auto-Bids are Open' />
        <BooleanInput source='settings.smsRemindersPermissions.sheepTenDaysReminder' label='Listings are Open' />
        <p>Goat Text Notifications:</p>
        <BooleanInput source='settings.smsRemindersPermissions.goatAuctionOpenReminder' label='Auction is Live' />
        <BooleanInput source='settings.smsRemindersPermissions.goatOneDayReminder' label='Auto-Bids are Open' />
        <BooleanInput source='settings.smsRemindersPermissions.goatTenDaysReminder' label='Listings are Open' />
      </SimpleForm>
    </Edit>
  )
}

import React from 'react'
import ReactDropzone from 'react-dropzone'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Slide from '@material-ui/core/Slide'
import { Form } from 'redux-form'
import './MediaInputField.css'
import Cropper from 'react-cropper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import 'cropperjs/dist/cropper.css'
import CheckboxField from '../LotAssessmentForm/fields/CheckboxField'
import uuid from 'uuid'

const grid = 8

function Transition (props) {
  return <Slide direction='up' {...props} />
}

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  result.forEach((item, index) => { item.order = index })

  return result
}

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  padding: grid
})

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'grey',

  // styles we need to apply on draggables
  ...draggableStyle
})

export default class MediaInputField extends Form {
  constructor (props, context) {
    super(props, context)
    this.reduxForm = context._reduxForm
    let media
    if (this.props.type === 'create') {
      media = []
    } else {
      media = this.reduxForm.getValues().media || this.reduxForm.getValues().videos
    }
    this.state = {
      media,
      cropping: null
    }
    this.deleteItem = this.deleteItem.bind(this)
    this.onDrop = this.onDrop.bind(this)
  }

  deleteItem (newDocument) {
    let media = Object.assign([], this.state.media)
    media.forEach((document, index) => {
      if (document.name === newDocument.name) {
        media.splice(index, 1)
      }
    })
    this.reduxForm.dispatch(this.reduxForm.change('media', media))
    this.setState({ media })
  }

  onDrop (files, rawFileExist) {
    this._handleFiles(files, rawFileExist)
  }

  _handleFiles (files, rawFileExist) {
    if (files && files.length > 0) {
      let file = files[0]
      let fileUrl
      if (rawFileExist) {
        fileUrl = URL.createObjectURL(file)
      } else {
        fileUrl = file.url
        file.type = file.mimeType
      }
      Promise.resolve()
        .then(() => {
          if (file.type.indexOf('image/') !== -1) {
            return this._handleImageFile(file, fileUrl)
          } else if (file.type.indexOf('video/') !== -1) {
            const maxSize = 50
            const size = Math.round(this.bytesToMegaBytes(file.size))
            if (size > maxSize) {
              window.alert('Please, use video that is not bigger than 50 MB')
            } else {
              return this._handleVideoFile(file, fileUrl)
            }
          } else {
            throw new Error('Unsupported media')
          }
        })
        .catch(err => {
          window.alert(err.message)
          URL.revokeObjectURL(fileUrl)
        })
    }
  }

  _checkFileAlreadyAdded (file) {
    return this.state.media.some((document) => file.name === document.name)
  }

  async _handleImageFile (file, fileUrl) {
    await this._checkImageSize(fileUrl)
    this.setState({
      cropping: {
        rawFile: file, name: file.name, url: fileUrl, mimeType: file.type, editMode: false
      }
    })
  }

  async _checkImageSize (fileUrl) {
    return new Promise((resolve, reject) => {
      const imageEl = document.createElement('img')
      imageEl.src = fileUrl
      imageEl.addEventListener('load', () => {
        if (imageEl.width < 320 || imageEl.height < 220) {
          reject(new Error('Image less than 320x220. Please choose another file.'))
          return
        }
        resolve()
      })
      imageEl.addEventListener('error', event => {
        reject(new Error('Image failed to load'))
      })
    })
  }

  bytesToMegaBytes (bytes) {
    return bytes / (1024 * 1024)
  }

  async _handleVideoFile (file, fileUrl) {
    return new Promise((resolve, reject) => {
      const videoEl = document.createElement('video')
      videoEl.addEventListener('loadeddata', () => {
        let maxDuration = 60
        if (this.props.location === 'howToVideo') {
          maxDuration = 360
        }
        if (videoEl.duration > maxDuration) {
          reject(new Error(`Video exceeds ${maxDuration / 60} minute(s). Please choose another file.`))
          return
        }
        this._snapshotVideo(videoEl)
          .then(blob => {
            const media = Object.assign([], this.state.media)
            media.push({
              rawFile: file,
              name: file.name,
              url: fileUrl,
              rawThumbnail: blob,
              thumbnail: URL.createObjectURL(blob),
              mimeType: file.type
            })
            this.reduxForm.dispatch(this.reduxForm.change('media', media))
            this.setState({ media })
          })
          .then(resolve)
      })
      videoEl.preload = 'metadata'
      videoEl.type = file.type
      videoEl.muted = true
      videoEl.playsInline = true
      videoEl.src = URL.createObjectURL(file)
      videoEl
        .play()
        .catch(reject)
    })
  }

  _snapshotVideo (videoEl) {
    return new Promise(resolve => {
      const canvas = document.createElement('canvas')
      canvas.width = videoEl.videoWidth
      canvas.height = videoEl.videoHeight
      canvas.getContext('2d').drawImage(videoEl, 0, 0, canvas.width, canvas.height)
      canvas.toBlob(resolve)
    })
  }

  handleClose = () => {
    if (this.state.cropping) {
      URL.revokeObjectURL(this.state.cropping.url)
    }
    this.setState({ cropping: null })
  }

  updateThumbnail = () => {
    this.refs.cropper.getCroppedCanvas().toBlob(blob => {
      if (this.state.media[this.state.cropping.index]) {
        let media = Object.assign([], this.state.media)
        media[this.state.cropping.index] = {
          rawFile: this.state.cropping.rawFile,
          name: this.state.cropping.name,
          url: this.state.cropping.fileUrl,
          mimeType: this.state.cropping.mimeType,
          rawThumbnail: blob,
          thumbnail: URL.createObjectURL(blob),
          editMode: true
        }
        this.reduxForm.dispatch(this.reduxForm.change('media', media))
        this.setState({ media, cropping: null })
      }
    })
  }

  handleUploadCropImage = () => {
    if (this.state.cropping.editMode) {
      this.updateThumbnail()
      return
    }
    this.refs.cropper.getCroppedCanvas().toBlob(blob => {
      if (this._checkFileAlreadyAdded(this.state.cropping.rawFile)) {
        this.deleteItem(this.state.cropping)
      }
      let media = Object.assign([], this.state.media)

      media.forEach(m => { m.order = m.order + 1 })
      media.push({
        order: 0,
        rawFile: this.state.cropping.rawFile,
        name: this.state.cropping.name,
        url: this.state.cropping.fileUrl,
        mimeType: this.state.cropping.mimeType,
        rawThumbnail: blob,
        thumbnail: URL.createObjectURL(blob),
        main: false,
        key: uuid.v4()
      })
      this.reduxForm.dispatch(this.reduxForm.change('media', media))
      this.setState({ media, cropping: null })
    })
  }

  openCrop = async (file, index) => {
    this.props.toggleSpinner()
    let fileUrl
    let newFile = false
    let editMode = false
    if (file.rawFile && !file.rawFile.url) {
      fileUrl = URL.createObjectURL(file.rawFile)
      newFile = true
    } else if (file.rawFile && file.rawFile.url) {
      fileUrl = file.rawFile.url
      newFile = true
      editMode = true
    } else {
      fileUrl = file.url
      editMode = true
    }
    let img = document.createElement('img')
    img.src = fileUrl
    img.onload = () => {
      this.props.toggleSpinner()
      this.setState({
        cropping: {
          rawFile: newFile ? file.rawFile : file,
          name: file.name,
          url: fileUrl,
          mimeType: file.mimeType,
          editMode,
          index
        }
      })
    }
  }

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const media = reorder(this.state.media, result.source.index, result.destination.index)
    this.reduxForm.dispatch(this.reduxForm.change('media', media))
    this.setState({ media })
  }

  _onMainChange (item) {
    const media = Object.assign([], this.state.media)
    media.forEach((mediaItem) => {
      if (item.key === mediaItem.key) {
        mediaItem.main = !mediaItem.main
      } else {
        mediaItem.main = false
      }
    })

    this.setState({ media })
    this.reduxForm.dispatch(this.reduxForm.change('media', media))
  }

  render () {
    const { media } = this.state
    return (<div className='MediaInputField'>
      <h3>Image</h3>
      <Dialog
        maxWidth='md'
        open={!!this.state.cropping}
        TransitionComponent={Transition}
        keepMounted
        onClose={this.handleClose}
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle id='alert-dialog-slide-title'>
          {'Are you sure?'}
        </DialogTitle>
        <DialogContent style={{ overflow: 'hidden', paddingBottom: '0' }}>
          {this.state.cropping && <div className='image-crop'>
            <Cropper
              ref='cropper'
              src={this.state.cropping.url}
              className={'modal-test'}
              guides={false}
              dragMode={'move'}
              draggable
              viewMode={1}
              restore={false}
              cropBoxResizable={false}
              toggleDragModeOnDblclick={false}
              minCropBoxWidth={320}
              minCropBoxHeight={220}
              zoomable
              // responsive={false}
              ready={() => {
                this.refs.cropper.setCropBoxData({
                  width: 320, height: 220
                })
              }}
            />
          </div>}

        </DialogContent>
        <DialogActions>
          <div>
            <div className='zoom-buttons'>
              <div className='zoom-button' onClick={() => {
                this.refs.cropper.rotate(270)
              }}>Rotate
              </div>
              <div className='zoom-button' onClick={() => {
                this.refs.cropper.zoom(0.1)
              }}>Zoom in
              </div>
              <div className='zoom-button' onClick={() => {
                this.refs.cropper.zoom(-0.1)
              }}>Zoom out
              </div>
            </div>
            <div style={{ display: 'flex', marginRight: '10px', justifyContent: 'flex-end' }}>
              <Button onClick={this.handleUploadCropImage} color='primary'>
                Yes
              </Button>
              <Button onClick={this.handleClose} color='primary'>
                No
              </Button>
            </div>
          </div>
        </DialogActions>
      </Dialog>
      <ReactDropzone
        // disabled={this.props.record.state === 'closed'}
        accept={'.jpg,.jpeg,.png,.mp4,.HEIC,.MOV,.mov'}
        multiple={false}
        onDrop={this.onDrop}>
        {({ getRootProps, getInputProps }) => {
          return (<div {...getRootProps()} className='drop-zone'>
            <input {...getInputProps()} />
            <p>Drop your file here</p>
          </div>)
        }}
      </ReactDropzone>
      {media && media.length
        ? <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId='droppable'>
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >

                {media.sort((a, b) => a.order - b.order).map((item, index) => {
                  return (
                    <Draggable
                      storeKey={item.key || item.name}
                      key={`draggable-${item.key || item.name}-${index}`}
                      draggableId={item.key || item.name}
                      index={index}>
                      {(provided, snapshot) => (
                        <div
                          key={`wrapper-${item.key || item.name}-${index}`}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <div key={`item-${item.key || item.name}-${index}`} className='item'>
                            <div className='item-header'>
                              <FontAwesomeIcon
                                className='delete-btn'
                                onClick={() => { 
                                 // this.props.record.state !== 'closed' && 
                                  this.deleteItem(item) }}
                                icon={faMinusCircle} />
                              {item.mimeType.includes('video') ? <a rel='noopener noreferrer' target='_blank'
                                href={item.thumbnail}>
                                <p>{item.name}</p>
                              </a> : <p>{item.name}</p>}
                            </div>
                            {item.mimeType.includes('video')
                              ? <video width={500} height={300} id='video' src={item.url} type={item.mimeType}
                                poster={item.thumbnail} controls />
                              : <img onClick={() => { this.openCrop(item, index) }} className='previewImg'
                                src={item.thumbnail || item.url} alt='Your images' />}
                          </div>
                          <CheckboxField
                            key={`checkboxField-${item.key || item.name}-${index}`}
                            style={{ paddingBottom: 4 }}
                            id={'main-check' + item.key}
                            onChange={() => { this._onMainChange(item) }}
                            value={item.main}
                            title={'super text'}
                            field='value'
                          />
                          <label htmlFor={'main-check' + item.key}>Main</label>
                        </div>
                      )}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext> : null
      }
    </div>)
  }
}

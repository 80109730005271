import handleError from '../handleError'
import { pick } from 'lodash'
import convertDataRequestToHTTP from '../providerService'
import fileUploader from '../fileUploader'

const updateAuctionFields = [
  'title',
  'auctionType',
  'approved',
  'liveAt',
  'lotOpeningSeconds',
  'bidAddedSeconds',
  'lotMaxSeconds',
  'standbyTimer',
  'auctionMaxSeconds',
  'description',
  'descriptionShort',
  'adminNotes',
  'addNewLotToSpecificAgents',
  'specificAgents',
  'additionalDateInfo',
  'location',
  'auctionTags',
  'usefulLinks',
  'smsReminderRegions',
  'regions',
  'smsReminders',
  'showCalculator',
  'public',
  'lotAddedDelay',
  'numberOfActiveLots',
  'listingFee',
  'specialPriceFee',
  'auctionSetUpFee',
  'privateSale',
  'auctionDiscount',
  'allowAutoBid',
  'allowUpcomingBid',
  'abbLambSale',
  'requiresTabs',
  'auctionTabs',
  'auctionOpenTimer',
  'addLotOpenTimer',
  'addLotCloseTimer',
  'showLotNumbers',
  'studSale',
  'optiweigh',
  'viewDetailsDays'
]

const updateAuction = (params, type, resource, httpClient) => {
  let newParams = JSON.parse(JSON.stringify(params))
  newParams.data.liveAt = new Date(newParams.data.localLiveAtISO).toISOString()
  if (newParams.data.listingFeeInDollars || newParams.data.listingFeeInDollars === 0) {
    newParams.data.listingFee = newParams.data.listingFeeInDollars * 100
  } else {
    newParams.data.listingFee = 0
  }
  if (newParams.data.auctionSetUpFeeInDollars || newParams.data.auctionSetUpFeeInDollars === 0) {
    newParams.data.auctionSetUpFee = newParams.data.auctionSetUpFeeInDollars * 100
  } else {
    newParams.data.auctionSetUpFee = 0
  }
  if (newParams.data.specialPriceFeeInDollars || newParams.data.specialPriceFeeInDollars === 0) {
    newParams.data.specialPriceFee = newParams.data.specialPriceFeeInDollars * 100
  } else {
    newParams.data.specialPriceFee = 0
  }
  if(!newParams.data.requiresTabs) {
    newParams.data.auctionTabs = []
  }
  newParams.data = { auction: pick(newParams.data, updateAuctionFields) }

  let { url } = convertDataRequestToHTTP(type, resource, newParams)
  return fileUploader(resource, params)
    .then((res) => {
      if (res[0] && res[0].data && res[0].data.newPdfFile) {
        const allLinks = res.map(
          (oneLink) => oneLink.data && oneLink.data.newPdfFile ? oneLink.data.newPdfFile : null)
          .filter(Boolean)
          .flat()
        newParams.data.auction.usefulLinks = newParams.data.auction.usefulLinks.concat(allLinks).filter(link => link.url)
      }
      return httpClient(url, {
        method: 'PUT',
        data: newParams.data
      })
    })
    .then((response) => {
      const one = response.data[resource.slice(0, resource.length - 1)] || response.data[params.type]
      one.id = one['_id']
      return {
        data: one
      }
    })
    .catch(handleError)
}

const createAuction = (params, resource, httpClient) => {
  let url = `kinds/${params.data.kindId}/auctions`
  delete params.data.kindId
  let newParams = JSON.parse(JSON.stringify(params))
  if (newParams.data.listingFee) {
    newParams.data.listingFee = newParams.data.listingFee * 100
  }
  if (newParams.data.specialPriceFee) {
    newParams.data.specialPriceFee = newParams.data.specialPriceFee * 100
  }
  if (newParams.data.auctionSetUpFee) {
    newParams.data.auctionSetUpFee = newParams.data.auctionSetUpFee * 100
  }
  if(!newParams.data.requiresTabs) {
    newParams.data.auctionTabs = []
  }
  newParams.data.liveAt = new Date(newParams.data.liveAt).toISOString()
  newParams.data = { auction: pick(newParams.data, updateAuctionFields) }

  return httpClient(url, {
    method: 'POST',
    data: newParams.data
  })
    .then(async response => {
      const one = response.data[resource.slice(0, resource.length - 1)]
      if (one) {
        one.id = one['_id']
        if (resource === 'auctions') {
          params.id = one['_id']
          await fileUploader(resource, params)
        }
        return {
          data: one
        }
      } else {
        return {
          data: response
        }
      }
    })
    .catch(handleError)
}

export default { updateAuction, createAuction }
